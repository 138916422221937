import React from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {useQuery} from "../../hooks";
import {replaceState} from "../../utils";
import printJS from 'print-js';
import {DashTitle} from "../common/com";
import {panel} from "../../components/tabs";
export  const BetD = ({d={},ls=[],onChange})=>{
    const {
        winning_number = [],coefficient,display_bet_number,
        lottery_id,bought_at,issue,status,prize_group,id,
        serial_number,title,multiple,commission,amount,prize
    } = d;
    const name = (ls.find(({id}) => id === lottery_id) || {}).name;
    const [query,loading] = useQuery('cancelOrder');
    if(!d)return  null;
    return <div className={'bet-d'}>
        <div id={'print-area'}>
            {panel(winning_number.join(""),  <div className={'balls'}>
                {winning_number.map((a, i) => <div key={i}><span>{a}</span></div>)}
            </div>)}
            <label className={'lt'}>投注详情</label>
            <div className={'r'}>
                <div className={'l'}>
                    <label>游戏</label>
                    <span>{name}</span>
                </div>
                <div>
                    <label>注单编号</label>
                    <span>{serial_number}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>玩法</label>
                    <span>{title}</span>
                </div>
                <div>
                    <label>投注时间</label>
                    <span>{bought_at}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>期号</label>
                    <span>{issue}</span>
                </div>
                <div>
                    <label>倍数</label>
                    <span>{multiple}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>模式</label>
                    <span>{['元','角','分'][Math.log10(1/coefficient)]}</span>
                </div>
                <div>
                    <label>投注金额</label>
                    <span>{amount}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>{status===3?'奖金':'状态'}</label>
                    <span>{ ['待开奖','已撤销','未中奖',(parseFloat(prize)).toFixed(3)+'元','已派奖','系统撤销'][status]}</span>
                </div>
                <div>
                    <label>奖金组</label>
                    <span>{prize_group}</span>
                </div>
            </div>
            <div className={'r'}>
                <div>
                    <label>投注返点</label>
                    <span>{commission}</span>
                </div>
                <div className={'l hi'} >
                    <label>兑奖码</label>
                    <span>{id}</span>
                </div>
            </div>
            <div className={'rr'}>
                <div className={'rrr'}>
                    {display_bet_number}
                </div>
            </div>
        </div>
        <div className={'rr'}>
            <div className={'print-btn'} onClick={()=>printJS({
                printable:'print-area',
                type:'html',
                targetStyles:['*'],
                maxWidth:600,
                documentTitle:'BT'
            })}>打印</div>
            {status<1?<div className={'btnCancel'} onClick={()=>{
                !loading&&query({id:id},()=>{
                    onChange&&onChange(id)
                });
            }}>{loading?'正在撤单':'撤单'}</div>:''}
        </div>
    </div>
}

export const HisDetail = widthDashBoard(({history,location: {state: d = {}}}, {betStore}) => {
    const {lotteries} = betStore;
    return <div className={'his-detail'}>
        <DashTitle
            title={'投注记录-详情'}
        />
        <div className={'g-back'} onClick={()=>history.go(-1)}>返回列表</div>
        <BetD d={d} ls={lotteries} onChange={()=>{
            const newState = {...d,status:1};
            replaceState(newState)
        }}/>
    </div>
});
