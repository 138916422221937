import React, {useState, useEffect, useMemo} from 'react';
import {TimeNum} from "./num";
import "./time.scss";

function lf(time) {
    return time ? Math.floor((time - Date.now()) / 1e3) : 0;
}

function fm(n) {
    return (n + '').length >= 2 ? n : (n + 100 + '').substr(1);
}

function tm(num, noHours) {
    const h = Math.floor(num / 3600);
    const m = noHours ? Math.floor(num / 60) : Math.floor(num / 60) % 60;
    let p = [m, num % 60];
    if (!noHours) p = [h].concat(p);
    return p.map(fm).join(':')
}

/**
 * 剩余时间组件
 * @param trigger
 * @param {number} time 结束时间的getTime
 * @param {function} onEnd 到了结束时间的回调
 * @param {(boolean|function)} simple 格式化日期 true-输出剩余时间  function(leftTime) - 自定义输出格式
 * @param noHours 不显示小时
 * @param className
 * @return {*}
 * @constructor
 * @example
 * <Time  time={Date.now()+1e3*30} simple={a=>a+'s'} onEnd={()=>alert('timeUp!')}/>
 */
export const Timer = ({trigger, time = 0, onEnd, simple, noHours, className = ''}) => {
    const [_, setA] = useState(0);
    const ctx = useMemo(() => ({
        stop: 0,
        get t() {
            return this.stop ? 0 : lf(time)
        }
    }), [time])
    useEffect(() => {
        const t = setInterval(() => {
            setA(Math.random());
            const {t, stop} = ctx
            if (!stop && t <= 0) {
                ctx.stop = 1
                if (onEnd && time) onEnd()
            }
        }, 1e3);
        return () => clearInterval(t);
    }, [time]);
    const ss = tm(ctx.t, noHours);
    let s = ss;
    if (simple) {
        if (typeof simple === 'function') {
            s = simple(ctx.t);
        }
    } else {
        s = ss
            .replace(/:/gi, '')
            .split('')
            .map((c, i) => <TimeNum value={c} key={i}/>)
    }
    return <div className={'left-time ' + className} title={new Date(time).toLocaleString() + ' ' + trigger}>
        {s}
    </div>
};
