import {decrypt, encrypt} from "../../utils";
import {api, base} from './cfg';
import {stores} from "../../store";

const s = localStorage._stv;
const l = sessionStorage._stv;
const lCach = s && decrypt(s) || {}
const sCach = l && decrypt(l) || {}

const setCache = (k, d, da) => {
    let [{t = 0, l = 0} = {}] = api[k];
    if (t) {
        let k = k;
        if (d) k += JSON.stringify(d);
        const st = [lCach, sCach][l] || {};
        st[k] = {
            d: da,
            v: Date.now()
        };
        [localStorage, sessionStorage][l]['_stv'] = encrypt(JSON.stringify(st))
    }
}
const getCache = (k, d) => {
    let [{t = 0, l = 0} = {}] = api[k];
    if (t) {
        let k = k;
        if (d) k += JSON.stringify(d);
        const st = [lCach, sCach][l] || {};
        const c = st[k];
        if (c) {
            const {d, v} = c;
            if (Date.now() < v + t) {
                return d
            } else {
                delete st[k];
                [localStorage, sessionStorage][l]['_stv'] = encrypt(JSON.stringify(st))
            }
        }
    }
}

export const sApi = (k, d, s, e) => {
    let [u, {
        m,
        l = 0,
        t = 0,
        before,
        after,
        done,
        fail
    } = {}] = api[k];
    const req = {
        method: [`GET`, `POST`, `PUT`, `DELETE`][m] || 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            requestId: stores.globalStore.tvTk,
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
    }
    if (d) {
        let dd = d;
        if (before) {
            dd = before(d);
        }
        if (m === 1) {
            req.body = JSON.stringify(dd)
        } else {
            u = u + '?';
            if (typeof dd === 'object') {
                u += Object.keys(dd)
                    .map(k => `${k}=${encodeURI(dd[k])}`)
                    .join('&')
            } else u += dd;
        }
    }
    const err = er => {
        if (fail) {

        }
        if (e) {

        }
    }
    const suc = da => {
        if (done) done(da);
        if (s) s(da);
    }
    const dd = getCache(k, d);
    if (dd) {
        suc(dd);
    }
    fetch(base + u, req)
        .then(e => e.json())
        .then(({data, code, msg, ts}) => {
            if (+code) {
                err({msg})
                return
            }
            if (after) {
                data = after(data, ts);
            }
            setCache(k, d, data);
            suc(data);
        })
        .catch(err)
}
