import React from 'react'
import {useThirdGame} from "../../hooks";
import {LaunchGame} from "../../pages/common/com";


export const PWay = () => {
    const cfg = useThirdGame('sport', [30,19,28, 16,18,14]);
    return <div className={'tv-ws'}>
        {cfg.map(( [k, id, cls, nm, bls, plt, win], i) =>
            <LaunchGame
                key={id+k}
                platform={plt}
                product_type={k}
                game_mode={1}
                game_code={id}
                local={win}
            >
                <i className={'w-ico-'+k}/>
                <span>{nm}</span>
            </LaunchGame>)}
    </div>
}