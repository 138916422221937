import React, {useEffect, useMemo, useState} from 'react';
import {FakeGm, Foot, Foot2, HeadA, HeadB, LaunchGame} from "../common/com";
import './r.scss';
import {animate, fakeNumber} from "../../utils";
import {useThirdGame} from "../../hooks";
import {popPro} from "../promotion";


const FG = ({nm, gm, pr, i = 0}) => {
    return <div className={'fg ' + ['a', 'b', 'c'][i]}>
        <i className={'jb'}/>
        恭喜<b>{nm}</b>，在<span>{gm}</span>
        <div className={'pz'}>
            赢得<span>{Math.floor(pr / 4.3)}</span>元
        </div>
    </div>
}
let u = 0;
export const RealPage = () => {
    const [, aa] = useState(0)
    const cvs = useMemo(() => ({x: 50}), [])
    const init = (c) => {
        const f = (x, ww, wh, v) => (v ? Math.cos : Math.sin)(x / ww) * wh;
        cvs.c = c;
        const mxX = c.width = c.offsetWidth;
        const mxY = c.height = c.offsetHeight;
        const ctx = c.getContext('2d');
        const grd = ctx.createLinearGradient(0, 0, mxX, 0);
        grd.addColorStop(0, "rgba(255,255,255,.3");
        grd.addColorStop(.1, "rgba(255,255,255,.8)");
        grd.addColorStop(.2, "rgba(255,255,255,.1)");
        grd.addColorStop(.5, "rgba(255,255,255,.4)");
        grd.addColorStop(.8, "rgba(255,255,255,.1)");
        grd.addColorStop(.9, "rgba(255,255,255,.8)");
        grd.addColorStop(1, "rgba(255,255,255,.3)");
        ctx.fillStyle = grd;
        const dot = (x, y, r) => {
            ctx.moveTo(x, y);
            ctx.arc(x, y, r, 0, Math.PI * 2);
        }
        const dr = (n = 100, ww = 20, wh = 5, r = 3, v) => {
            let x = mxX;
            while (x > 0) {
                const y = Math.floor(n - f(x + cvs.x * 3, ww, wh, v));
                dot(x, y, r);
                x -= 8;
            }
        }
        cvs.draw = () => {
            ctx.clearRect(0, 0, mxX, mxY);
            ctx.beginPath();
            let d = 140
            const step = 10;
            let i = 0;
            const n = d / step;
            const md = d / 2;
            const ww = 120;
            const wh = 50;
            const top = 400;
            while (d > 0) {
                i++;
                const p = Math.abs(md - d) / n;
                d = d - step;
                const t = Math.abs(i - n);
                const rx = (1 + Math.sin(t / n) / n);
                dr(top - d + p,
                    ww * rx,
                    wh * rx,
                    2 * t / n + 1)
                dr(
                    top + d / 3 + p,
                    ww * rx * .8,
                    wh * rx * 1.5,
                    2 * t / n + 1,
                    1)
            }
            ctx.fill();
            ctx.closePath();
        };
    }
    useEffect(() => {
        return animate([0, 1], {
            r: 1,
            next(v) {
                if (u++ % 200 === 0) {
                    aa(v)
                }
                if (cvs.draw && u % 5 === 0) {
                    cvs.draw();
                    cvs.x += .5
                }
            }
        })
    }, [cvs])
    const c = (u, c, a, b) => {
        return <a onClick={() => popPro.show(u)}>
            <div className={c}/>
            <div className={'f'}>
                <div>
                    <div><i className={'m'}/>目前送出金额</div>
                    <div className={'f1'}>{fakeNumber(v => v / a)}</div>
                </div>
                <div>
                    <div><i className={'p'}/>目前参与人数</div>
                    <div className={'f1'}>{fakeNumber(v => v / b, 1)}</div>
                </div>
            </div>
        </a>
    }
    const games = [
        'AG百家乐', 'AG极速百家乐', 'AG龙虎', 'AG牛牛', 'AG轮盘', 'AG21点',
        'AG区块链百家乐', 'OB经典百家乐', 'OB咪牌百家乐', 'OB区块链百家乐',
        'OB轮盘', 'OB龙虎', 'OB骰宝', 'OB炸金花', 'OB斗牛',
        'EA百家乐', 'EA牛牛', 'EA骰宝', 'EA轮盘', 'EA电子五行龙虎',
        'EA电子牛牛', 'BBIN百家乐', 'BBIN区块链百家乐', 'BBIN区块链骰宝',
        'BBIN色碟', 'BBIN区块链牛牛', 'BBIN区块链炸金花', 'BBIN区块链三公',
        'EG闪电轮盘', 'EG极速轮盘', 'EG捕梦转盘', 'EG尊贵轮盘', 'EGVIP自动轮盘',
        'EG轮盘精致版', 'EG 足球厅', 'EG极速百家乐', 'EG闪电百家乐', 'EG免水极速百家乐',
        'EG免水百家乐', 'EG百家乐咪牌', 'EG百家乐控制咪牌', 'EG意式百家乐',
        'EG龙虎斗', 'EG超级骰宝', 'SEXY百家乐', 'SEXY龙虎', 'SEXY骰宝',
        'SEXY轮盘'
    ].map(k => ({name: k}));
    const r = (g, i) => <FG {...g} i={i} key={g.nm + g.pr}/>;
    const cfg = useThirdGame('live');
    const gn = (cls, a, b, bn = [], nullAble = 1) => {
        const bns = [];
        let wa = undefined;
        let ept = 0;
        bn.forEach(b => {
            const cd = b[0];
            if (!cd) return ept = 1;
            const cf = cfg.find(c => c[1] === cd);
            if (!cf) return ept = 1;
            const [tp, code, , , bls, plt] = cf;
            if (bls && bls.id) wa = bls;
            bns.push([b[1], tp, code, plt, bls])
        });
        if (!nullAble && ept) return null;
        const nf = bns.length;
        const [, tp, code, plt] = (nf && bns[0] || []);
        const inn = ept ? null : <>
            <div className={'rn'}>
                <div className={'p'}>
                    <i/>
                    <span>{nf ? fakeNumber(v => v / a, 1) : '---'}人</span>
                </div>
                <div className='m'>
                    <i>￥</i>
                    <span>{(nf && wa) ? wa.balance : '---'}元</span>
                </div>
            </div>
            <div className={'bns'}>
                {bns.map(([txt], i) => <div key={i}>
                    {txt || '进入游戏'}
                </div>)}
            </div>
        </>
        const c = 'crd ' + (ept ? ' ept ' : '') + cls;
        return ept ? <div className={c} onClick={e => e.preventDefault()}>{inn}</div> : <LaunchGame
            cls={c}
            game_code={code}
            game_mode={1}
            product_type={tp}
            platform={plt}
        >
            {inn}
        </LaunchGame>
    }
    const rd = <div className={'nu'}>
        {fakeNumber(a => a / 8).split('').map((a, i) => {
            return /\d/.test(a) ? <b key={i}>{a}</b> : <span key={i}>{a}</span>
        })}
    </div>

    const allb = gn('ab', 1134, 533, [['AB0001']], 1)
    const dg = gn('dg', 1234, 333, [['LIVE034']], 1)//DG034
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'full c-page live-page bg0'}>
            <div className={'hv'}>
                <canvas ref={a => {
                    if (a && cvs.c !== a) init(a);
                }}/>
                <div className={'cx'}>
                    <div className={'rd'}>
                        <span>RMB</span>
                        <span>{fakeNumber()}</span>
                        <b>￥</b>
                    </div>
                    <div className={'ls'}>
                        {c(3, 'c0', 534, 5432)}
                        {c(10, 'c1', 722, 8765)}
                        {c(7, 'c2', 866, 6765)}
                    </div>
                </div>
            </div>
            <div className={'fkb'}>
                <div className={'cx'}>
                    <FakeGm games={games}>{r}</FakeGm>
                    <FakeGm games={games} cls={'a'}>{r}</FakeGm>
                    <FakeGm games={games} cls={'b'}>{r}</FakeGm>
                </div>
            </div>
            <div className={'gb'}>
                <div className={'cx'}>
                    <i/>

                    {rd}
                    <div className={'bg-a'}/>
                    <div className={'bg-b'}/>
                    <div className={'bg-c'}/>
                    <div className={'rv'}>
                        <div className={'rr'}>
                            {gn('ag', 1000, 1000, [
                                // ['A00070', <span><span className={'c0'}>国际厅</span><i>|</i>进入游戏</span>],
                                ['A00070', <span><span className={'c1'}>旗舰厅</span><i>|</i>进入游戏</span>]
                            ])}
                            {gn('yb', 10000, 100, [['YB0001']])}
                        </div>
                        <div className={'r'}>
                            {gn('ea', 1100, 500, [['EA0001']])}
                            {gn('bb', 1200, 666, [['BB1001']])}
                            {gn('eg', 1300, 777, [['EG0001']])}
                            {gn('se', 1234, 333, [['SEX001']])}
                        </div>
                    </div>
                    {allb || dg ? <div className={'rv'}>
                        {allb ? <div className={'rr rx'}>
                            {allb}
                        </div> : null}
                        {dg ? <div className={'r'}>
                            {dg}
                        </div> : null}
                        {(!allb || !dg) ? <div className={'r nf'}>
                            <div className={'wt'}>
                                敬请期待
                            </div>
                        </div> : null}
                    </div> : null}
                </div>
            </div>
        </div>
        <Foot2/>
        <Foot/>
    </div>
}
