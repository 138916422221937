import React, {useState} from 'react';
import {Page} from "../common/page";

import './vip.scss'
import {X, V} from "../common/com";
import {Table} from "../../components/table/table";

const f = (da, n, i) => da[i];

const C = ({lv, t0, t1, cur}) => <div className={'vip-e' + (cur === lv ? ' act' : cur < lv ? ' lok' : '')}>
    <V v={lv} iconOnly/>
    <div>
        <span>{t0}</span>
        <s/>
        <b>{t1}</b>
    </div>
    <i className={'k'}/>
</div>

const d0 = [
    ['有效投注\n（累计）', '-', 10000, '5万', '20万', '120万', '300万', '800万', '1800万', '6000万'],
    ['存款金额\n（累计）', '-', 10000, '1万', '5万', '20万', '50万', '150万', '300万', '1000万'],
    ['保级要求\n（投注）', '-', 300, 5000, '2万', '12万', '30万', '80万', '180万', '600万'],
    ['晋级礼金', '-', 18, 38, 88, 288, 588, 1888, 3888, 5888],
    ['每月红包', '-', 18, 28, 58, 188, 388, 1288, 1888, 5888],
    ['生日礼金', '-', '-', '-', '-', 188, 388, 888, 1888, 5888],
    ['周周存送\n最高红利', '-', '-', '10%/288', '10%/588', '20%/888', '30%/1288', '30%/2888', '30%/5888', '30%/8888'],
    ['佳节礼品\n（节日）', '-', '-', '-', '-', '-', '√', '√', '√', '√'],
    ['每日提款\n（次数）', '5次/日', '5次/日', '5次/日', '5次/日', '6次/日', '6次/日', '6次/日', '6次/日', '6次/日'],
    ['每日提款\n（额度）', '20万', '20万', '20万', '25万', '30万', '30万', '30万', '30万', '30万'],
    ['存取通道\n（专用）', '-', '-', '-', '-', '-', '√', '√', '√', '√']
];


export const VIPPage = () => {
    const [lv, sLv] = useState(1);
    const x = ({cell}) => lv + 1 === cell ? 'act' : '';
    const event = {
        mouseEnter: ({cell}) => {
            if(cell>1) sLv(cell - 1)
        }
    };
    const b = (f, c) => {
        const r = [{
            name: 'VIP等级',
            cellClass: c
        }];
        for (let i = 0; i < 9; i++) {
            r.push({
                name: 'VIP' + (i),
                cell: f,
                cellClass: x,
                cls: x,
                event
            })
        }
        return r;
    };

    const r0 = b(f, 'x');
    const T = ({lv}) => {
        const  a0 = d0[1][lv+1]
        const  a1 = d0[0][lv+1]
        const  a2 = d0[2][lv+1]
        return <div className={'d'}>
            <V v={lv} iconOnly/>
            <span>VIP等级 : VIP{lv}</span>
            <h3>累计存款</h3>
            <h1>{a0}元</h1>
            <div className={'i'}>
                <div>{a1}元 / 流水要求</div>
                <div>{a2}元 / 保级流水要求2个月</div>
            </div>
        </div>
    };
    return <Page cls={'vip-page'}>
        <div className={'a'}/>
        <X>
            <div className={'c'}>
                <i className={'c0'} onClick={() => sLv(Math.min(8, Math.max(1, lv - 1)))}/>
                <T lv={lv}/>
                <i className={'c1'} onClick={() => sLv(Math.min(8, Math.max(1, lv + 1)))}/>
            </div>
            <div className={'ls'}>
                <C cur={lv} lv={1} t0={'普及礼金'} t1={'18元'}/>
                <C cur={lv} lv={2} t0={'每月红包'} t1={'VIP2专享'}/>
                <C cur={lv} lv={2} t0={'专属活动'} t1={'VIP2专享'}/>
                <C cur={lv} lv={3} t0={'生日礼金'} t1={'VIP3专享'}/>
                <C cur={lv} lv={3} t0={'专属客服'} t1={'VIP3专享'}/>
                <C cur={lv} lv={4} t0={'佳节礼品'} t1={'VIP4专享'}/>
                <C cur={lv} lv={5} t0={'极速出款'} t1={'VIP5专享'}/>
            </div>
            <div className={'f'}>
                <h1>VIP等级奖列</h1>
                <Table
                    row={r0}
                    data={d0}
                />
            </div>
            <div className={'g'}>
                <h2>活动的一版条款与规则：</h2>
                <p><b>1</b>晋升标准：会员的累计存款以及累计投注额达到相应级别的要求，即可在次日24点前晋级相应VIP等级。</p>
                <p><b>2</b>晋升顺序：VIP等级达到相应的要求可每天晋升一级，但VIP等级不可越级晋升。</p>
                <p><b>3</b>保级要求：会员在达到某VIP等级后，60天内投注需要完成保级要求（当前等级总有效投注额的10%）。如果在此期间完成晋升，保级要求重新按照当前等级计算。</p>
                <p><b>4</b>降级标准：如果会员在60天内没有完成相应的保级要求流水，系统会自动降一个等级</p>
                <p><b>5</b>晋级礼金：晋级礼金可在“用户中心 - 优惠福利”中自行领取，5倍流水即可提款。（每个级别的晋级礼金，每位会员仅能获得1次）</p>
                <p><b>6</b>生日礼金：会员可在生日当天的前后两天内找在线客服申请（例如1月4日生日，则可在1月3日-1月5日内申请）。</p>
                <p><b>7</b>每月红包：会员在上个月有过至少1次的成功存款，即可在每月1号获得上个月相应等级的每月红包彩金。（每月红包可在“用户中心 - 优惠福利”中自行领取）</p>
                <p><b>8</b>周周存送：VIP会员可以每周申请一次对应等级的存送优惠，10倍流水即可提款，不可游戏彩票，存款后不要进行转账和游戏，联系在线客服申请。（20%/888指存送比例为20%，最高赠送888）</p>
                <p><b>9</b>佳节礼品：仅限中国节假日：春节、端午节、中秋节、国庆节、元旦。</p>
                <p><b>10</b>BT游戏保留对活动的修改，停止及最终解释权。</p>
            </div>
        </X>

    </Page>
};
