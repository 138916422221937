import React from 'react';
import {widthStore} from "../../store";
import {Link} from "react-router-dom";
import {routeMap} from "../../routes/routes";
import {isAgentPage, showAna} from "../../utils";
import {popAvatar} from "../../pages/pop/ava";
import {popWallet} from "../../pages/pop/popWallet";
import {panel} from "../tabs";


const SL = ({title, desc,c, act}) => {
    return <i className={c + (act ? ' act' : '')}>
        <div>
            {act?title:desc}
        </div>
    </i>
}

export const BoxMain = widthStore(
    ({globalStore, myBankStore, walletStore}, {match: {path}, children}) => {
        const {
            available,nick_name, vip_level,user_level,bonus_rate,
            has_fund_password, real_name, is_valid_phone, avatar
        } = globalStore;
        const {banks} = myBankStore;
        const hasBank = banks && banks.length;
        const m1 = [
            ['i-h', 'hisLotty'],
            ['i-th', 'foundHis'],
            ['i-wa', 'bank'],
            ['i-pz', 'personal'],
            ['i-fa', 'welfare'],
            ['i-v', 'myVip'],
            ['i-m', 'msg'],
        ];
        const v =  [
            // ['i-g0', 'agent/analysis'],
            ['i-g1', 'agent/openAccount'],
            ['i-c', 'agent/subManage'],
            ['i-wi', 'agent/teamBet'],
            ['i-h1', 'agent/profit'],
            ['i-h2', 'agent/myInc',0],
            ['i-h3', 'agent/foundDetail'],
            ['i-m', 'agent/msg']
        ];
        if(showAna){
            v.unshift(['i-g0', 'agent/analysis'])
        }
        if (bonus_rate && bonus_rate.length) {
            v.splice(2, 0,   ['i-th', 'agent/Contracts'])
        }
        const m2 =v.filter(a=>a[2]!==user_level)

        let mainPath = window.location.pathname.split('/').slice(1).join('/');
        const buildM = (n, cls, na) => {
            const arr = [].concat(n);
            let hasAct = 0;
            if (na) {
                const [c, hAct] = buildM(arr);
                return [
                    <div key={cls} className={'mu' + (hAct ? ' act' : '')}>
                        <span className={`mu_span`}> <i className={cls}/>{na}</span>
                        <div className={`slide-mu-r`}>{c}</div>
                    </div>,
                    hAct
                ]
            } else {
                return [
                    arr.map(k => {
                        const nb = routeMap.getName(k);
                        const act = mainPath === k || path === k;
                        if (act) hasAct = 1;
                        return <div key={k} className={'mu' + (act ? ' act' : '')}>
                            <Link className={'mu_span'} to={'/' + k}>
                                {cls ? <i className={cls}/> : null}
                                {nb}
                            </Link>
                        </div>
                    }),
                    hasAct
                ];
            }
        };
        const main = (walletStore.bls||{}).main
        let vip = 'VIP'+vip_level;
        let cla = '';
        if(isAgentPage()){
            vip=['合伙人'][user_level]||'代理'
            cla= ' u'+((user_level>1)?2:user_level);
        }
        return <>
            <div className={'mu-l'}>
                <div className={'hd'}>
                    <div className={`us`}>
                        <div className={'h'}>
                            <div className={'img ava-' + avatar} onClick={() => popAvatar.show()}/>
                            <div>
                                <div className={`us-name`}>
                                    <div className={'v'+cla}>{vip}</div>
                                    {nick_name}
                                </div>
                            </div>
                        </div>
                        <Link to={'/personal'} className={'ics'}>
                            <SL c={'a'} act={is_valid_phone}
                                desc={'为了帐户与资金安全，请绑定验证手机！'}
                                title={'已绑定手机'}/>
                            <SL c={'b'} act={real_name}
                                desc={'完善帐户信息，查看返水标准！'}
                                title={'已绑定真实姓名'}/>
                            <SL c={'c'} act={has_fund_password}
                                desc={'为了您的资金安全，请先设置资金密码'}
                                title={'已设置资金密码'}/>
                            <SL c={'d'} act={hasBank} title={'已绑定银行卡'}
                            desc={'为了享受更便捷的提款服务，请先绑定银行卡!'}
                            />
                        </Link>
                    </div>
                    <div className={'wall'}>
                        主钱包
                        <div className={'w'}>
                            <i/>{available}
                            <s className={main&&main.loading?'ld':''}
                               onClick={() => walletStore.load().query('main')}/>
                        </div>
                    </div>
                </div>
                {panel(!isAgentPage(),
                    <div className={'mx'}>
                        {panel(user_level, <span onClick={() => popWallet.show(0)}>钱包</span>)}
                        {panel(user_level,<span onClick={() => popWallet.show(1)}>充值</span>)}
                        <span onClick={() => popWallet.show(2)}>提现</span>
                    </div>)}
                <div className={'mus'}>
                    {
                        (isAgentPage() ? m2 : m1).map(m => {
                            if (!m) return null;
                            else {
                                const [a, b, c] = m;
                                return buildM(b, a, c)[0]
                            }
                        })
                    }
                </div>

            </div>
            <div className={'mu-r'}>
                {children}
            </div>
        </>
    });
