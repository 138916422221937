import React, {useEffect, useState} from 'react';
import {widthStore} from "../../store";

export const TimeUp = widthStore(({betListStore}, {onEnd, act}) => {
    const [z, sZ] = useState(false);
    const [n, sN] = useState(0);
    const [v, sv] = useState(false);
    const {traceIssues = []} = betListStore;
    const issue = (traceIssues[1] || {}).number;
    useEffect(() => {
        let tt = -1;
        if (act) {
            let t = 5;
            sZ(true);
            const f = () => {
                sN(t);
                if (t === 4) sv(true);
                if (t < 0) {
                    sZ(false);
                    clearInterval(tt);
                } else if (t === 0) {
                    sv(false);
                    onEnd();
                }
                t--;
            };
            tt = setInterval(f, 1e3);
            f();
        }
        return () => {
            sZ(false);
            sv(false);
            clearInterval(tt);
        }
    }, [act]);
    return <div className={'timeUp ' + (z ? 'z ' : '') + (v ? 'v' : '')}>
        <div className={'issue-ch'}>
            <div>当前已进入</div>
            <div className={'iu'}>{issue}期</div>
            <div>请留意奖期变化({n})</div>
        </div>
    </div>
});
