export const sscwuxingzuxuanzuxuan5 = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	   [0,1,2,3,4,5,6,7,8,9],
       [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	   [0,1,2,3,4,5,6,7,8,9],
       [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
       '四重号',
       '单号'
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str+=i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let arr = [],arr_=[],num=0;
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    if(i==0){
                        arr.push(j);//四重号
                    }else{
                        arr_.push(j);//单号
                    }
                }
            }
        }
        if(arr.length >=1 && arr_.length>=1){
            for(let i=0,len_i = arr.length;i<len_i;i++){
                let obj = arr[i];
                for(let j = 0,len_j = arr_.length;j<len_j;j++){
                    if(arr_[j]!=obj){
                        num++;
                    } 
                }
            }
            return num;
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str+=i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += views[i][j];
				}
		    }
    	}
	    return str;
    }
}
