import React from 'react';
import {Page} from "../common/page";
import './app.scss'
import {Link} from "react-router-dom";
import {AppCode, X} from "../common/com";

export const AppPage = () => {
    const {protocol,host} = window.location;
    return <Page cls={'app'}>
        <X>
            <div className={'inf'}>
                <h1>自研内核 畅享游戏</h1>
                <p>同行业最安全、游戏最全的手机投注APP</p>
                <span>
                    全球首家一体化娱乐原生APP，尽显流畅、完美操作。海量体育、
                    电竞顶尖赛事，真人娱乐、彩票投注等，最新最全娱乐项目尽在掌
                    中体验扫码下载，即刻拥有!
                </span>
                <div>
                    <div className={'a'}>
                       <AppCode/>
                        <span>扫码下载APP</span>
                        <p>支持IOS & Android 全部移动设备</p>
                        <p>苹果用户扫码后 选择 safari 浏览器打开</p>
                        <span>{protocol+'//'+host}</span>
                    </div>
                    <div className={'a'}>
                        <i className={'h5'}/>
                        <span>无需下载直接访问</span>
                        <p>无需下载，手机输入网址即可访问</p>
                        <span>{protocol+'//'+host}</span>
                    </div>
                </div>
            </div>
        </X>
    </Page>
};
