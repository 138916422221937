import React from 'react';
import {stores, history} from "../../store"
import {Observer} from "mobx-react-lite";
import "./dash.scss";
import {BoxMain} from "./SideMenue";
import {Foot, Foot2, HeadA, HeadB} from "../../pages/common/com";
/**
 * 配置标题
 * @typedef DashBoardNameObject
 * @property {string} name 显示内容
 * @to {string} 跳转链接
 */

/**
 * 动态返回标题
 * @typedef DashBoardNameFunction(match,stores)
 * @type function
 * @param {object} match 路由匹配信息
 * @param {object} stores
 */

/**
 * @param {[DashBoardNameObject|DashBoardNameFunction|string]} name 多标题
 * @param children 子元素
 * @param match props 自动传入 匹配的路由信息
 * @param history 操作历史记录
 * @return {*}
 * @constructor
 */

export  const DashBoard = ({children, match, cls = ''}) => {
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'dashboard full '}>
            <div className={'d-container'}>
                <BoxMain history={history} match={match}>
                    <div className={'main-page'}>
                        <div className={'dash-main ' + cls}>
                            {children}
                        </div>
                    </div>
                </BoxMain>
            </div>
        </div>
        <Foot2/>
        <Foot/>
    </div>
};


export const widthDashBoard = (fc,cfg={}) => props => <DashBoard {...props} {...cfg}>
        <Observer>
            {() => fc(props, stores)}
        </Observer>
    </DashBoard>;

