import "./select.scss"
import React, {useState, useEffect} from 'react';

const o = {};
let count = 0;
/**
 * 下拉选择框
 * @param prefix
 * @param className
 * @param value
 * @param placeholder
 * @param {function} onChange  cb(value)
 * @param {Array} items
 * @param {String} loading
 * @param {function} render (v)=>[key,text]  key - 值 ，text - 显示文字
 * @return {*}
 * @constructor
 */
export const Select = ({
                           prefix = '',
                           suffix = '',
                           className = "",
                           placeholder = "",
                           value,
                           loading = "",
                           onChange,
                           items = [],
                           render = ([a, b, c]) => [a, b, c],
                       }) => {
    const [act, setAct] = useState(false);
    const ls0 = items.map(render);
    const ls = placeholder ? [["", placeholder]].concat(ls0) : ls0;
    let sel = ls.find(c => (c[0] + "") === (value + "")) || [];
    const f = () => setAct(false);
    useEffect(() => {
        if (act) {
            window.addEventListener('click', f);
        } else {
            window.removeEventListener('click', f);
        }
        return () => {
            window.removeEventListener('click', f);
        }
    }, [act, f]);
    useEffect(() => {
        if (loading) return;
        const k = ++count;
        o[k] = setAct;
        if (!sel.length && ls.length) {
            onChange(ls[0][0]);
        }
        return () => delete o[k];
    }, [items]);
    return <div className={"x-select " + className + (act ? " on" : "")}>
        <div onClick={(e) => {
            if (loading) return;
            e.stopPropagation();
            e.preventDefault();
            Object.keys(o).forEach(c => o[c](o[c] === setAct ? !act : false));
        }
        }>{loading || (prefix + sel[1]+suffix)}</div>
        {(loading || ls.length <= 1) ? null : <div className={(act ? "act" : "") + " opts"}>
            {ls.map(([k, v, vv], i) => {
                const isCur = (k + "") === (value + "");
                return <div
                    onClick={() => {
                        setAct(false);
                        if (!isCur) {
                            onChange(k)
                        }
                    }}
                    key={i}
                    className={isCur ? 's' : ''}>
                    {vv || v}
                </div>
            })}
        </div>}
    </div>
};
