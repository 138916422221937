import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Page} from "../common/page";
import {history, widthStore} from "../../store";
import {Loading} from "../../components/loading";
import {BubbleBtn, FakeNum, FakeToday, X} from "../common/com";

import './lotto.scss';
import {Link} from "react-router-dom";
import {useQuery} from "../../hooks";
import {panel, Tabs} from "../../components/tabs";
import {animate, randArr, scrollToTop, service, WS} from "../../utils";
import {Portals} from "../../components/portals";

const cMap = {
    SSC: '时时彩',
    DPC: '低频彩',
    '11-5': '11选5',
    K3: '快三',
    PK10: 'PK10',
};

const Card = ({cfg, show, d}) => {
    const timer = useMemo(() => ({}), []);
    const {id, cate, name} = cfg;
    const [issue, setIssue] = useState('-');
    const [num, setNum] = useState('');
    const [tm, sT] = useState('');
    const [left, sL] = useState('--:--');
    const [next, sN] = useState(60);
    const [start, sS] = useState(0);
    const [start1, sS1] = useState(0);
    const [end, sE] = useState(0);
    let ns = num.split(/[^\d?]/);
    if (ns.length === 1) ns = num.split('');
    const [query, ld, re] = useQuery('issue');
    const res = useMemo(() => d || re, [d, re])
    const loading = useMemo(() => d ? 0 : ld, [d, ld])
    const getIssue = useCallback(
        () => {
            if (d) {
                return;
            }
            query({
                lottery_id: id,
                is_game_numbers: 0,
                is_need_last_winnuber: 1
            })
        }, [d]);
    const getTm = m => [Math.floor(m / 3600), Math.floor((m % 3600) / 60), Math.floor(m % 60)];
    const f = (x, y) => x ? x + y : '';
    const ff = x => (100 + x + '').substr(1);
    const fmtTime = d => {
        const dur = parseInt(d);
        return !loading && dur ? getTm(dur).map(ff).join(':') : '等待更新...';
    }
    const getLf = useCallback(() => {
        sL(Math.max(next - (end - start + Date.now() - start1) / 1000, 0) + '');
    }, [end, next, start, start1]);
    useEffect(() => {
        const t = setInterval(getLf, 500);
        return () => clearInterval(t);
    }, [loading, res, getLf]);
    useEffect(() => {
        if (!loading && res) {
            const {
                cur_issue_time,
                cur_issue,
                cur_time,
                delay,
                last_win_number,
                cycle
            } = res;
            let [a, b, c] = getTm(cycle);
            sN(cycle);
            sT(f(a, '时') + f(b, '分') + f(c, '秒'));
            setNum(last_win_number);
            sS1(Date.now());
            sE(cur_issue_time);
            setIssue(cur_issue);
            if (cur_time) sS(cur_time);
            else if (delay) sS(Date.now() - delay);
        }
        clearTimeout(timer.a);
        timer.a = setTimeout(getIssue, next * 1000);
        return () => clearTimeout(timer.a);
    }, [loading, next, res]);

    useEffect(() => getIssue(), []);
    return show ? <div
        className={'l-card'}>
        <i className={'i-' + cate}/>
        <label>{name}</label>
        <div className={'mk'}>
            <span>第{issue}期开奖号</span>
            <div className={'n'}>
                {(ns&&ns.length)?
                    ns.map((a, i) => a ? <b key={i} className={'i-' + a}>{a}</b> : null)
                    :<span>正在开奖...</span>
                }
            </div>
            <div className={'inf'}>
                <div className={'tm'}><i><i/></i><span> {tm} / 期</span></div>
                <div>{fmtTime(left)}</div>
            </div>
            <div className={'msk'}>
                <Link to={`/bet/${id}/${cate}`}>
                    <BubbleBtn>
                        <span>进入游戏</span>
                    </BubbleBtn>
                </Link>
            </div>
        </div>
    </div> : null
};

const C = ({d, className, children}) => {
    const {id, cate, n0, n1} = d || {};
    return <div className={className}
                onClick={() => history.push(`/bet/${id}/${cate}`)}>
        <span>{children}</span>
        <div className={'c-lo'}>
            <label>{n0}</label>
            <b className={'v-' + cate}>
                {n1}
            </b>
        </div>
        <R cls={'a'} r={45}/>
        <R cls={'b'} r={-90}/>
        <R cls={'c'} r={65}/>
        <R cls={'d'} r={-25}/>
    </div>
}
const R = ({cls, r}) => {
    const ctx = useMemo(() => ({t: 0, r: r, v: (Math.random() / 20 + 0.01) * (r > 0 ? 1 : -1)}), [])
    const [_, c] = useState(0)
    useEffect(() => {
        return animate([0, 1], {
            r: 1,
            next(v) {
                ctx.t++;
                if (ctx.t % 5 === 0) {
                    ctx.r = (ctx.r + ctx.v + 0.2) % 360;
                    c(v)
                }
            }
        })
    }, [])
    const s = {
        transform: `rotateZ(${ctx.r}deg)`
    }
    return <i className={'rv ' + cls} style={s}/>
}
export const LHead = widthStore(({betStore, globalStore: {token}}) => {
    const [gRec, ld, rec] = useQuery('getRecentGame');
    const [r, rr] = useState([])
    const {allLottery, lotteries} = betStore;
    useEffect(() => {
        if (token) gRec()
    }, [token])
    useEffect(() => {
        if (rec) {
            const w = {}
            const ids = rec.map(i => {
                const {lottery_id, ways} = i;
                w[lottery_id] = (w[lottery_id] || []).concat(ways || [])
                return lottery_id
            });
            const s = [];
            lotteries.forEach(a => {
                if (ids.includes(a && a.id)) {
                    const o = {
                        a: a,
                        w: w[a.id] || []
                    }
                    s.push(o);
                }
            })
            rr(s.slice());
        }
    }, [lotteries, rec])
    const rs = r.slice(0, 3).map(({a: {name, id, cate}, w}, i) => <div key={i}>
        <label title={name} onClick={() => history.push(`/bet/${id}/${cate}`)}>{name}</label>
        {randArr(w, 2).map((e, i) => {
            const {way_name, way_id} = e
            return <Link key={i} to={`/bet/${id}/${cate}/${way_id}`} title={way_name}>{way_name}</Link>
        })}
    </div>);
    const ks = ['SSC', '11-5', 'K3', 'PK10', 'DPC'];
    const bs = useMemo(() => {
        const a = [];
        const nm = ['时时彩', '快3', '11选5', 'PK10'];
        ['SSC', 'K3', '11-5', 'PK10'].forEach((k, i) => {
            const r = randArr(allLottery[k], 1)[0];
            if (r) {
                const {name, id, cate} = r;
                const [c = name, d = nm[i]] = (/^(.*?)(时时彩|分分彩|11选5|快3)/g.exec(name) || []).slice(1);
                a[i] = {
                    id, cate, n0: d, n1: c
                }
            }
        })
        return a
    }, [allLottery])
    const ls = n => {
        return n.map(({name, name_cn, id, cate}, i) => <Link key={i} to={`/bet/${id}/${cate}`}>{name || name_cn}</Link>)
    }
    return <div className={'lo-head'}>
        <div className={'lo-w'}>
            <div className={'w0'}>
                {ks.map(k => {
                    const gs = (allLottery[k] || []).slice();
                    if (k === 'DPC') gs.push(...allLottery['PLW'] || [])
                    const a = gs.slice(0, 2);
                    const b = gs.slice();
                    return <div className={'w01'} key={k}>
                        <i className={'i-' + k.toLowerCase()}/>
                        <div className={'w02'}>
                            <label>{cMap[k] + '系列'}</label>
                            <div>{ls(a)}</div>
                        </div>
                        {panel(b.length, <i/>)}
                        {panel(b.length, <div className={'mr r-' + k.toLowerCase()}>{ls(b)}</div>)}
                    </div>
                })}
            </div>
            <div className={'w1'}>
                <C className={'w10'} d={bs[0]}>BT·HOT</C>
                <C className={'w11'} d={bs[1]}>BT·NEW</C>
                <C className={'w12'} d={bs[2]}>BT·NEW</C>
                <C className={'w13'} d={bs[3]}>BT·HOT</C>
            </div>
            <div className={'w2'}>
                <div className={'rc'}>
                    {rs}
                    {panel(!rs.length, <div className={'nod'}><i/>您还没有游戏...</div>)}
                </div>
                <div className={'rd'}>
                    <div className={'rd0'}>
                        <i/>
                        <span><FakeNum a={2000} b={a => a.replace(/\..*/, '')} t={10}/>人在玩...</span>
                    </div>
                    <div className={'rd1'}>
                        {ls(randArr(lotteries, 8))}
                    </div>
                </div>
            </div>
        </div>
    </div>
});

export const LottoPage = widthStore(({betStore, globalStore: {token}}) => {
    const {loading, lotteries} = betStore;
    const [ca, sC] = useState(0);
    const [da, sDa] = useState([]);
    const ck = cycle => {
        let c = Math.floor(cycle / 60)
        if (c > 60) c = 61
        return c
    }
    const cfg = useMemo(() => {
        const o = {}
        const b = [0]
        lotteries.forEach(({cycle}) => {
            const c = ck(cycle)
            if (!o[c]) {
                o[c] = 1;
                b.push(c)
            }
        });
        b.sort((a, b) => a > b ? 1 : -1);
        return {
            cycle: b[ca],
            tbs: b.map(a => a === 61 ? '其它' : a ? a + '分钟开奖' : '全部游戏')
        }
    }, [ca])


    const cycle = cfg.cycle;


    const getD = useCallback((id) => {
        // return da.find(a => +a.lottery_id === +id) || (token ? {
        //     cur_issue_time:"",
        //     cur_issue:"",
        //     cur_time:"",
        //     last_win_number:"",
        //     cycle:0
        // } : null);
    }, [da, token]);
    return <Page
        head={<>
            <LHead/>
            <FakeToday/>
        </>}
        cls={'lotto'}
    >
        <X>
            <b>所有游戏 GAMES</b>
            <Tabs change={sC}>{o => <div className={'tm-filter'}>
                {cfg.tbs.map((a, i) => <div key={i} {...o(i)}>
                    {a}
                </div>)}
            </div>}</Tabs>

            <div className={'ls'}>
                {lotteries.map((o) => {
                        return <Card
                            d={getD(o.id)}
                            show={!cycle || ck(o.cycle) === cycle}
                            cfg={o}
                            key={o.id}
                        />
                    }
                )}
                <Loading act={loading}>正在加载..</Loading>
            </div>
        </X>
        <Portals>
            <div className={'toTop'}>
                <div className={'s'} onClick={() => service.open()}>
                    <span>联系客服</span>
                </div>
                <div className={'t'} onClick={scrollToTop}>
                    <span>返回顶部</span>
                </div>
            </div>
        </Portals>
    </Page>
});
