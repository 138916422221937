import React, {useEffect, useState} from 'react';
import {widthStore} from "../../store";
import {getGameWay, useGameWay} from "../../hooks";
import {BetHeadSelect} from "./selectHead";
import {getPos} from "../../utils";


const Tip = widthStore(({globalStore,betListStore,betStore},{tipInfo, loading}) => {
    const {prize_group, max_bet_prize_group} = globalStore;
    const mxGroup = Math.min(max_bet_prize_group, prize_group);
    const [e, ee] = useState(null);
    const [[[x, y], bet, bonus, pr], sP] = useState([[0, 0], null, null]);
    const [target, pid, id] = tipInfo || []
    useEffect(() => {
        if (target && pid && !loading && e) {
            const {way0, moneyunit} = betStore;
            const {2: w2} = getGameWay(
                way0, pid, id,
                'way0', 'way1', 'way2'
            );
            const {bet_note, bonus_note,prize} = w2;
            const pp =  prize/mxGroup*(betListStore.prizeGroup ||mxGroup)* moneyunit;
            const pr =pp?pp.toFixed(2):'--'
            sP([getPos(target, e), bet_note, bonus_note, pr]);
        }
    }, [e, id, loading, pid, target])
    const style = {
        opacity: pid ? 1 : 0,
        left: x + 'px',
        top: y + 'px'
    };
    return <div className={'info-box'} ref={ee} style={style}>
        <div className={'p'}><label>单注奖金</label>：<span>{pr}</span></div>
        <div className={'i'}>
            <p><label>玩法介绍</label>：{bet}</p>
            <p>{bonus}</p>
        </div>
    </div>
});

export const GameWay = widthStore
(({betStore, betListStore}, {loading}) => {
    const {headerData, way0, way1, way2, playGame: game} = betStore;
    const [w0, w1, w2] = useGameWay(loading, headerData);
    const {name_cn: n0 = ''} = w0;
    const {name_cn: n1 = ''} = w1;
    const {name_cn: n2} = w2;
    const [tipInfo, setTip] = useState(null);
    useEffect(() => {
        const a = [n0, n1, n2].join();
        const b = betStore.cnName.join();
        if (a !== b && n0 && n1 && n2) betStore.cnName = [n0, n1, n2]
    }, [n0, n1, n2]);

    const {
        mark = '',
        positionView, position, positionMin
    } = game;
    const betHead = positionView && positionView.length && <BetHeadSelect
        key={'a' + mark}
        value={position}
        view={positionView}
        min={positionMin}
        change={v => {
            game.position = v.concat();
        }}
    /> || "";

    return <>
        <div className={"cate-box"}>
            <div className={"cate"}>{
                headerData.map(({name_cn, id}) => <div
                    key={'w-' + id}
                    className={"w-0" + (id === way0 ? " act" : "")}
                    onClick={() => {
                        betStore.gKey = null;
                        betStore.way0 = id
                    }}>
                    <span>{name_cn}</span>
                </div>)
            }</div>
            <div className={"sub-cate"}>
                {w0.children.map(({name_cn, id, children}) =>
                    <div key={'w1-' + id}
                         className={"w2"}>
                        <label>{name_cn}</label>
                        <div className={"w3"}>{
                            children.map(({name_cn: n, id, pid}) => <div
                                key={'w2-' + id}
                                onClick={() => {
                                    betStore.gKey = null;
                                    betStore.way1 = pid;
                                    betStore.way2 = id;
                                }}
                                onMouseEnter={({target}) => setTip([target, pid, id])}
                                onMouseLeave={({target}) => setTip([target])}
                                className={'w-3' + (pid === way1 && id === way2 ? ' act' : '')}>
                                <span>
                                    {n}
                                </span>
                            </div>)
                        }</div>
                    </div>)}
                <div className={"bet-inf"}>
                    {betHead}
                    <div className={'opt-se'}/>
                </div>
            </div>
        </div>
        <Tip tipInfo={tipInfo} loading={loading}/>
    </>
});
