/**
 * 走势钩子
 */
import {useMemo, useEffect} from 'react';
import {trendsStore} from "../store/trend";

const clearLis = [];
/**
 * 渲染内容表格
 * @param key
 * @param name
 * @param idx
 * @param fx
 * @return {{r: number, act: number, value: number}|{r: number, value: number, m: number}|{name: *, fmt({lottery?: *}): (*|*)}}
 */
const renderNum = (key, name, idx, fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: name,
        fmt({lottery}) {
            const v = fx(lottery)[idx];
            const act = +v === +key;
            if (act) {
                before = 0;
                return {act: 1, value: parseInt(v), r: 1,g:1}
            } else return {value: ++before, r: 1, m: 1,g:1}
        }
    }
};
// 号码分布
const scatterNum = (key, name, fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: name,
        fmt({lottery}) {
            const d = {};
            fx(lottery).forEach(n => {
                n = parseInt(n);
                d[n] = d[n] + 1 || 1;
            });
            const v = d[key];
            if (v) {
                before = 0;
                return {r: 1, value: key, cls: ['', 'act1', 'act2', 'act3'][v]}
            } else return {value: ++before, r: 1}
        }
    }
};
const bsForm = (all = [], fx, cls) => {
    const mid = (all[0] + all[all.length - 1]) / 2;
    return {
        name: '大小形态',
        fmt: ({lottery}) => ({value: fx(lottery).map(i => +i >= mid ? '大' : '小').join(''), cls})
    }
};
const obForm = (fx, cls) => {
    return {
        cls,
        name: '单双形态',
        fmt: ({lottery}) => fx(lottery).map(i => ['双', '单'][+i % 2]).join('')
    }
};
const udForm = (fx) => {
    return {
        name: '升平降形态',
        fmt: ({lottery}, old) => {
            const a = fx(lottery);
            if (old) {
                const f = (d, e) => {
                    const r = d - e;
                    return r > 0 ? '降' : r === 0 ? '平' : '升'
                };
                const b = fx(old.lottery);
                return a.map((n, i) => f(n, b[i])).join(' ');
            }
            return a.map(() => '-').join(' ');
        }
    }
};
const _isP = {2: 1, 3: 1, 5: 1, 7: 1, 11: 1, 13: 1, 17: 1};
const isPrime = n => {
    const a = _isP[n];
    if (a !== undefined) return a;
    const mx = Math.floor(Math.sqrt(n));
    for (let i = 3; i < mx; i += 2) {
        if (n % i === 0) return _isP[n] = 0;
    }
    return _isP[n] = 1;
};
const oeForm = (fx, cls) => {
    return {
        name: '质合形态',
        fmt: ({lottery}) => ({cls, value: fx(lottery).map(i => ['质', '合'][isPrime(i)]).join('')})
    }
};
const otForm = (fx, cls) => {
    return {
        name: '012形态',
        fmt: ({lottery}) => ({cls, value: fx(lottery).map(i => i % 3).join('')})
    }
};
const leopard = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '豹子',
        fmt: ({lottery = []}) => {
            const l = fx(lottery);
            const o = l[0];
            const b = l.slice(1);
            for (let i = 0, l = b.length; i < l; i++) {
                if (b[i] !== o) return {value: ++before, r: 1}
            }
            return {cls: 'r', r: 1};
        }
    }
};
const g3 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '组三',
        fmt: ({lottery = []}) => {
            const o = {};
            fx(lottery).forEach(c => o[c] = 1);
            return Object.keys(o).length === 2 ? {cls: 'r', r: 1} : {value: ++before, r: 1};
        }
    }
};
const same2 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '对子',
        fmt: ({lottery}) => {
            const [a, b] = fx(lottery);
            return a === b ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const same32 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '二同号',
        fmt: ({lottery}) => {
            const [a, b,c] = fx(lottery);
            const o={};
            o[a]=o[b]=o[c]=0;
            return Object.keys(o).length===2 ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const same3 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '三同号',
        fmt: ({lottery}) => {
            const [a, b, c] = fx(lottery);
            return a === b === c ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const dff3 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '三不同',
        fmt: ({lottery}) => {
            const [a, b, c] = fx(lottery);
            return a!==b&&b!==c&&c!==a ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const squ3 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '三连号',
        fmt: ({lottery}) => {
            const [a, b, c] = fx(lottery).sort();
            return +a+1===+b&&+b+1===+c ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const g6 = (fx) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: '组六',
        fmt: ({lottery}) => {
            const [a, b, c] = fx(lottery);
            return a !== b && a !== c && b !== c ? {cls: 'r', r: 1} : {r: 1, value: ++before};
        }
    }
};
const span = (fx, cls) => {
    return {
        name: '跨度',
        fmt: ({lottery}) => {
            const o = fx(lottery).sort((a, b) => a - b);
            return {
                cls,
                value: o[o.length - 1] - o[0]
            }
        }
    }
};
const spanTrend = (key, name, fx,cls) => {
    let before = 0;
    clearLis.push(() => before = 0);
    return {
        name: name,
        fmt({lottery}) {
            const o = fx(lottery).sort((a, b) => a - b);
            const v = +o[o.length - 1] - o[0];
            if (v === key) {
                before = 0;
                return {value: key, r: 1, cls: cls||'blu1'}
            } else return {value: ++before, r: 1}
        }
    }
};
const total = (fx, cls, name = '和值') => {
    return {
        name,
        fmt({lottery}) {
            return {
                cls,
                value: fx(lottery).reduce((a, b) => +a + (+b), 0)
            }
        }
    }
};
const totalEnd = (fx) => {
    return {
        name: '和值尾数',
        fmt({lottery}) {
            return {
                value: fx(lottery).reduce((a, b) => +a + (+b), 0) % 10
            }
        }
    }
};

function clear() {
    clearLis.forEach(f => f());
}

const n10 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const n15 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const n11 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const groupNum = (idx, num = [], fx) => num.map((n) => renderNum(n, n, idx, fx));
const groupScatter = (num = [], fx) => num.map(n => scatterNum(n, n, fx));
const groupSpanTrend = (num = [], fx) => num.map(n => spanTrend(n, n, fx,'blu'));
const pos = (fx, name = ['万', '千', '百', '十', '个'], nn = n10) => fx(name).map((n, i) => (
    {name: n, children: groupNum(i, nn, fx)}));

const fm = (nn, fx, key) => {
        return {
            bsForm: bsForm(nn, fx, 'blu1'),
            obForm: obForm(fx, 'gre'),
            udForm: udForm(fx),
            oeForm: oeForm(fx, 'blu1'),
            otForm: otForm(fx, 'gre'),
            leopard: leopard(fx),
            g3: g3(fx),
            g6: g6(fx),
            span: span(fx, 'blu'),
            total: total(fx, 'red', '直选和值'),
            total1: total(fx,),
            totalE: totalEnd(fx),
            squ3: squ3(fx),
            same2: same2(fx),
            dff3: dff3(fx),
            same32: same32(fx),
            same3: same3(fx),
            scatter: {name: '号码分布', cls: 'small', children: groupScatter(nn, fx)},
            spanTrend: {name: '跨度走势', cls: 'small', children: groupSpanTrend(nn, fx)},
            cpSum: {
                name: '冠亚和',
                cls: 'gyh',
                children: [
                    {
                        name: '大小单双', fmt: ({lottery}) => {
                            const [a, b] = fx(lottery);
                            const s = a + b;
                            const big = s > 11?'大':'小';
                            const odd = s % 2 === 1?'单':'双';
                            return {type: 'cp', value: big+odd};
                        }
                    },
                    {
                        name: '升平降', fmt: ({lottery}) => {
                            const [a, b] = fx(lottery);
                            const r = a - b;
                            return {
                                type: 'ud', value: r > 0 ? '降' : r === 0 ? '平' : '升'
                            }
                        }
                    },
                    {name: '和值', fmt: ({lottery}) => fx(lottery).reduce((a, b) => a + parseInt(b), 0)},
                ]
            }
        }[key];
    }
;

const row3 = ['scatter', 'bsForm', 'obForm', 'oeForm', 'otForm', 'leopard', 'g3', 'g6', 'span', 'total', 'totalE'];
const sscCate = [
    ['五星', [0, 1, 2, 3, 4], ['scatter']],
    ['四星', [1, 2, 3, 4], ['scatter']],
    ['前三', [0, 1, 2], row3],
    ['中三', [1, 2, 3], row3],
    ['后三', [2, 3, 4], row3],
    ['前二', [0, 1], ['scatter', 'spanTrend', 'total1']],
    ['后二', [3, 4], ['same2', 'scatter', 'spanTrend']],
];
const dpcCate = [
    ['三星', [0, 1, 2], row3]
];
const _115Cate = [
    ['五星', [0, 1, 2, 3, 4], ['scatter']]
];
const _k3Cate = [
    ['三星', [0, 1, 2], ['scatter', 'total1', 'same3','same32','dff3','squ3']]
];
const pk10Cate = [
    ['冠亚军', [0, 1], ['scatter', 'spanTrend', 'cpSum']],
    ['前五名', [0, 1, 2, 3, 4], ['bsForm', 'obForm', 'udForm']],
    ['后五名', [5, 6, 7, 8, 9], ['bsForm', 'obForm', 'udForm']],
];
/**
 *
 * @param cf 基础配置
 * @param na 名称
 * @param nn 号码位置对应名字
 * @return {*}
 */
const cover = (cf, na, nn = n10) => cf.map(([name, mp, other = []]) => {
    const fx = ls => mp.map(n => ls[n]);
    return {
        name: name,
        head: [
            {key: 'issue', name: '期号'},
            {name: '开奖号码', fmt: ({lottery}) => ({value: lottery.join(' '), cls: 'num'})},
        ].concat(
            pos(fx, na, nn),
            other.map(c => fm(nn, fx, c))
        )
    }
});
const cfg = {
    k3: cover(_k3Cate, ['百', '十', '千'], [1, 2, 3, 4, 5, 6]),
    '3d': cover(dpcCate, ['百', '十', '个']),
    p35: cover(dpcCate, ['百', '十', '个']),
    ssc: cover(sscCate),
    115: cover(_115Cate, undefined, n11),
    dpc: cover(dpcCate, ['百', '十', '个']),
    plw: cover(dpcCate, ['百', '十', '个']),
    pk10: cover(pk10Cate, ['冠军', '亚军', '第三名',
        '第四名', '第五名', '第六名', '第七名', '第八名',
        '第九名', '第十名'], n15)
};

/**
 *
 * @param type 彩种类型
 * @param data 走势数据
 * @param actCate  当前选中类型
 * @return {any[]}
 */
export const useTrend = (type, data = [], actCate) => {
    clear();
    useEffect(() => {
        trendsStore.act = 0;
    }, [type]);
    return useMemo(() => {
        const _cfg = cfg[type] || [];
        const actCfg = (_cfg[actCate] || {}).head || [];
        const group = [];
        const renderFmt = (o, inf, x, y) => {
            //   special:0,  特殊值
            //   miss:0,     遗漏
            //   maxMiss:0,  最大遗漏
            //   keep:0,     连续
            //   n:0,        总次数
            //   maxKeep:0,  最大连续
            //   before:0    上次
            if ('object' === typeof o) {
                const {act, cls, value: vv, r, m, type} = o;
                const value = parseInt(vv);
                const {lastMiss, actMap} = inf;
                if (r) {
                    inf.n++;
                    if (inf.before === value) {
                        inf.keep++;
                    } else {
                        const {maxKeep, keep} = inf;
                        inf.before = value;
                        inf.maxKeep = Math.max(keep, maxKeep);
                        inf.keep = 0;
                    }
                    if (act || cls) {
                        if (act) {
                            actMap[vv] = actMap[vv] || [];
                            if (lastMiss) {
                                lastMiss.length = [];
                            }
                            inf.special++;
                            o.pos = {x, y};
                        }
                    } else {
                        inf.miss += value;
                        inf.maxMiss = Math.max(inf.maxMiss, value);
                    }
                } else {
                    inf.empty = 1;
                }
                const rt = {type, value: vv, cls: cls || (act ? ' act' : o.g?'hi':''), pos: o.pos, m};
                if (act && actMap[vv]) {
                    actMap[vv].push(rt);
                }
                if (m && !act) {
                    lastMiss.push(rt);
                }
                return rt;
            } else inf.empty = 1;
            return o;
        };
        if (data.length) {
            actCfg.forEach(({name, key, fmt: ff, children, cls = ''}) => {
                const hd = (k, r) => {
                    const o = [
                        [{
                            key: k,
                            cls,
                            rs: children ? 1 : 2,
                            cs: children ? children.length : 1,
                            value: name
                        }],
                        children ? children.map(({name}) => ({value: name, cls: cls + ' cc'})) : null
                    ];
                    if (r) o.reverse();
                    return o;
                };
                const bd = [];
                const go = () => ({
                    n: 0,
                    special: 0,
                    miss: 0,
                    maxMiss: 0,
                    keep: 0,
                    maxKeep: 0,
                    before: 0,
                    lastMiss: [],
                    actMap: {},
                });
                const fd = [];
                const inf = [go()];
                let last;
                data.forEach((d, x) => {
                    let z;
                    if (!children) z = [
                        renderFmt(ff ? ff(d, last) : d[key], inf[0])
                    ];
                    else if (children) z =
                        children.map(({fmt}, iii) => {
                            if (!inf[iii]) inf.push(go());
                            const o = inf[iii];
                            return renderFmt(fmt(d, last), o, x, iii)
                        });
                    last = d;
                    bd.push(z);
                });
                inf.forEach(({actMap, lastMiss}) => {
                    Object.keys(actMap).forEach(k => {
                        const o = actMap[k];
                        if (o.length < 4) {
                            o.forEach(z => z.cls = z.cls + (o.length < 3 ? ' h0' : ' h1'))
                        }
                    });
                    lastMiss.forEach(r => r.miss = 1);
                });
                let lastBall;
                if (bd[0].length) bd.forEach(b => {
                    const o = b.find(({pos}) => pos);
                    if (o) {
                        const {x, y} = o.pos;
                        if (lastBall) {
                            const {x: _x, y: _y} = lastBall.pos;
                            const [a, b] = [x - _x, y - _y];
                            o.line = {
                                width: (Math.sqrt(a * a + b * b) * 100).toFixed(2) + '%',
                                transform: 'rotateZ(' + (180 + (180 * Math.atan2(a, b) / Math.PI)).toFixed(3) + 'deg)'
                            };
                        }
                        lastBall = o;
                    }
                });
                if (key === 'issue') fd.push(
                    ['出现总次数'],
                    ['平均遗漏值'],
                    ['最大遗漏值'],
                    ['最大连出值']
                );
                else {
                    const t = [];
                    inf.forEach(({
                                     n, special, miss, maxMiss,
                                     keep, maxKeep, empty
                                 }) => {
                        if (empty) t.push(['', '', '', '']);
                        else t.push(
                            [special, Math.floor(miss / n), maxMiss, Math.max(keep, maxKeep)]
                        );
                    });
                    for (let i = 0; i < 4; i++) {
                        fd.push(t.map((c) => c[i]))
                    }
                }
                group.push([
                    hd('a'),
                    bd,
                    fd.concat(hd('b', 1))
                ]);
            })
        }
        return [_cfg.map(({name}) => name), group];
    }, [type, data, actCate])
};
