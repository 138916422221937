export const _115ermazuxuanfushi = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']
    ],
    views:[
	    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']
    ],
    chooseType:['全','大','小','奇','偶','清'],
    titles: [
	    '前二'
    ],
    getLottery:(num,balls)=>{
        let str = '',str_ = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            str += i==0?'':'|';
            str_ = '';
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
			    if(balls[i][l] > 0){
				    str_ += str_?(' '+num[i][l]):num[i][l];
				}   
		    }
            str += str_;
    	}
	    return str;
    },
    getNum:(balls)=>{
        let len_all = 0,num=0;
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>0){
                    len_all++;
                }
            }
        }
        if(len_all>=2){
            num = len_all*(len_all-1)/2
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = '',str_ = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            str += i==0?'':'|';
            str_ = '';
            for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
                if(balls[i][l] > 0){
                    str_ += str_?(' '+views[i][l]):views[i][l];
                }   
            }
            str += str_;
        }
        return str;
    }
}
