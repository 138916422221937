import React, {useRef, useState, useEffect, useMemo} from 'react';
import {useQuery} from "../../../hooks";
import {Select} from "../../../components/select/select";

import './wa.scss'
import {widthStore} from "../../../store";
import {tip} from "../../../utils";
import {DashTitle, Tip} from "../../common/com";
import {change} from "../../../components/pwd";

const W = widthStore(({globalStore}, {o, status = 0, ch, query}) => {
    const {name, loading, id, balance} = o;
    const ft = !loading && !+balance;
    const cls = ['', 'ou', 'in'][status] + (ft ? '' : ' sm');
    const c = (+balance || 0).toFixed(2);
    const v = +balance;
    const v2 = parseFloat(globalStore.available);
    return <div className={'wa ' + cls}>
        <div className={'a'}>
            <i className={'w-ico-' + id}/>
            <label>{name}</label>
            {ch && o.id !== 'main' ? <div className={'b'}>
                {!ft ? <div onClick={() => v && ch(id, 1)} className={'g' + (v ? '' : ' dis')}>转出</div> : null}
                <div onClick={() => ch(id, 2)} className={'e' + (v2 ? '' : ' dis')}>转入</div>
            </div> : null}
        </div>
        <i
            onClick={query}
            className={'l' + (loading ? ' act' : '')}/>
        {!ft ? <div className={'f'}>
            <div className={'c'}>
                {loading ? <span>加载中</span> :
                    ch ? <span>￥<b>{c}</b></span> : <span>{c}</span>
                }
            </div>
        </div> : null}
    </div>
});

export const WalletLs = widthStore(({walletStore}, {onLoad, onChange, status, hideMain, cls = ''}) => {
    const ws = walletStore.wallets;
    return <div className={'wallet-ls ' + cls}>
        {ws.filter(({id}) => {
            if (hideMain && id === 'main') return 0;
            return 1
        }).map((k, i) => <W
            key={k.id + '' + i}
            o={k}
            onLoad={onLoad}
            ch={onChange}
            query={() => walletStore.query(k.id)}
            status={status && status(k.id)}/>
        )}
    </div>
});

const bs = {};
export const Wallet = widthStore(({thirdGameStore, walletStore, globalStore}) => {
    const [send, sO] = useState('main');
    const [amount, sA] = useState('');
    const [receive, sI] = useState('');
    const [trans, loading] = useQuery('thirdTrans');
    const {wallets} = thirdGameStore;
    const ls = [['', '请选择'], ['main', '主钱包']].concat((wallets).map(k => [k, walletStore.name(k)]));
    const needInt = useMemo(()=>{
        return +receive===18||+send===18
    },[receive,send])
    useEffect(()=>{
        if(needInt&&/\./.test(amount)){
            sA(amount.replace(/\..*/g,''))
        }
    },[needInt,amount])
    const processTrans = () => {
        if (send && receive && amount) {
            const isMain = send === 'main';
            const left = (walletStore.bls[send] || {}).balance || 0;
            if (+amount > left) {
                return tip.alert({text: '转出钱包余额不足,请充值或刷新钱包!'});
            }
            const f = a => a === 'main' ? undefined : a;
            const p = f(send) || f(receive);
            trans({
                product_id: p,
                fund_type: isMain ? 1 : 2,
                amount: parseFloat('' + amount)
            }, () => {
                walletStore.query(receive, send);
                tip.alert(<Tip.Ok>转账成功</Tip.Ok>)
            });
        }
    };
    const ref = useRef(null)
    const ch = (p, s) => {
        // if ([receive, send][s - 1] === p)
        if (p && p !== 'main') [sI, sO][s - 1]('main');
        [sO, sI][s - 1](p);
        const {current} = ref;
        if (current) {
            current.select();
            current.focus();
        }
    };
    const outAct = walletStore.bls[receive] || {};
    const sw = !outAct.loading && +outAct.balance

    return <div className={'x-sheet wallets-page'}>
        <div className={'x-st-h'}>
            <DashTitle
                title={'钱包转帐'}
                desc={'场馆钱包和钱包之间不可以互转'}
            />
            <div className={'opt'}>
                <div className={'g'}>
                    <label>主钱包：</label>
                    <span>{globalStore.available}</span>
                    <i
                        onClick={() => walletStore.query('main')}
                        className={'l' + (walletStore.loading ? ' act' : '')}
                    />
                    <label>总资产：</label>
                    <span>{walletStore.totalBalance.toFixed(2)}</span>
                </div>
                <span className={'m-tp'}>BT彩票可直接使用主钱包</span>
                <div className={'g'}>
                    <label>
                        <Select className={'select'}
                                suffix={'-转出'}
                                value={send}
                                onChange={v => ch(v, 1)}
                                items={ls.filter(([a]) => {
                                    if (!a) return 1;
                                    if (a === 'main') return 1;
                                    const w = walletStore.bls[a];
                                    if (w && +w.balance && !w.loading) return 1
                                    return 0
                                })}/>
                    </label>
                    <i
                        onClick={() => {
                            if (sw) {
                                sO(receive);
                                sI(send);
                            }
                        }}
                        className={'swap'}/>
                    <label>
                        <Select className={'select'}
                                suffix={'-转入'}
                                value={receive}
                                onChange={v => ch(v, 2)}
                                items={ls.filter(([a]) => {
                                    if (send === 'main' && a === 'main') return 0;
                                    return 1;
                                })}/>
                    </label>
                    <label>
                        <input
                            placeholder={'请输入转账金额'}
                            ref={r => {
                                if (r) ref.current = r
                            }}
                            type={'text'} value={amount}
                            onChange={change(sA, v=> v.replace(/[^\d.]/g, '')
                            .replace(/(\.\d{2})\d+/,'$1')
                        )}/>
                    </label>
                    <div
                        onClick={processTrans}
                        className={'btn' + (loading ? ' dis' : '')}
                    >{loading ? '提交中...' : '立即转账'}</div>
                </div>
            </div>
        </div>
        <div className={'x-tba'}>
            <WalletLs
                hideMain
                onChange={ch}
                onLoad={(k, b, q) => {
                    bs[k] = [b, q]
                }}
                status={k => (send === k) * 1 + (receive === k) * 2}
            />
        </div>
    </div>
});
