import React, {useEffect, useMemo, useState} from 'react';
import {Page} from "../common/page";
import {NoDate} from "../common/com";
import {widthStore} from "../../store";
import {useQuery} from "../../hooks";
import './t.scss'
import {animate} from "../../utils";

const QuickLink = ({domain = ''}) => {
    const max = 5e3;
    const min = max / 20;
    const ctx = useMemo(() => ({v: 0}), [])
    const now = Date.now()
    const [start, ss] = useState(now);
    const [end, ee] = useState(now);
    const [q, l, r, e] = useQuery('test');
    useEffect(() => {
        const ani = () => {
            if(ctx.t)return;
            const now = Date.now()
            ctx.v = max - now + start;
            ee(now)
            if (ctx.v < min) ctx.v = min
            if (r || e) {
                ee(now);
                ctx.t =1
            }
            requestAnimationFrame(ani)
        }
        ani()
    }, [r, e])
    useEffect(() => {
        const img = new Image();
        img.onerror = () => {
            ss(Date.now())
            q()
        }
       const t =setTimeout(()=>{
           img.src = domain.replace(/^(https?:)?(\/\/)?\w+/,'//api')+'/404.png?'+start;
       },1000)
        return ()=>clearTimeout(t)
    }, [domain]);
    const {v} = ctx;
    const pv = 100 * (1-v / max);
    const cls = e ? 'er' : pv <20 ? 'good' : pv < 50 ? 'normal' : ''
    const tr = `translate3d(-${pv}%,0,0)`;
    const style = {
        transform:tr,
        '-webkit-transform':tr
    }
    return <div className={'way'}>
        <label>线路地址</label>
        <div className={'bar'}>
            <div style={style} className={cls}/>
        </div>
        <a href={domain}>
            {l ? '正在连接' : r ? (end-start+ 'ms') : e ? '连接异常' : '正在连接'}
        </a>
    </div>
};

export const TestPage = widthStore(
    ({globalStore: {domains = []}}) => {
        const [q] = useQuery('init');
        useEffect(() => {
            q()
        }, [])
        return <Page cls={'test'}>
            <div className={'lk-box'}>
                <div className={'ti'}>
                    <i/>线路测速
                </div>
                <p>
                    秉承重视安全的宗旨，平台将不定期更换域名,使您能随时方便的获
                    取到平台最新最快的登录域名。
                </p>
                <div className={'ls'}>
                    {domains.length ?
                        domains.slice(0, 8).map((a, i) => <QuickLink key={i} name={'线路0' + (1 + i)}
                                                                     domain={'//' + a}/>) :
                        <NoDate cls={'b'}>暂其它无线路</NoDate>
                    }
                </div>
                <div className={'ft'}>
                    <div>访问状态说明：</div>
                    <div><i className="good"/>很好</div>
                    <div><i className="normal"/>一般</div>
                    <div><i className="bad"/>较差</div>
                </div>
            </div>
        </Page>
    })
