export const pk10caipaiweizhixuanpkguanyajun =  {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [0,1,2,3,4,5,6,7,8,9],
	    [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	    [1,2,3,4,5,6,7,8,9,10],
	    [1,2,3,4,5,6,7,8,9,10]
    ],
    titles: [
	    '冠军',
	    '亚军',
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let arr = [];
        for(let i = 0,len= balls.length;i < len;i++){
		    let str = "";
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){
			    if(balls[i][l] > 0){
				    str+=num[i][l]
			    }           
		    }
		    arr.push(str);
	    }
	    return  arr.join("|")
    },
    getNum:(balls)=>{
		let arr = balls,len = arr.length,_num=0;
        if(len){
            for(let i=0,len_i=arr[0].length;i<len_i;i++){
                if(arr[0][i]>-1){
                    for(let j =0,len_j=arr[1].length;j<len_j;j++){
                        if(arr[1][j]>-1 && i!=j){
                            _num++;
                        }
                    }
                }    
            }
        }  
        return _num;
    },
    getView: (views,balls)=>{
        let arr = [];
        for(let i = 0,len=balls.length;i < len;i++){
		    let str = "";
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){
			    if(balls[i][l] > 0){
				    str+=views[i][l];
			    }           
		    }
		    arr.push(str);
	    }
	    return  arr.join("|")
    }
}
