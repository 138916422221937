import React from 'react';
import {Link} from "react-router-dom";
import "./foot.scss"

import {B1Foot} from "./b1Foot.js";
import {B2Foot} from "./b2Foot.js";


export const PageFooter = (props)=><>
  {/* <B2Foot/> */}
  <B1Foot/>
</>;


