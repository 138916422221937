export const trendsStore = {
    data:{},
    // 辅助线
    line:true,
    // 遗漏
    miss:true,
    // 遗漏条
    missBar:false,
    //走势
    trend:true,
    //号温
    numHot:false,
    //期数
    count:30,
    // 类别
    act:0,
};
