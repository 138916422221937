import React, {useState, useEffect, useMemo} from 'react';
import './cal.scss';
import {panel} from "../tabs";
import {Link} from "react-router-dom";
import {LaunchGame} from "../../pages/common/com";
import {popPro} from "../../pages/promotion";
import {thirdGameStore} from "../../store/third";

const A = ({to, children, className, style}) => <a className={className} href={to} target={'__blank'}
                                                   style={style}>{children}</a>
const MiniBanner = ({n, dur, next}) => {
    const a = `rotateZ(${180 * (n / dur) + 45}deg)`;
    return <div className={'mini-banner'} onClick={next}>
        <div style={{
            transform: a,
            WebkitTransform: a,
            MozTransform: a
        }}/>
        <i/>
    </div>
}

export const Carousel = ({showNext, items = [], dur = 100}) => {
    const [act, setAct] = useState(0);
    const [ani, setAni] = useState(0);
    const [n, nn] = useState(0);
    const [c, sC] = useState('');
    const [pre, sP] = useState(null);
    const [nxt, sN] = useState(null);
    const ctx = useMemo(() => ({
        s: 0,
        v: 0,
        d: 0
    }), [])
    useEffect(() => {
        return () => ctx.s = 1
    }, [])
    const jump = i => {
        if (ctx.d) return;
        ctx.d = 1;
        ctx.v = 0;
        const l = items.length;
        const v = (i + l) % l;
        const t = items[v];
        if (!l || v === act || !t || ctx.s) return;
        if (i > act) {
            sN(t);
            sP('');
        } else if (i < act) {
            sP(t);
            sN('');
        }
        setAni(1);
        setTimeout(() => {
            if (!ctx.s) sC(i > act ? 'p' : 'n')
        }, 60)
        setTimeout(() => {
            if (!ctx.s) setAni(0);
        }, 1050)
        setTimeout(() => {
            if (!ctx.s) {
                sC('');
                sP('');
                sN('');
                setAct(v);
                ctx.d = 0;
            }
        }, 1060)
    }
    useEffect(() => {
        if (items.length < 1) return;
        const t = setInterval(() => {
            ctx.v++;
            nn(ctx.v);
            if (ctx.v >= dur) {
                clearInterval(t);
                jump(act + 1);
            }
        }, 60);
        return () => clearInterval(t);
    }, [act, items])
    const cur = items[act];
    const itm = ({redirect_url, pic_url}, cls) => {
        const u = redirect_url;
        if (/^game:\/\//.test(u)) {
            const [type, pid] = u.substr(7).split('_');
            const cfg = thirdGameStore.game(type,+pid);
            const {code, platform} = cfg;
            return <LaunchGame
                link={1}
                key={cls}
                cls={cls}
                style={{backgroundImage: `url(${pic_url})`}}
                platform={platform}
                product_type={pid}
                game_mode={1}
                game_code={code}
                local={1}
            />
        }
        const Cpm = /(^https?:)/i.test(redirect_url) ? A : Link;
        if (/promotion\/\d/.test(redirect_url)) return <a
            onClick={() => popPro.show(+((/promotion\/(\d+)/.exec(u) || [])[1]))}
            className={cls} key={cls}
            style={{backgroundImage: `url(${pic_url})`}}/>
        return <Cpm
            key={cls}
            className={cls}
            to={u}
            style={{backgroundImage: `url(${pic_url})`}}
        />
    }
    const l = items.length;
    return <>
        <div className={'carousel'}>
            <div className={'cs-v'}>
                <div className={'cs-sc ' + (ani ? 'ani ' : '') + c}>
                    {cur ? <>
                        {pre && itm(pre, 'pre')}
                        {cur && itm(cur, 'cur')}
                        {nxt && itm(nxt, 'nxt')}
                    </> : null}
                </div>
            </div>
            {l > 1 && showNext ? <div className={'dot'}>
                {items.map((_, i) => <s className={act === i ? 'act' : ''} onClick={() => jump(i)} key={i}/>)}
            </div> : null}
        </div>
        {panel(showNext && l > 1, <MiniBanner dur={dur} n={n} next={() => jump(act + 1)}/>)}
    </>
};
