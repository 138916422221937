import {LaunchGame} from "../common/com";
import {Page} from "../common/page";
import React, {useEffect} from 'react';
import {useQuery} from "../../hooks";
import './t.scss';

export const TcgLottoPage = () => {
    const [q, ld, re] = useQuery('thirdLotteries');
    useEffect(() => {
        q();
    }, [])
    return <Page
        cls={'lotto-tcg'}
    >
        <LaunchGame
            frameMode
            product_type={21}
            game_mode={1}
            game_code={'ALL'}
            platform={'html5-desktop,html5'}
        />
    </Page>
}
