import React, {useMemo} from 'react';
import {widthStore} from "../../store";
import {Link} from "react-router-dom";

const cc = ['SSC', '11-5', 'K3', 'PK10', 'DPC'];
const nm = ['时时彩', '11选5', '快3', 'PK0', '低频彩']
export const HMu = widthStore(({betStore: {allLottery}},{cur}) => {
    const mu = useMemo(() => {
        const mu = [];
        if (allLottery) {
            cc.forEach((a, i) => {
                const v = (allLottery[a] || []).slice();
                if (a === 'DPC') {
                    const vv = allLottery['PLW']||[];
                    if (vv.length) v.push(...vv);
                }
                if (v.length) mu.push({
                    c: 'm-' + a.toLowerCase(),
                    n: nm[i],
                    v
                })
            })
        }
        return mu;
    }, [allLottery])
    return <div className={'bet-menu'}>
       <div className={'bm0'}>
           {mu.map(({c, n, v}, i) => {
               const mm = [];
               v.forEach(({id, cate, name}, i) => {
                   const x = Math.floor(i / 4);
                   (mm[x] = mm[x] || []).push(<Link className={+id===+cur?'act':''} to={`/bet/${id}/${cate}`} key={i}>{name}</Link>);
               })
               return <div className={'bm'} key={i}>
                   <div className={'bm1'}>
                       <i className={c}/>
                       <span>{n}</span>
                   </div>
                   <div className={'bm2'}>
                       {mm.map((a, i) => <div key={i}>{a}</div>)}
                   </div>
               </div>
           })}
       </div>
    </div>
})
