export const sscdaxiaodanshuangdaxiaodanshuangzhongsandaxiaodanshuang =  {
	balls:[
	    [-1,-1,-1,-1],
        [-1,-1,-1,-1],
        [-1,-1,-1,-1]
	],
    num:[
	    [1,0,3,2],
        [1,0,3,2],
        [1,0,3,2]
    ],
    views:[
	    ['大', '小', '单', '双'],
        ['大', '小', '单', '双'],
        ['大', '小', '单', '双']
    ],
    titles: [
        '千',
        '百',
        '十'
    ],
    getLottery:(num,balls)=>{
        let str = '';
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 1,nums = [];
        for(let i=0,len=balls.length;i<len;i++){
            nums[i] = 0;
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    nums[i]++;
                }
            }
        }
        for(let k = 0,len_k = nums.length;k<len_k;k++){
            num *= nums[k];
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += views[i][j];
				}
		    }
    	}
	    return str;
    }
}
