import React, {useEffect, useMemo, useState} from 'react';
import  './i.scss';
import {PopUpWin} from "../popup/popup";
const Video = ({src})=>{
    const  ctx = useMemo(()=>({}),[])
    useEffect(()=>{
        return ()=>{
            return ()=>{
                if(ctx.v){
                    ctx.v.pause();
                }
            }
        }
    },[src])
    return <div className={'video-player'}>
        <div key={src} ref={v=>{
            if(ctx.v)ctx.v.pause();
            if(src&&v){
                const p = v.parentElement
                const vi = document.createElement('video')
                vi.src=src;
                vi.controls=true;
                vi.onpause=()=>{
                    p.className = 'video-player pu'
                }
                vi.onplay = ()=>{
                    p.className = 'video-player'
                }
                vi.onclick=()=>{
                    if (vi.paused) {
                        vi.play();
                    } else {
                        vi.pause();
                    }
                     return false
                }
                v.innerHTML='';
                v.appendChild(vi);
                ctx.v =vi;
                return vi.play();
            }
        }}/>
        <i className={'ico-play'}/>
    </div>
}


export const popVideo = {}
export  const PopVideo = ()=>{
     const [s,ss] = useState('')
    return <PopUpWin
        cls={'pop-video'}
        closeAble={false}
        maskCloseAble={true}
        onClose={()=>{
        ss('')
    }} read={({show})=>{
        popVideo.show = a=>{
            ss(a);
            show()
        }
    }}>
        <Video src={s}/>
    </PopUpWin>
}
