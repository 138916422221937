export  const p35daxiaodanshuangdaxiaodanshuanghouerdaxiaodanshuang =  {
    balls:[
        [-1,-1,-1,-1],
        [-1,-1,-1,-1]
    ],
    num:[
        [1,0,3,2],
        [1,0,3,2]
    ],
    views:[
        ['大', '小', '单', '双'],
        ['大', '小', '单', '双']
    ],
    titles: [
        '十',
        '个'
    ],
    getLottery:(num,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
            str+=i==0?'':'|'
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    str +=num[i][l];
                }
            }
        }
        return str;
    },
    getNum:(balls)=>{
        let num=1,arr = [];
        for(let i=0,len=balls.length;i<len;i++){
            arr[i] = 0;
            for(let j=0,len_j=balls[i].length;j<len_j;j++){
                if(balls[i][j]>0){
                   arr[i]++;
                }
            }
        }
        for(let k=0,len_k=arr.length;k<len_k;k++){
            num*= arr[k];
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
            str+=i==0?'':'|' 
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    str +=views[i][l];
                }
            }
        }
        return str;
    }
}
