export const pk10liangmianpanzhixuanguanyahezhi =  {
	balls:[
	    [-1,-1,-1,-1]
	],
    num:[
	    [1,0,3,2]
    ],
    views:[
	    ['大','小','单','双']
    ],
    titles: [
	    '冠亚和'
    ],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let view = ""; 
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(i == 0){
			    	if(balls[i][l] > 0){
					    view +=views[i][l]
				    }
			    }
		    }
    	}
	    return view;
    }
}
