import React, {useEffect, useState} from 'react';
import {useQuery} from "../../hooks";
import {change} from "../../components/pwd";
import {trim} from "../../utils";
import {Loading} from "../../components/loading";

export const DailyS = ({onChange, userId}) => {
    const [query, loading, data] = useQuery('userSetting');
    const [query1, loading1, data1] = useQuery('userSetting');
    const {daily_salary = 0} = data || {};
    const {daily_salary: d2 = 0} = data1 || {};
    const [v, vv] = useState(0);
    useEffect(() => {
        query1({user_id: userId});
        query();
    }, [])
    useEffect(() => {
        if (!loading && !loading1 && data && data1) {
              onChange(v)
        }
    }, [
        loading, loading1, data, data1, v
    ]);
    return <div className={'pp-d'}>
        <p>您的日工资为<b>{daily_salary}</b></p>
        <label>用户日工资设定（当前{d2}）：</label>
        <input value={v}
               placeholder={'请输入日工资'}
               onChange={change(vv, x => {
                   let v = trim(x + '');
                   if (v) {
                       v = parseFloat(v);
                       if (v > daily_salary) v = daily_salary;
                       if (v < d2) v = d2;
                   }
                   return v;
               })}/>
        <Loading act={loading || loading1}>正在加载...</Loading>
    </div>
}
