import {useState} from 'react';

export const Tabs = ({change, hoverChange,index = 0, act, cur,children}) => {
    const [s, ss] = useState(index);
    const [h, hh] = useState(index);
    const click = i => () => {
        ss(i);
        change && change(i)
    };
    const hover = i => () => {
        hh(i);
        hoverChange && hoverChange(i)
    };
    const cls = (i, c) => (act === undefined ? s === i : act === i) ? c || 'act' : '';
    const hov = (i, c) => (cur === undefined ? h === i : cur === i) ? c || ' cur' : '';
    const props = (i, c) => {
        const o = {
            key:i,
            className: cls(i, c)
        };
        if(change){
                o.onClick=click(i)
        }
        if(hoverChange){
            o.className = o.className+hov(i,c);
            o.onMouseEnter = hover(i)
        }
        return o
    };
    return children(props)
};

export const panel = (c, children) => {
    return c ? typeof children==='function' ?children():children: null
};
