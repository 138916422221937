import React, {useEffect, useState} from 'react';
import {widthStore} from "../../store";
import {Ipt} from "../common/com";
import {trim} from "../../utils";
import  './tr.scss';

export const TransCpm = widthStore(
    ({globalStore: {available}, myBankStore}, {onChange}) => {
        const [v0, vv0] = useState('')
        const [v1, vv1] = useState('')
        const [v2, vv2] = useState('')
        const [e0, ee0] = useState('')
        const [e1, ee1] = useState('')
        useEffect(() => {
            const err = e0 || e1;
            onChange(err ? [] : [parseFloat(v0), v1, v2])
        }, [e0, e1, onChange, v0, v1, v2])
        return <div className={'trans-card'}>
            <div className={'a'}>
                <b>￥</b>
                <div className={'b'}>
                    <label>主钱包</label>
                    <h1>{available}</h1>
                </div>
            </div>
            <div className={'c'}>
                <Ipt
                    cls={'money'}
                    value={v0}
                    onChange={v =>{
                        const c = v.replace(/[^0-9.]/g,'').split('.');
                        const d = c.slice(1).join('');
                        vv0(c[0]+(c.length>1?'.':'')+(d?d.substr(0,2):''))
                    }}
                    onCheck={ee0}
                    check={[
                        [a => a, '请输入转账金额']
                    ]}
                    placeholder={'请输入转账金额'}/>
                <Ipt
                    cls={'pwd'}
                    value={v1}
                    onChange={vv1}
                    onCheck={ee1}
                    type={'password'}
                    check={[
                        [a => a, '请输入资金密码'],
                        [/^.{6,16}$/, '密码格式不正确']
                    ]}
                    placeholder={'请输入资金密码'}/>
                <Ipt
                    cls={'edit'}
                    value={v2}
                    onChange={v => vv2(trim(v))}
                    placeholder={'备注'}/>
            </div>
        </div>
    });
