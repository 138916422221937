import {Select} from "../select/select";
import './i.scss'
export const CCode = ({value = '86', change}) => {
    return <Select
        className={"p-code"}
        onChange={change}
        value={value}
        items={[
            ['86', '+86', <div><b className={'f-cn'}/>+86 中国 China</div>],
            ['852', '+852', <div><b className={'f-hk'}/>+852 中国香港 Hong Kong</div>],
            ['853', '+853', <div><b className={'f-mc'}/>+853 中国澳门 Macao</div>],
            ['886', '+886', <div><b className={'f-tw'}/>+886 中国台湾 Taiwan</div>],
            ['63', '+63', <div><b className={'f-ph'}/>+63 菲律宾 Philippines</div>],
            ['65', '+65', <div><b className={'f-sg'}/>+65 新加坡 Singapore</div>]
        ]}
    />
}

export const VerifyPhone = (p,pr)=>{
    const re = {
        '86':/^1[3-9][0-9]{9}$/,
        '886':/^\d{8,11}$/,
        '852':/^(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/,
        853:/^(?:28\d{6}|6\d{7})$/,
        65:/^[689]\d{7}$/,
        '63':/0?9\d{9}/
    }
    const r = re[pr]
    if(r&&p)return r.test(p)
}