export  const _115quweixingquweixingcaizhongwei = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [3,4,5,6,7,8,9]
    ],
    views:[
	    [3,4,5,6,7,8,9]
    ],
    titles: [
	    '猜中位'
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
			    if(balls[i][l] > 0){
				    str += str?(' '+num[i][l]):num[i][l];
				}   
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num=0;
        for(let i=0,len=balls[0].length;i<len;i++){
            if(balls[0][i]>0){
                num++;
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
                if(balls[i][l] > 0){
                    str += str?(' '+'0'+views[i][l]):('0'+views[i][l]);
                }   
            }
        }
        return str;
    }
}
