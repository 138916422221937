import React,{useEffect} from 'react';
import {widthStore} from "../../store";
import {Chase} from "./Chase";
import {Timer} from "../../components/time";
import {useBetSubmit} from "../../hooks";
import {Loading} from "../../components/loading";

export const Cart = widthStore(
    ({betStore, betListStore, globalStore}, {gameId, endTime}) => {
        const {
            curGameId,
            betList = [], betMap = {},
            traceType,
            trRateLis, trSameMultipleLis, trMultipleLis
        } = betListStore;
        useEffect(()=>{
           if(curGameId!==gameId){
               betListStore.curGameId = gameId;
               betListStore.betList = [];
               betListStore.betMap = {};
               betListStore.trRateLis=[];
               betListStore.trSameMultipleLis=[];
               betListStore.trMultipleLis=[];
           }

        },[gameId,curGameId]);
        const submit = useBetSubmit(gameId);
        const {_submitting} = betStore;
        const {gKey: g,} = betStore;
        const {balance} = globalStore;
        const actTrace = [trRateLis, trSameMultipleLis, trMultipleLis][traceType];
        const actLength = actTrace.length;
        const ls = betList.map(c => {
            const d = betMap[c];
            if (d) {
                const {title, view, bets, amount, mulriple, gKey, prizeGroup} = d;
                const isAct = g === gKey;
                return <tr key={gKey}
                           onClick={() => betStore.recover(d)}
                           className={(isAct ? "act" : "")}>
                    <td className={'t0'}>{title}</td>
                    <td title={view} className='limit t1'>{view}</td>
                    <td className={'t2'}>{bets}</td>
                    <td className={'t3'}>{actLength?1:mulriple}</td>
                    <td className={'mon t4'}>{+(actLength?amount/mulriple:amount).toFixed(2)}</td>
                    <td className={'t6'}>{prizeGroup}</td>
                    <td className={'t5'}>
                        <div
                            className={'del'}
                            onClick={e => {
                                e.preventDefault();
                                betListStore.remove(d);
                            }}
                        >X
                        </div>
                    </td>
                </tr>
            }
            return ''
        });
        let totalA = 0, totalB = 0, totalC = 0;
        if (actTrace.length) {
            actTrace.forEach(item => {
                if (item.sel) {
                    totalA++;
                    totalC += item.price * item.multiple
                }
            });
            totalB = totalA * ls.length;
        } else {
            betList.forEach(c => {
                const {bets, amount} = betMap[c];
                totalB = bets;
                totalC += amount;
            })
        }
        const available = !_submitting && totalB;
        return <div className={'ca-bx'}>
            <Loading fixed act={_submitting}>
                投注提交中...
            </Loading>
            <div className={"cart"}>
                <div className={'l'}>
                    <div className={'tb-h'}>
                        <table>
                            <thead>
                            <tr>
                                <th className={'t0'}>玩法</th>
                                <th className={'t1'}>号码</th>
                                <th className={'t2'}>注</th>
                                <th className={'t3'}>倍</th>
                                <th className={'t4'}>金额</th>
                                <th className={'t6'}>奖金组</th>
                                <th className={'t5'}>
                                    <div onClick={() => betListStore.clearList()}><i/></div>
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className={'limit-r'}>
                        <table>
                            <tbody>
                            {ls}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'r'}>
                    <Chase/>
                </div>
            </div>
            <div className={'total'}>
                <div className={'ff-l'}>
                    总计：<span>{totalB || '0'}</span>注, 共 <span>{totalC ? totalC.toFixed(2) : '0.00'}</span>元
                    可用余额 <span>{(+balance).toFixed(2)}</span> 元
                </div>
                <div className={'ff-r'}>
                    <div className={'lft'}>
                        <span>剩余时间</span>
                        <Timer simple time={endTime}/>
                    </div>
                    <div className={'sub sf'}>
                        <div className={'b-addCart  ct2 ' + (available ? ' act' : '')} onClick={() => available && submit()}>
                            <span>
                                <i/>确认投注
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    });
