import React, {useState} from 'react';
import {PopUpWin} from "../../components/popup/popup";
import {widthStore} from "../../store";
import './a.scss';
import {useQuery} from "../../hooks";

export const popAvatar = {};
const A = ({i, act, onClick}) => {
    return <div onClick={() => onClick(i)} className={'ava-' + i + (act ? ' act' : '')}/>
}
export const PopAva = widthStore(({
                                                     globalStore: {avatar}
                                                 }) => {
    const [set, ld] = useQuery('setUserInfo')
    const [v, vv] = useState(avatar);
    const o = i => <A onClick={vv} i={i} act={v === i}/>
    return <PopUpWin
        maskCloseAble
        cls={'ava'}
        onShow={()=>vv(avatar)}
        read={o => Object.assign(popAvatar, o)}>
        <div className={'ava-ls'}>
            <div className={'t'}>更换头像</div>
            <div className={'ls'}>
                {o(0)}
                {o(1)}
                {o(2)}
                {o(3)}
                {o(4)}
                {o(5)}
                {o(6)}
                {o(7)}
            </div>
            <div className={'btn'} onClick={ld ? null : () => set({avatar:v})}>{ld ? '提交中' : '确定'}</div>
        </div>
    </PopUpWin>
})
