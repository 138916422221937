import React, {useEffect, useState} from 'react';
import {panel} from "../../components/tabs";
import './p.scss';
import {UserRate} from "../account/rate";
import {DailyS} from "../account/day";
import {fmtErr, tip, val} from "../../utils";
import {Dividend} from "./div";
import {TransCpm} from "./tran";
import {query, seqQuery} from "../../api";
import {Tip} from "../common/com";
import {Loading} from "../../components/loading";

export const PupSetting = ({
                               onStatusChange,
                               user, type,
                               close, onDone
                           }) => {
    const [a, aa] = useState(false);
    const [c, cc] = useState({});
    const [d, dd] = useState({});
    const [e, ee] = useState({});
    const [f, ff] = useState({});
    const [er, eer] = useState({});
    const [ld, sLd] = useState('');
    useEffect(() => {
        sLd('');
        const t = setTimeout(() => aa(!!user), 200);
        return () => clearTimeout(t);
    }, [user])
    if (user) {
        const {username,id,user_id:uid} = user;
        const user_id = uid||id;
        const qu = (o, done) => {
            if (type >= 3) {
                return seqQuery({
                    process({failed, finished, total, finish, result}) {
                        sLd(`正在提交(${finished}/${total})`)
                        if (failed || finish) sLd('');
                        if (failed) tip.alert(<Tip.Err>{fmtErr(result)}</Tip.Err>)
                    }
                })(
                    ['addBonus', o.bonus_rate.map(d => ({...d, user_id}))],
                    ['addContracts', {user_id}]
                )
            }
            const api = ['transMoney', 'setPrizeGroup'][type]
            sLd('1');
            query(api, o, (d) => {
                sLd('');
                done(d);
            }, (er) => {
                sLd('');
                tip.alert(<Tip.Err>{fmtErr(er)}</Tip.Err>)
            });
        }
        const btn = type>=3?<div className={'btn'} onClick={close}>确定</div> :type > 2 ? null : <div className={'btn'} onClick={() => {
            if (!ld) {
                const o = {user_id};
                switch (type) {
                    case  1:
                        Object.assign(o, c)
                        break;
                    case  2:
                        o.daily_salary = val(d);
                        break;
                    case  3:
                    case  4:
                        if (er) return;
                        Object.assign(o, e);
                        break;
                    default:
                        delete o.user_id;
                        o.username = username;
                        if (f) {
                            const [amount, fund_password, remark] = f;
                            Object.assign(o, {amount, fund_password, remark})
                        } else return;
                }
                qu(o, () => {
                    if(o.reload)o.reload();
                    if(onDone)onDone(type)
                })
            }
        }}>{ld ? '提交中' : type ? '设置' : '立即转账'}</div>

        return <div className={'pp-s' + (a ? ' act' : '')} onClick={() => {
            aa(false);
            setTimeout(close, 300)
        }}>
            <div className={'pp-p ss'} onClick={e => e.stopPropagation()}>
                <div className={'pp-t'}>
                    <i className={'i-usr'}/>{type === 5 ? '' : '修改'}{username}的{(['转账', '返点', '日工资'][type] || '分红')}
                    <div className={'bn'} onClick={close}>{'<'}返回</div>
                </div>
                <div className={'col-line'}/>
                <div className={'pp-c x-ss'}>
                    {panel(type === 0, <>
                        <TransCpm onChange={ff}/>
                    </>)}
                    {panel(type === 1, <UserRate large={1} userId={user_id} onChange={cc}/>)}
                    {panel(type === 2, <DailyS onChange={dd} userId={user_id}/>)}
                    {panel(type >= 3, <Dividend
                        id={id}
                        onStatusChange={onStatusChange}
                        readOnly={type === 5}
                        onChange={({data, er}) => {
                            ee(data);
                            eer(er);
                        }}
                        userId={user_id}/>)}
                    {panel(type >= 3, <Loading act={ld}>{ld}</Loading>)}
                </div>
                <div className={'pp-f'}>
                    {btn}
                </div>
            </div>
        </div>
    }
    return null;
}
