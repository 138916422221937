import React, {useState, useEffect, useMemo} from 'react';
import {widthStore} from "../../store";
import {Page} from "../common/page";
import {Banner} from "../home/Banner";
import {BubbleBtn, GameCate, LaunchGame, NoDate, X} from "../common/com";
import './slot.scss'
import {
    GAME_SUBTYPE, ROTATE_RNG
} from "../../api/enum";
import {useQuery} from "../../hooks";
import {fakeNumber} from "../../utils";
import {Loading} from "../../components/loading";
import {stores} from '../../store'

const GameList = ({k, filter = {}}) => {
    const [page, setPage] = useState(1);
    const [q, loading, allGames, err] = useQuery('thirdGames');
    const [hasMore, sH] = useState(false);
    useEffect(() => {
        setPage(1);
    }, [filter, k]);

    useEffect(() => {
        q({
            product_type: 'ALL',
            game_type: 'RNG',
        })
    }, []);

    const res = useMemo(() => {
        const {gameSubType: a, gameName: b,} = filter;
        const o = {};
        return (allGames || []).filter(({gameSubType, gameName, product_id, tcgGameCode}) => {
            if (o[tcgGameCode]) return false;
            o[tcgGameCode] = 1;
            const g = (k === 'ALL' || k === +product_id) && (!b || gameName.indexOf(b) !== -1);
            if (!g) return false;
            if (a === 'ROTATE') {
                const r = k === 'ALL' ?
                    Object.keys(ROTATE_RNG).reduce((a, b) => a.concat(ROTATE_RNG[b]), [])
                    : ROTATE_RNG[k];
                return r && r.find(e => e.test(gameName))
            } else return (!a || a === gameSubType)
        }).slice(0, 32 * page)
    }, [allGames, filter, k, page]);

    useEffect(() => {
        sH(!(res.length < 32 * page || err));
    }, [res, err, page]);
    return <div className={'ls'}>
        {!res.length ? <NoDate>暂无符合条件的游戏</NoDate> : res.map(
            ({
                 gameName,
                 product_id,
                 trialSupport,
                 tcgGameCode,
                 productCode,
                 platform
             }) => {
                const isLocal = !/PT|PG|PNG|HB|TTG|MG|CQ9/ig.test(productCode);
                const img=`${productCode}/${tcgGameCode}.png`
                const bmg=isLocal?`url("/icons/${img}")`
                    :`url("https://images.b51613.com:42666/TCG_GAME_ICONS/${img}")`;
                const [a, b] = gameName.split('_');
                return <div className={'s-card'} key={tcgGameCode}>
                    <i style={{backgroundImage: bmg}}/>
                    <label>{b || a}</label>
                    <div className={'msk'}>
                        <LaunchGame
                            product_type={product_id}
                            game_mode={1}
                            game_code={tcgGameCode}
                            platform={platform}
                        >
                            <BubbleBtn>
                                <span>进入游戏</span>
                            </BubbleBtn>
                        </LaunchGame>
                        {trialSupport ?
                            <LaunchGame
                                product_type={product_id}
                                game_mode={0}
                                game_code={tcgGameCode}
                                platform={platform}
                            >
                                <BubbleBtn>
                                    <span>试玩游戏</span>
                                </BubbleBtn>
                            </LaunchGame> : null}
                    </div>
                </div>
            })}
        <div className={'ld'}>
            {(loading || hasMore) ? <div
                onClick={() => {
                    if (!loading) setPage(page + 1);
                }}
                className={loading ? 'act' : ''}>
                ...
            </div> : null}
        </div>
        <Loading act={loading && page === 1}>正在加載...</Loading>
    </div>
};

const SlotH = ({gameSubType = '', gameName = '', ff}) => {
    const c = a => a * 1.1;
    const [s, sS] = useState(gameName);
    const [t, sT] = useState(gameSubType);
    const [n, sN] = useState(fakeNumber(c));
    useEffect(() => {
        const t = setTimeout(() => {
            sN(fakeNumber(c))
        }, 5e3);
        return () => clearTimeout(t);
    }, [n]);
    const qu = (a, b) => {
        sS(a);
        sT(b);
        ff({gameSubType: b, gameName: a});
    };
    return <div className={'sh'}>
        <div className={'ft'}>
            <div className={'fk'}>{n}</div>
            <div className={'search'}>
                <input type={'text'}
                       value={s}
                       onChange={(e) => {
                           sS(e.target.value.replace(/^\s+|\s+$/g, ''));
                       }}/>
                {s ? <span onClick={() => {
                    qu('', t);
                }}>X</span> : null}
                <i onClick={() => qu(s, t)}/>
            </div>
            <div className={'ff'}>
                <div className={'f0'}>
                    <label>游戏类型:</label>
                    {GAME_SUBTYPE.map(([k, n]) =>
                        <div
                            key={k || 'all'}
                            className={(!gameSubType && !k) || gameSubType === k ? 'act' : ''}
                            onClick={() => {
                                qu(s, k);
                            }}
                        >{n}</div>)}
                </div>
            </div>
        </div>
    </div>
};

export const SlotPage = widthStore(({thirdGameStore}, {match: {params: {target}}}) => {
    const {availableGames = {}} = thirdGameStore;
    const keys = availableGames.rng || [];
    const [act, sA] = useState(+target || 'ALL');
    const [filter, sF] = useState({});
    useEffect(() => {
        if (target && act !== +target) {
            sA(+target)
        }
    }, [target])
    return <Page cls={'slot'}>
        <X>
            <Banner small id={36}/>
            <GameCate
                keys={keys}
                act={act}
                click={sA}
            />
            <SlotH {...filter} ff={o => {
                Object.keys(o).forEach(k => {
                    if (!o[k]) delete o[k]
                });
                sF(o);
            }}/>
            <GameList k={act} filter={filter}/>
        </X>
    </Page>
});
