import React from 'react';
import './t.scss'
import {NoDate} from "../../pages/common/com";
/**
 * 表格配置
 * @typedef rowCfg
 * @property {function(Object,number)} [cell] cell(rowData,index)  渲染td内部内容
 * @property {string} [key]   数据的键值
 * @property {string} [name]  表格名称
 * @property {Object} [event]  cell 事件
 * @property {function} [rowspan]  基于当前行数计算 rowspan 值  <td rowspan={rowspan(n)}> n - 当前行数
 * @property {string} [cls]   th样式
 * @property {string} [cellClass]   tbody 里的 td 样式
 */

/**
 * 表格组件
 * @param props
 * @param {[Object]} props.data 数据源
 * @param {[rowCfg]} props.row  列配置
 * @return {*}
 * @constructor
 */
export const Table = (props) => {
    const {data = [], row, tbodyRow, rowCfg, noData = []} = props;
    let rs = row.map(() => 0);
    const buildEvent = (cfg, da, k, n, i) => {
        if (!cfg) return {};
        const o = {};
        Object.keys(cfg).forEach(k => {
            const f = cfg[k];
            if (typeof f === 'function') {
                o['on' + k[0].toUpperCase() + (k.substr(1))] = () => f({
                    data: da,
                    key: k,
                    row: n,
                    cell: i
                })
            }
        });
        return o;
    };
    const renderRow = (da, n) => row.map((cfg, i) => {
        if (!cfg) return null;
        const {key, cell, cellClass = '', event, rowspan} = cfg;
        let rsp;
        if (rowspan) {
            const r = rowspan(n);
            if (r) rsp = r;
        }
        if (rs[i]) {
            rs[i]--;
            return null
        }
        if (rsp) rs[i] += rsp - 1;
        let v = da[key || i];
        if (cell) v = cell(da, n, i);
        if(v===''||v===null||v===undefined)v=empty;
        return <td
            rowSpan={rsp}
            key={'cell' + n + i + key}
            className={typeof cellClass === 'function' ? cellClass({data: da, key, row: n, cell: i}) : cellClass}
            {...buildEvent(event, da, key, n, i)}
        >{v}</td>
    });
    const o = {...props};
    const {empty=''}=o;
    delete o.data;
    delete o.rowCfg;
    delete o.empty;
    delete o.row;
    delete o.noData;
    delete o.rowClick;
    const rsD = (data || []).map ? (data || []).map((da, i) => {
        const o = rowCfg ? rowCfg(da, i) : {};
        const a = <tr key={'tr' + i} {...o}>{renderRow(da, i)}</tr>;
        if (tbodyRow) {
            return <tbody key={'tr' + i}>{a}</tbody>
        }
        return a
    }) : [];
    // return null;
    return <>
        <table {...o}>
            <thead>
            <tr>
                {
                    row.map((cfg, i) => {
                        if (!cfg) return null;
                        const {name, cls, key, event} = cfg;
                        return <th
                            className={typeof cls === 'function' ? cls({key, cell: i}) : cls}
                            key={'th' + i}
                            {...buildEvent(event, null, key, -1, i)}
                        >{name}</th>
                    })
                }
            </tr>
            </thead>
            {
                data.length !== 0 ? tbodyRow ? rsD
                    : <tbody>{rsD}</tbody>
                    : null
            }

        </table>
        {
            data.length === 0 ? <div style={{lineHeight: '60px', textAlign: 'center'}}>
                <NoDate cls={noData[1]} children={noData[0]}/></div> : ''
        }
    </>
};
