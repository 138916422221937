import React from 'react';
import superagent from 'superagent';
import {logout, stores} from '../store';
import {apis} from './config';
import {encrypt, decrypt, encStoreKey, tip, fmtErr, uniqArr, getApi} from '../utils';
import {Tip} from "../pages/common/com";
// todo disable fake;
export const allowFake = 0;
const enk = 'enc_k';
const pk = 'sk_';
const cp = a => JSON.parse(JSON.stringify(a));

/**
 * API 请求地址
 * @const {string}
 */
const defaultApi =  localStorage.api || getApi();
const types = ['get', 'post', 'put', 'delete'];
const {globalStore} = stores;
/**
 * 是否标记强制更新对应API缓存
 * @param api
 */
const needCleanCache = (api) => {
    const o = window.localStorage.getItem('_cc');
    try {
        const ks = JSON.parse(decrypt(o));
        const k = ks[api];
        delete ks[api];
        window.localStorage.setItem('_cc', encrypt(JSON.stringify(ks)));
        return k
    } catch (e) {

    }
};
export const nextCleanCache = (...api) => {
    const o = window.localStorage.getItem('_cc');
    let ks = {};
    try {
        ks = JSON.parse(decrypt(o)) || ks;
    } catch (e) {
    }
    api.forEach(a => ks[a] = 1);
    window.localStorage.setItem('_cc', encrypt(JSON.stringify(ks)))
};

/**
 * Object 转链接格式
 * @param {(Object|string)} data
 * @return {string}
 */
function obj2Params(data) {
    return typeof data === 'object' ?
        Object.keys(data).map(
            k => k + '=' + encodeURI(data[k])).join('&'
        ) : data;
}

// Subject 池
const QSubject = {};
const groupMap = {};

/**
 * @param  {string} api api配置名称{@param moreConfig
@link apiConfig}
 * @return {Function}
 */
export function _query(api, moreConfig = {}) {
    const cfg = apis[api] || [];
    const [url, typeN = 0, config = {}] = cfg;
    const {
        fakeData,
        cacheTime,
        before,
        after,
        group,
        done,
        baseURL = defaultApi,
        fail,
        ctx = {},
        cacheKey,
        storage = sessionStorage,
        merge,
        retry = 0,
        timeout = 6e4
    } = {...config, ...moreConfig};
    const type = types[typeN];
    const {token} = globalStore;
    const header = {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.bojin.v1+json',
    };
    if (token) header.Authorization = 'Bearer ' + token;

    function setCache(key, data) {
        const enc = encStoreKey(key);
        if (!data) {
            return storage.removeItem(enc);
        }
        const o = encrypt(JSON.stringify({
            v: data,
            t: Date.now()
        }));
        const ls = uniqArr((localStorage.getItem(enk) || '').split(','));
        localStorage.setItem(enk, uniqArr(ls.concat((storage === sessionStorage ? 0 : 1) + enc)).join(','));
        storage.setItem(enc, o);
    }

    function getCache(key, cache, param) {
        let cacheTime = cache;
        if (typeof cache === 'function') {
            cacheTime = cache(param)
        }
        if (needCleanCache(api)) return;
        try {
            let o = storage.getItem(encStoreKey(key));
            const r = JSON.parse(decrypt(o));
            const {v, t} = r;
            if (cacheTime === -1 || (cacheTime > 0 && cacheTime + t > Date.now())) {
                return v
            }
        } catch (_) {
        }
    }

    /**
     * 生成订阅Subject 了些的请求
     * @param param
     * @param [success]
     * @param [error]
     * @return {Object}
     */
    return (param, success, error) => {
        const override = {};
        const content = {...ctx, override};
        /**
         * 参数转换  返回fetch请求链接 fetch请求配置 before函数处理后参数
         * @param  data 参数
         * @return {[string,string,(Object|string)]}
         */
        const apiCfg = (data) => {
            const origin = data && {...data};
            if (before) {
                const dd = before.call(content, data);
                if (dd !== undefined) data = dd;
            }
            if (data) {
                let mar = Object.prototype.toString.call(data).split(' ')[1].indexOf('Object');
                if (mar !== -1) {
                    Object.keys(data).forEach((v) => {
                        if (data[v] === '' || data[v] === undefined || data[v] === null) {
                            delete data[v]
                        }
                    })
                }
            }
            const c = {
                method: type,
                headers: header,
            };
            let uu = url;
            if (typeof url === 'function') {
                uu = url(origin, data);
            }
            let u = uu;
            if (u && typeN === 0 && data) {
                const has = u.indexOf('?') !== -1;
                u += (has ? '' : '?') + obj2Params(data);
            } else {
                if (data !== undefined) {
                    try {
                        c.body = JSON.stringify(data)
                    } catch (e) {
                        debugger;
                    }
                }
            }
            return [u, c, data, origin];
        };
        const [u, c, p, ori] = apiCfg(param);
        let saveKey = pk + api + (ori ? JSON.stringify(ori) : '');
        if (cacheKey) {
            saveKey = pk + cacheKey(api, ori)
        }
        const groupKey = typeof group === 'function' ? group(ori) : group;
        if (groupKey) {
            const gm = groupMap[groupKey] = groupMap[groupKey] || {};
            gm[saveKey] = ori;
        }
        let obs = QSubject[saveKey];
        if (obs) {
            return obs
        }
        obs = QSubject[saveKey] = (function () {
            const o = {
                done: [],
                fail: [],
            };
            /**
             *  @typedef Subscription
             */
            return {
                unsubscribe: () => {
                    o.stop = 1;
                    o.done.length = o.fail.length = 0;
                    delete o.e;
                    delete o.v;
                },
                subscribe: c => {
                    if (o.stop) return obs;
                    if (typeof c === 'function') {
                        if (o.hasOwnProperty('v')) c(o.v)
                        else o.done.push(c);
                    } else {
                        if (typeof c === 'object') {
                            const {next, error} = c;
                            if (next) {
                                if (o.hasOwnProperty('v')) next(o.v)
                                else o.done.push(next);
                            }
                            if (error) {
                                if (o.hasOwnProperty('e')) error(o.e)
                                else o.fail.push(error);
                            }
                        }
                    }
                    return obs
                },
                error(v) {
                    if (o.stop) return;
                    o.e = v;
                    o.fail.forEach((f) => {
                        f(o.e)
                    })
                    o.fail.length = 0;
                },
                next(v) {
                    if (o.stop) return;
                    o.v = v;
                    o.done.forEach((f) => {
                        f(o.v)
                    });
                    o.done.length = 0;
                }
            }
        }());
        const old = getCache(saveKey, cacheTime, param) || override.fake;
        const obsDone = a => {
            delete QSubject[saveKey];
            let stop = 0;
            if (success) {
                stop = success(a, param);
            }
            if (!stop && done) {
                const nextFn = done.call(content, a, param, (api, param, cacheKey) => {
                    let sk = saveKey;
                    if (api) {
                        sk = pk + api + (ori ? JSON.stringify(ori) : '');
                        if (cacheKey) {
                            sk = pk + cacheKey(api, ori)
                        }
                    }
                    setCache(sk, undefined);
                });
                if (Array.isArray(nextFn)) {
                    nextFn.forEach((f) => {
                        const [fn, params, su, er] = f;
                        setTimeout(() => {
                            _query(fn)(params, su, er)
                        }, 0);
                    })
                }
            }
            obs.next(a);
        };
        const requestUrl = 'https://'
            +(override.baseURL || baseURL).replace(/^https?:\/\//,'')
            + '/' + u;
        const obsErr = er => {
            if (fakeData && allowFake) {
                try {
                    return obsDone(fakeData(param));
                } catch (e) {
                }
            }
            try {
                console.error(er);
            } catch (e) {

            }
            delete QSubject[saveKey];
            const code = er && (er.status_code || er.statusCode || er.status);
            if (code === 401) {
                logout();
                return tip.popLogin(1)
            }
            const {data: {code: c1} = {}} = er || {};
            if ((c1 === -1 || c1 === -1||/重新登陆/.test(fmtErr(er))) && !tip.popStatus) {
                tip.alert({
                    text: <Tip.Err>{fmtErr(er)}</Tip.Err>,
                    onClose: () => {
                        logout();
                        return tip.popLogin(1)
                    }
                });
                return;
            }
            const body = er && ((er.response && er.response.body) || er.message);
            if (fail || error) {
                er = (fail && fail(body || er, param)) || er;
                if (error) error(body || er, param);
            } else {
                if (!/Request has been terminated/i.test(body)) {
                    const et = fmtErr(er);
                    if (et) tip.alert(<Tip.Err>{et}</Tip.Err>);
                }
            }
            obs.error(er);
        };
        if (!merge && old) {
            obsDone(old);
        } else {
            let cli = superagent[c.method](requestUrl)
                .send(c.body)
                .set(c.headers);
            if (timeout) cli = cli.timeout({
                response: timeout, // 15s响应时间
                deadline: 6e4,  // 60加载时间
            });
            if (retry) cli = cli.retry(retry);
            cli.on('error', err => {
                obsErr(err)
            }).then(({body: res, statusCode, status}) => {
                if (statusCode === 401 || status === 401) {
                    logout();
                    return tip.popLogin(1)
                }
                /**
                 * @name configAfter
                 */
                if (res) {
                    const {self, data, isSuccess, sum} = res;
                    if (isSuccess && data) {
                        if (sum) {
                            data.sum = res.sum
                        }
                        if (self) {
                            self.isSelf = 1;
                            data.self = res.self;
                        }
                    }
                }
                if (after) res = after.call(content, res, ori, p, (filter = {}) => {
                    if (groupKey) {
                        const gm = groupMap[groupKey];
                        if (gm) {
                            return Object.keys(gm).filter(k => {
                                const pm = gm[k];
                                const fs = Object.keys(filter);
                                for (let i = 0, l = fs.length; i < l; i++) {
                                    const kk = fs[i];
                                    if (pm[kk] !== filter[kk]) return false
                                }
                                return true
                            }).map(k => getCache(k, -1)).filter(a => a)
                        }
                    }
                    return []
                }) || res;
                if (res&&res.isSuccess) {
                    if (cacheTime) {
                        const o = cp(res.data);
                        if (merge && old) {
                            merge(o, cp(old))
                        }
                        setCache(saveKey, o);
                    }
                    if (fakeData && allowFake) {
                        try {
                            if (res.data.data.length === 0)
                                return obsDone(fakeData(param));
                        } catch (e) {
                        }
                    }
                    obsDone(res.data);
                } else obsErr(res);
            }).catch(e => {
            });
        }
        return obs;
    }
}

/**
 * @param  {string} api api配置名称{@link apiConfig}
 * @param [param] 参数
 * @param [success] done
 * @param [error] error
 * @return {Subscription}
 */
export function query(api, param, success, error) {
    return _query(api)(param, success, error)
}

/***
 *  批处理请求
 * @param config
 */
export const seqQuery = (config) => {
    const {process, done, fail, skip = 0} = config
    return (...qs) => {
        const c = [];
        qs.forEach(d => {
            const [api, params = [undefined]] = d;
            [].concat(params).forEach(p => c.push(
                {api, param: p}
            ))
        })
        const status = {
            total: c.length,
            finished: 0,
            failed: 0,
            results: [],
            result: {},
            get finish() {
                return this.finished === this.total
            }
        }
        const po = (i) => {
            if (i) status.finished++;
            if (process) process(status)
        }
        const next = (ps = []) => {
            const cf = ps.shift();
            if (!cf) return;
            if (!status.finished) {
                po(0);
            }
            const {api, param} = cf;
            query(api, typeof param === "function" ? param(status.result) : param, (da) => {
                    status.results.push(status.result = da);
                    po(1);
                    if (ps.length) {
                        next(ps);
                    } else {
                        done && done(da)
                    }
                },
                (err) => {
                    status.result = err;
                    status.failed++;
                    po(1);
                    if (skip) {
                        next(ps);
                    } else {
                        fail && fail(err);
                    }
                })
        }
        next(c);
    }
}


stores._query = query;
