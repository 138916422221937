import {LAUNCH_CONFIG} from "../api/enum";
const sortCfg = {

    live:[22]
};

const ks = ['lottery', 'rng', 'live', 'fish', 'pvp', 'sport'];

const generateGameCgh =(t)=>(cfg={})=>{
    const {id, product, status} = cfg;
    if(status){
        ks.forEach(k=>{
            if(cfg[k]){
                const ext = (LAUNCH_CONFIG[k]||{})[id]||[];
                (t[k]=t[k]||[]).push({
                    code:ext[0]||(k.toUpperCase()+0+id),
                    id:id,
                    name:ext[2]||product,
                    platform:ext[3]|| 'html5,html5-desktop',
                    cls:ext[1]||(k+'-'+id)
                })
            }
        })
    }
}



export const thirdGameStore = {
    game(type,id){
        return (this[type]||[]).find(a=>a.id===id)
    },
    cate: {},
    //[]
    lottery:[],
    rng:[],
    live:[],
    fish:[],
    pvp:[],
    sport:[],
    productType: {},
    initProducts(v) {
        v = v || [];
        if(!Array.isArray(v)){
            v = Object.values(v)
        }
        const o = {};
        const vv = {};
        const p = {};
        const w = [];
        const ks = ['lottery', 'rng', 'live', 'fish', 'pvp', 'sport']
        const gm = {
            lottery:[],
            rng:[],
            live:[],
            fish:[],
            pvp:[],
            sport:[]
        }
        v.forEach(generateGameCgh(gm))
        v.forEach(x => {
            const {id, product, status, product_type} = x;
            if (status) {
                p[id] = product;
                vv[id] = product_type;
                w.push(id);
                ks.forEach(k => {
                    if (x[k]) {
                        (o[k] = o[k] || []).push(id)
                    }
                })

            }
        });
        Object.keys(sortCfg).forEach(k => {
            const s = o[k];
            const d = sortCfg[k];
            if (s && s.length > 1) {
                s.sort(function (a, b) {
                    const x = d.indexOf(a)
                    const y = d.indexOf(b)
                    if (x !== y) return x > y ? -1 : 1
                    return 0;
                })
            }
        })
        this.productType = p;
        this.availableGames = o;
        this.wallets = w;
        this.productValue = vv;
        Object.assign(this,gm);
    },
    wallets: [],
    availableGames: {},
    getName(cate, code) {
        const gs = this.cate[cate];
        if (!gs) {
            this.getStore().query('thirdGames', {
                product_type: 'ALL',
                game_type: cate
            })
        } else {
            const g = gs.find(({tcgGameCode}) => tcgGameCode === code);
            if (g) return g.gameName || code;
        }
        return code;
    }
}
