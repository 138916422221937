export const k3k3k3hezhi = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	   ['03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18']
    ],
    views:[
	   ['03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18']
    ],
    titles: [
	  '和值'
    ],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let view = ""; 
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(i == 0){
			    	if(balls[i][l] > 0){
					    view += view?("|"+views[i][l]):views[i][l];
				    }
			    }
		    }
    	}
	    return view;
    }
}
