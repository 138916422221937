import React, {useEffect, useState} from 'react';
import {playMusic} from "../../utils";

export const ViewItem = ({click, view, ball, x, y}) => {
    const [small, setSm] = useState(0);
    useEffect(()=>{
        if(small){
            const t = setTimeout(()=>setSm(0),600);
            return ()=>clearTimeout(t);
        }
    },[small])
    let foo = (isNaN(view) && (view + '').length > 2) || (view + '').length > 2;
    return <div
        className={`ball vi-${view}${foo ? ' large' : ''}${ball === 1 ? ' checked' : ''}${small ? ' sm' : ''}`}
        onClick={() => {
            playMusic()
            click(x, y)
        }}
        onMouseDown={() => setSm(1)}
    > <div>
        <i/><span>{view}</span>
    </div></div>
};
