import {widthStore} from "../../store";
import React, {useEffect, useState} from "react";
import './i.scss'

export const PDFDwn = widthStore(({
                                      globalStore: {
                                          token, is_first_login
                                      }
                                  }) => {
    const [s, ss] = useState(0)
    useEffect(() => {
        if (token && !localStorage.pdd) {
            ss(1)
        }
    }, [
        token, is_first_login
    ])
    return <div className={'pdd-msk' + (s ? ' so' : '')} onClick={e=>e.stopPropagation()}>
        <div>
            <p>尊贵的用户您好，请收下这份新人锦囊。当您遇到网站无法访问的时候，可以参考该文档操作。</p>
            <div className={'fi'}>
                <i/>
                <span>BT网络疑难问题自助指南</span>
            </div>
            <i className={'sv'}/>
            <div className={'bns'}>
                <a
                    download={'BT网络疑难问题自助指南.pdf'}
                    href={'/BT网络疑难问题自助指南.pdf'}
                    onClick={() => {
                        localStorage.pdd=1
                        ss(0)
                    }}
                >收下锦囊</a>
                <div onClick={() => {
                    ss(0)
                    localStorage.pdd=1
                }}>我已知晓，不用了
                </div>
            </div>
        </div>
    </div>
})