import * as allGames from "./games";
import {useParams} from "react-router-dom"

/**
 * 填单类型去重复
 * @param str {String} 需要去重复的字符串
 * @param ft {function(String)} 过滤器
 * @param u {number} 最小单位
 * @return {string[]}
 */
function uniq(str = '', ft = a => a, u = 1) {
    if (!str.split) return str || [];
    const arr = u === 1 ? str.split(/[^0-9]+/).map(ft)
        : str.split(/[^0-9 ]+/).map(ft);
    const o = {};
    arr.forEach(c => o[c] = 1);
    const r = Object.keys(o)
        .filter(c => (u === 1 ? /^\d+$/gi : /[ 0-9]+$/gi).test(c))
        .sort((a, b) => a.replace(/ /g, '') - b.replace(/ /g, ''));
    return r || [];
}

/**
 * 数字字符串 去豹子等处理
 * @param n {Number} 字符串长度过滤
 * @param [u] {Number} 其他要求 无-至少一个不同 u-个不同
 * @param [f] {function} 附加过滤器
 * @param [j] {string} 组合分隔符
 * @return {Function}
 */
const mix = (n, u, f = () => 1, j = '') =>
    /**
     * @function 过滤器
     * @param c
     * @return {*}
     */
        c => {
        c = c.replace(/^ +| +$/, '')
            .split((n > 1 && / /.test(c)) ? ' ' : '')
            .filter(a => a)
            .sort(sort);
        if (c.length === n) {
            const o = {};
            for (let i = 0, l = c.length; i < l; i++) {
                const a = c[i];
                if (!f(a)) return '';
                o[a] = 1
            }
            const b = Object.keys(o).length;
            if ((u && b === u) || (!u && b > 1)) return c.join(j)
        }
        return ''
    };

// 填单策略
function form(type) {
    const is115 = /^_115/.test(type);
    type = type.replace(/ssc|_115/, '');
    const unit = is115 ? 2 : 1;
    let ft = a => a;
    let eg = '123 2345;88767,33021|98897';
    const v = ['yi', 'er', 'san', 'si', 'wu', 'liu', 'qi', 'ba'];
    const vv = [
        '01 00,12;33|45',
        '011 023,124;334|445',
        '0122 0023,1234;3345|4578',
        '01223 00244,12345;33459|45789',
        '01,09;11|02',
        '01 02,09 10;11 02|02 03',
        '01 02 03,09 10 03;11 02 03|02 03 04',
        '01 02 03 04,09 03 04 05;11 03 04 05|02 03 04 05',
        '01 02 03 04 05,09 10 03 04 05;11 02 03 04 05|02 06 03 04 05',
    ];
    const fn = n => c => c.length === n ? c : '';
    if (is115) {
        const i = v.findIndex(c => type.indexOf(c) !== -1);
        eg = vv[i + 4];
        const f115 = c => c.length === 2 && +c > 0 && +c < 12;
        if (type.indexOf('zuxuan') !== -1) {
            ft = mix(i + 1, i + 1, f115, ' ');
        } else if (/zhixuan|renxuan/) {
            ft = c => {
                var arr = c.replace(/^ +| +$/, '').split(/ +/).filter(f115);
                return arr.length === i + 1 ? arr.join(' ') : '';
            }
        }
        // x星直选
    } else if (type.indexOf('zhixuan') !== -1) {
        const i = v.findIndex(c => type.indexOf(c) !== -1);
        if (i !== -1) {
            ft = fn(i + 1);
            eg = vv[i - 1];
        }
        // x 星组选
    } else if (/\w+zuxuan\w+/.test(type)) {
        const t0 = (/zuxuan(\w+)$/.exec(type) || [])[1];
        switch (t0) {
            case 'zusan':
                ft = mix(3, 2);
                eg = '001 112;223,244';
                break;
            case 'zuliu':
                ft = mix(3, 3);
                eg = '012 123;234,789';
                break;
            case 'houer':
            case 'qianer':
                ft = mix(2, 2);
                eg = '01 12;23,78';
                break;
        }

    } else switch (type) {
        case 'qiansan':
        case 'zhongsan':
        case 'housan':
            ft = mix(3);
            eg = '001 012;123,234';
            break;
    }
    return {
        mark: type,
        balls: '',
        isInput: 1,
        getBalls(balls) {
            return uniq(balls, ft, unit)
        },
        getNum(balls) {
            return uniq(balls, ft, unit).length
        },
        getLottery(num, balls) {
            return uniq(balls, ft, unit).join('|')
        },
        getView(num, balls) {
            return uniq(balls, ft, unit).join('|')
        },
        desc: '支持常见的各种单式格式，间隔符如：换行符、回车、逗号、分号等。',
        eg: eg
    }
}

// 速获取选择结果和视图
function getBallData(sp = '') {
    return (num, balls) => {
        let x = balls.length === 1 ? sp : '';
        return balls.map((ba, j) => ba.map((c, i) => c > 0 ? num[j][i] + '' : '')
            .filter(c => c).join(x)).join('|')
    }
}

// 龙虎和玩法处理
function sscLongHuHe(type) {
    const a = ['万', '千', '百', '十', '个'];
    const b = ['wan', 'qian', 'bai', 'shi', 'ge'];
    b.forEach((c, i) => type = type.replace(c, a[i]));
    return {
        mark: type,
        balls: [[-1, -1, -1]],
        num: [[0, 1, 2]],
        views: [['龙', '虎', '和']],
        titles: [type],
        getLottery: getBallData(),
        chooseType: [],
        getNum: balls => balls[0].reduce((a, b) => a + (b > 0), 0),
        getView: getBallData()
    }
}

// 和值玩法处理
function heZhi(type) {
    const bs = [];
    const ns = [];
    const vs = [];
    let ti;
    let vi;
    let di;
    if (type === 'hezhi') {
        ti = '五星和值';
        vi = getBallData('|');
        di = getBallData('|');
        for (let i = 0; i < 46; i++) {
            bs.push(-1);
            ns.push(i);
            vs.push(i + '');
        }
    } else if (type === 'bsde') {
        ti = '大小单双';
        vi = getBallData('');
        di = getBallData('');
        bs.push(-1, -1, -1, -1);
        ns.push(1, 0, 3, 2);
        vs.push('大', '小', '单', '双');
    } else return '';
    return {
        mark: type,
        balls: [bs],
        num: [ns],
        views: [vs],
        titles: [ti],
        getLottery: di,
        chooseType: ['全', '大', '小', '奇', '偶', '清'],
        getNum: balls => balls[0].reduce((a, b) => a + (b > 0), 0),
        getView: vi
    }
}

const sort = (a, b) => a - b;
const sum = (c = []) => c.reduce((a, b) => a + (+b > 0 ? +b : 0), 0);
/**
 * 排列计算 A(b,a)
 * @param a {number}
 * @param b {number}
 * @return {number}
 */
const arrange = (a, b) => {
    let [c, d] = [a, b].sort(sort);
    let s = 1;
    while (c-- > 0) {
        s *= d--
    }
    return s;
};
/**
 * 组合计算 C(b,a)
 * @param a {number}
 * @param b {number}
 * @return {number}
 */
const combine = (a, b) => {
    const [c, d] = [a, b].sort(sort);
    const e = Math.min(d - c, c);
    return arrange(d, e) / arrange(e, e)
};
/**
 * 任选直选和值计数
 * @param m {number} 多少行矩阵 e.g 2 - 任选2
 * @param arr {Array} 每行的内容模板 e.g [0,1,2,3,4]
 */
const countDirect = (m, arr) => {
    const b = {};
    if (m === 1) {
        arr.forEach(c => {
            b[c] = 1
        });
    } else {
        const o = countDirect(m - 1, arr);
        const ks = Object.keys(o);
        arr.forEach(c => {
            ks.forEach(k => {
                const x = o[k];
                b[+k + c] = (b[+k + c] || 0) + x
            })
        });
    }
    return b;
};

/**
 * 任选组选和值计数
 * @param m {number} 多少行矩阵 e.g 2 - 任选2
 * @param arr {Array} 每行的内容模板 e.g [0,1,2,3,4]
 */
const countGroup = (m, arr) => {
    const f = mix(m);
    const all = [];
    while (m-- > 0) {
        const l = all.length;
        if (l === 0) all.push(...arr);
        else {
            const cp = all.concat();
            all.length = 0;
            cp.forEach(c => arr.forEach(a => all.push(c + '' + a)))
        }
    }
    const o = {};
    uniq(all.join(), f).forEach(c => {
        const s = sum(c.split(''));
        o[s] = (o[s] || 0) + 1;
    });
    return o;
};

// 任选类别 玩法逻辑
function anySelect(type) {
    const x = ['万', '千', '百', '十', '个'];
    let n = +type[0];
    const t = type.substr(1);
    let bs, gb;
    const ns = [];
    const vs = [];
    const ct = [];
    const pv = [];
    let ti = [];
    let isInput = 0;
    let vi, desc = '', eg = '', ft, position = [];
    let di, positionMin = n;
    let gi;

    /**
     * 配置直选/组选和值
     * @param fn {function(Number,Array)} countGroup|countDirect
     * @param nm {String} 名字 组选|直选
     */
    function setConf(fn, nm) {
        pv.push('万', '千', '百', '十', '个');
        ct.push('全', '大', '小', '奇', '偶', '清');
        ti.push(nm);
        const b = [], e = [], v = [];
        const cfg = fn(n, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
        const ks = Object.keys(cfg).sort(sort);
        ks.forEach(k => {
            b.push(-1);
            e.push(k);
            v.push(k);
        });
        const z = getBallData('|');
        bs = [b];
        vi = di = z;
        ns.push(e);
        vs.push(v);
        gi = (balls, position) => {
            let s = 0;
            balls[0].forEach((y, i) => (y > 0) && (s += cfg[e[i]]));
            return s * combine(sum(position), n)
        }
    }

    // 直选复式
    if (t === 'zhixuanfushi') {
        ct.push('全', '大', '小', '奇', '偶', '清');
        bs = [];
        x.forEach(c => {
            bs.push([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
            const v = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            ns.push(v.concat());
            vs.push(v);
            ti.push(c);
        });
        di = (num, balls) => balls
            .map(v => v.reduce((a, b, c) => a + (b > 0 ? c : ''), ''))
            .join('|');
        vi = di;
        // 任选X 直选复式
        const ac = (i, arr) => arr
            .reduce(
                (a, b, c, d) => a + b * (i > 2 ?
                    ac(i - 1, d.slice(c + 1)) :
                    sum(d.slice(c + 1)))
                , 0);
        gi = balls => ac(n, balls
            .map(c => c.reduce((a, b) => a + (b > 0), 0))
            .filter(c => c > 0));
    }
    // 任选x 和值
    else if (/\w+hezhi$/.test(t)) {
        if (t === 'zuxuanhezhi') {
            setConf(countGroup, '组选');
        } else if (t === 'zhixuanhezhi') {
            setConf(countDirect, '直选');
        }
    }
    //其他单式
    else if (t.indexOf('danshi') !== -1 || /hunhezuxuan$/.test(t)) {
        const vv = [
            '01 00,12;33|45',
            '011 023,124;334|445',
            '0122 0023,1234;3345|4578',
        ];
        bs = '';
        eg = vv[n - 2];
        let t0 = t
            .replace('danshi', '');
        const fn = i => c => c.length === i ? c : '';
        if (t0 === 'zuxuan' || t0 === 'hunhezuxuan') {
            ft = mix(n);
        } else if (t0 === 'zusan') {
            ft = mix(n, 2);
        } else if (t0 === 'zuliu') {
            ft = mix(n, n);
            eg = '012 023,123;234|435';
        } else ft = fn(n);
        isInput = 1;
        pv.push('万', '千', '百', '十', '个');
        gb = function (balls) {
            return uniq(balls, ft)
        };
        gi = (balls, position) => uniq(balls, ft).length * combine(sum(position), n);
        di = (num, balls) => uniq(balls, ft).join('|');
        vi = (num, balls) => uniq(balls, ft).join('|');
        desc = '支持常见的各种单式格式，间隔符如：换行符、回车、逗号、分号等。';
    }
    //其他复式
    else if (t.indexOf('fushi') !== -1 || /zuxuan\d+/.test(t)) {
        let t0 = t
            .replace('fushi', '')
            .replace('zuxuan', '');
        let cc = combine;
        let x = n;
        ti.push('组选');
        pv.push('万', '千', '百', '十', '个');
        ct.push('全', '大', '小', '奇', '偶', '清');
        bs = [[-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]];
        const v = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        gi = false;
        ns.push(v.concat());
        vs.push(v);
        di = vi = getBallData();
        if (t0 === 'zusan') {
            x = 2;
            cc = arrange;
        } else if (t0 === 'zuliu') x = 3;
        else if (t0 === '6') {
            x = 2;
        } else if (t0 === '12' || t0 === '4') {
            ti.length = 0;
            bs.push(bs[0].concat());
            ns.push(v.concat());
            vs.push(v.concat());
            if (t0 === '12') {
                ti.push('二重号', '单号');
                gi = (balls, position) => {
                    let a = 0;
                    const s = sum(balls[1]);
                    balls[0].forEach((v, i) => {
                        if (v > 0) {
                            const s0 = s - (balls[1][i] > 0);
                            a += s0 > 1 ? combine(s0, 2) : 0;
                        }
                    });
                    return a * combine(sum(position), n)
                }
            } else if (t0 === '4') {
                ti.push('三重号', '单号');
                gi = (balls, position) => {
                    let a = 0;
                    const s = sum(balls[1]);
                    balls[0].forEach((v, i) => {
                        if (v > 0) {
                            a += s - (balls[1][i] > 0)
                        }
                    });
                    return a * combine(sum(position), n)
                }
            }
        }
        if (!gi) gi = (balls, position) => {
            const s = sum(balls[0]);
            return s >= x ? cc(s, x) * combine(sum(position), n) : 0;
        };
    }
    if (pv.length) {
        for (let i = 5; i > 0; i--) {
            position.push(i > n ? 0 : 1)
        }
    }
    return {
        position,
        desc,
        positionMin,
        eg,
        balls: bs,
        num: ns,
        views: vs,
        titles: ti,
        isInput,
        getLottery: di,
        positionView: pv,
        chooseType: ct,
        getNum: gi,
        getView: vi,
        getBalls: gb,
        mark: type
    }
}

function fixGameCfg(cfg, name) {
    cfg.id = name;
    const __getNum = cfg.getNum;
    cfg.getNum = function (a, b) {
        try {
            return __getNum.call(this, a, b);
        } catch (e) {
            console.log(e)
        }
    };
    console.log(name)
    if ('sscyixingdingweidanfushi' === name
        && /\/bet\/(67|66|68|92|7|102|84|85)\/ssc/.test(window.location.pathname)) {
        const gn = cfg.getLottery;
        cfg={...cfg}
        cfg.balls=cfg.balls.slice(2);
        cfg.views=cfg.views.slice(2);
        cfg.titles=cfg.titles.slice(2);
        cfg.getLottery=(...args)=>{
            const r= gn.call(cfg,...args);
            return '||'+r
        }
    }
    if (/^(k3k3k3|pk10)/.test(name)) {
        const o = (views, balls) => {
            let view = "";
            for (let i = 0; i < balls.length; i++) {
                for (let l = 0; l < balls[i].length; l++) {
                    if (!i) {
                        if (balls[i][l] > 0) {
                            view += views[i][l] + "|"
                        }
                    }
                }
            }
            return view.substring(0, view.length - 1);
        };
        if (!cfg.getView) {
            cfg.getView = o;
        }
        if (!cfg.getLottery) cfg.getLottery = o;
        if (!cfg.info) {
            cfg.info = function () {
                const {balls} = this;
                return [
                    this.getLottery(this.num, balls),
                    this.getNum(balls)
                ];
            };
        }
    }
    return cfg
}


// 泛用型玩法补♂全
export function getGameRule(name) {
    const gn = allGames[name];
    if (gn) {
        return fixGameCfg(gn, name);
    }
    console.log('泛用型玩法补♂全:', name);
    let m;
    if (!name.indexOf(m = 'ssclonghulonghuhe')) {
        const type = name.replace(m, '');
        return sscLongHuHe(type);
    }
    if (!name.indexOf(m = 'sschezhiwuxing')) {
        const type = name.replace(m, '');
        return heZhi(type);
    }
    if (!name.indexOf(m = 'sscrenxuanrenxuan')) {
        const type = name.replace(m, '');
        return anySelect(type);
    }
    if (name.indexOf(m = 'danshi') !== -1 || name.indexOf(m = 'zuxuanhunhezuxuan') !== -1) {
        return form(name.replace(m, ''));
    }
    return ''
}
