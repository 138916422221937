export const sscqiansanzhixuanzuhe =  {
    balls:[
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
    ],
    num:[
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
        '万',
        '千',
        '百'
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                   str += num[i][j];
                }
            }
        }
        return str;
    },
    getNum:(balls)=>{
        let num = 1,nums = [];
        for(let i=0,len=balls.length;i<len;i++){
            nums[i] = 0;
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    nums[i] += 1;
                }
            }
        }
        for(let k = 0,len_k=nums.length;k<len_k;k++){
            num *=nums[k] 
        }
        return num*3;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                    str += views[i][j];
                }
            }
        }
        return str;
    }
}
