import React from 'react';
import {HeadA, HeadB, Foot, X, Foot2, LaunchGame} from '../common/com'
import './f.scss'
import {stores} from '../../store';
import {animate} from "../../utils";
import {useThirdGame} from "../../hooks";

const {walletStore} = stores;
const runAni = canvas => {
    if (!canvas) return;
    const w = canvas.width = canvas.offsetWidth;
    const h = canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext('2d');

    function create(o) {
        o = o || {};
        o.x = Math.random() * w;
        o.y = Math.random() * h + h;
        o.r = 20 * Math.random() + 3;
        o.o = .8 + Math.random() * 0.3;
        o.s = 1 + Math.random();
        return o;
    }

    function draw(a) {
        const y = a.y, r = a.r, x = a.x + r * Math.sin(a.y / Math.PI / 18), o = a.o, s = o.s;
        const grd = ctx.createRadialGradient(x, y, 0, x + r * 0.02, y + r * 0.03, r);
        grd.addColorStop(.0, 'hsla(0,0%,100%,0)');
        grd.addColorStop(.5, 'hsla(0,0%,100%,' + o * .02 + ')');
        grd.addColorStop(.95, 'hsla(0,0%,100%,' + o * .1 + ')');
        grd.addColorStop(1, 'hsla(0,0%,100%,' + o * .5 + ')');
        ctx.beginPath();
        ctx.arc(x, y, r, 0, Math.PI * 2);
        ctx.fillStyle = grd;
        ctx.closePath();
        ctx.fill();
        a.y = a.y - a.s * 0.8;
        a.r *= 0.999;
        a.o = (a.y + a.r * 2) / h;
        if (a.o < 0) a.o = 1;
        if (a.y < 0) create(a);
    }

    const bubbles = [];
    const d = window.document.body;
    animate([0, 1000], {
        t: 10,
        next(a) {
            if (parseInt(a) % 10 === 0 && d.contains(canvas)) {
                bubbles.push(create());
            }
        }
    })
    animate([0, 1], {
        r: 1,
        next(_,s) {
            if (d.contains(canvas)) {
                ctx.clearRect(0, 0, w, h);
                bubbles.forEach(draw);
            }else s();
        }
    })
}
const FishCard = ({d}) => {
    const [k, id, cls, ,bls,ptf] = d;
    return <div className={"fish-card " + cls}>
        <div className="load-money">
            <div className="open-wallet">
                <span>￥</span>
                <span className="yellow">{bls.balance || '0.0'}</span><span>元</span></div>
            <i className={bls.loading ? 'ld' : ''} onClick={() => walletStore.query(k)}/>
        </div>
        <LaunchGame
            cls={'launchGame'}
            product_type={k}
            game_mode={1}
            game_code={id}
            platform={ptf}

        />
    </div>
}
export const Fish = () => {
    const config = useThirdGame('fish',[4,25])
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'full c-page fish-page'}>
            <div className={'bg'}/>
            <div className={'ctx'}>
                <div className={'lmt'}>
                    {config.map((a, b) => <FishCard d={a} key={b}/>)}
                    <div className={'fish-card'}/>
                </div>
            </div>
            <div className={'ft'}/>
            <canvas ref={runAni}/>
        </div>
        <Foot2/>
        <Foot/>
    </div>
}

