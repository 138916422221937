import React from 'react';
import {Link} from "react-router-dom";
import './4.scss';
// 没有的页面
export const P404 = () => <>
    <div className={'err-404-page'}>
        <div className={"lg-bg"}>
            <div className={"fx no-page"}>
                <h1>页面不存在</h1>
                <Link to={"/"}>←返回首页</Link>
            </div>
        </div>
    </div>
</>;
