import React, {PureComponent} from 'react';
import "./slideToUp.scss";

export class SlideToUp extends PureComponent{
    constructor(props){
        super(props);
        this.clear = this.clearI.bind(this);
        this.setI = this.setI.bind(this);
        this.move = this.move.bind(this);
        this.renderData = this.renderData.bind(this);
        this.ctrl = React.createRef();
    }

    clearI(){
        clearInterval(this.timer);
        clearTimeout(this.timer2)
    }
    setI(){
        this.clearI(this.timer);
        this.timer = setInterval(()=>{
            this.move();
        },this.props.etime||4000)
    }

    move(){
        const transY = y=>{
            return `-webkit-transform: translate3d(0, -${y}, 0);transform: translate3d(0, -${y}, 0);`
        };
        let p = this.ctrl.current;
        let idx = this.idx || 0;
        const l = p.children.length;
        this.idx = idx = (idx+1)%l;
        if(!l)return;
        const end = idx===l-1;
        const s = (100/l*idx).toFixed(3)+'%';
        p.className = 'warp smooth';
        p.style=transY(s);
        if(end) {
            p.idx = 0;
            p.className = 'warp';
            this.timer2 = setTimeout(()=>p.style=transY(0),500);
        }
    }

    componentDidMount(){
        this.setI()
    }

    componentWillUnmount() {
        this.clearI()
    }

    renderData(){
        const {data=[]} = this.props;
        return data.map(v => {
            if(!v)return null;
            let t = v.c.split(' ');
            return <div key={v.id} className={'slideToChild'}>
                {t[0]} {t[1]} {t[2]}  <span className={'slide_prize'}>{t[3]}</span>  {t[4]}
            </div>
        })
    }

    render(){
        const {children=[]} = this.props;
        const cc  = children.length ? children : this.renderData();
        return <div className={`slideToWraper`}
            onMouseOver={()=>{this.clearI()}} onMouseOut={()=>{this.setI()}}
        >
            <div className={`warp`} ref={this.ctrl}>
                {cc.map((c,i)=><div key={i}>{c}</div>)}
                {cc[0]?<div>{cc[0]}</div>:null}
            </div>
        </div>;
    }
}


