import React, {useEffect, useState} from "react";
import './p.scss'
import {change, Pwd} from "../../components/pwd";

export const PopPwd = ({
                           oldPwd = '',
                           loading = 0,
                           rule, old = 1,
                           tip = '', submit, close,
                       }) => {
    const [e0, ee0] = useState('');
    const [e1, ee1] = useState('');
    const [e2, ee2] = useState('');
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState('');
    const hasEr = !v1 || !v2 || e2 || e1 || e0;
    const ck = i => {
        let ru = rule;
        if(!i)ru = /[0-9a-z]{6,16}/gi;
        const f = v => {
            [vv0, vv1, vv2][i](v);
            let [c, t] = [
                [new RegExp(ru), '须' + tip],
                [new RegExp(ru), '须' + tip],
                [{test: () => !v1 || (i && v === [v2, v1][i - 1])}, '两次密码不一致']
            ][i];
            if (!v) t = ['请输入旧密码', '请输入新密码', '请确认密码'][i];
            if (c.test(v)) t = '';
            [ee0, ee1, ee2][i](t);
            console.log(ru)
            if (t) return;
            if (i !== 2 && v === [v1, v0][i]) ee1(t = '新旧密码不能相同');
            return t;
        };
        return {
            onChange: f,
            blur: change(f)
        }
    }
    const err = e => e ? <span className={'er'}>{e}</span> : '';
    useEffect(()=>{
        if(oldPwd)vv0(oldPwd);
    },[oldPwd])
    return <>
        <div className={'pop-pw'} onClick={e => e.stopPropagation()}>
            {old ? <div className={'r'}>
                <Pwd placeholder={'请输入旧密码'} value={v0} {...ck(0)}/>
                <label>旧密码: {err(e0)}</label>
            </div> : null}
            <div className={'r'}>
                <Pwd placeholder={'请输入新密码'} value={v1} {...ck(1)}/>
                <label>新密码:{err(e1)}</label>
            </div>
            <div className={'r'}>
                <Pwd placeholder={'请再次输入密码'} value={v2} {...ck(2)}/>
                <label>确认密码:{err(e2)}</label>
            </div>
            <div
                className={'btn' + (hasEr ? ' dis' : '')}
                onClick={() => {
                    if (loading) return;
                    if (!hasEr) submit({
                        password_old: v0,
                        password_new: v1
                    }, close)
                }}>{loading ? '提交中..' : '确认'}
            </div>
        </div>
    </>
}
