export const sscwuxingzuxuanzuxuan120 = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	   [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	   [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
       ''
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 0,num_=0,bets = [1,6,21,56,126,252];
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    num++
                }
            }
        }
        if(num>=5){
            num_ = bets[num-5];
        }
        return num_;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += views[i][j];
				}
		    }
    	}
	    return str;
    }
}
