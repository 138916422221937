import React from 'react';
import "./foot.scss"
import {Link} from "react-router-dom";
import {widthStore} from "../../store";

// export const B2Foot = widthStore(
// 	({}) => {
// 		return <div className={"b2-foot"}>
//             <div className={"container"}>
//                 <div className={"l"}>
//                     <div className={"l-money"}>¥ 26,999,000.15</div>
//                     <div className={"l-text"}>今天实现兑奖</div>
//                 </div>
//                 <div className={"m"}>
//                     <div className={"m-people"}>26,055</div>
//                     <div className={"m-text"}>在线人数</div>
//                 </div>
//                 <div className={"r"}>
//                     <span className={"r-1"}></span>
//                     <span className={"r-2"}></span>
//                     <span className={"r-3"}></span>
//                     <span className={"r-4"}></span>
//                     <span className={"r-5"}></span>
//                     <span className={"r-6"}></span>
//                 </div>
//             </div>
//         </div>
// 	}
// )

const b2data = [
    {id:1,icon:'b2a',title:'品牌信誉',desc:'集团16年稳健经营 资金雄厚'},
    {id:2,icon:'b2b',title:'安全保障',desc:'政府颁发合法PAGCOR牌照'},
    {id:3,icon:'b2c',title:'充提便捷',desc:'充值秒到提现2分钟到帐'},
    {id:4,icon:'b2d',title:'玩法多样',desc:'彩种齐全，玩法多样'},
    {id:5,icon:'b2e',title:'金牌服务',desc:'7*24小时客户服务'},
]

function createB2Data(){
    return b2data.map((v,k) => {
        return <div className={'b2child'} key = {v.id}>
            <div className={`b2c_a ${v.icon}`}></div>
            <div className={'b2c_b'}>{v.title}</div>
            <div className={'b2c_c'}>{v.desc}</div>
        </div>
    })
}

export const B2Foot = () => {
    return <div className={"b2-foot"}>
        <div className={"b2container"}>
            {createB2Data()}
        </div>
    </div>
}
