export const _115quweixingquweixingdingdanshuang ={
	balls:[
	    [-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [5, 4, 3, 2, 1, 0]
    ],
    views:[
	    ['5单0双', '4单1双', '3单2双', '2单3双', '1单4双', '0单5双']
    ],
    titles: [
	    '定单双'
    ],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
			    if(balls[i][l] > 0){
				    str += str?(' '+num[i][l]):num[i][l];
				}   
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num=0;
        for(let i=0,len=balls[0].length;i<len;i++){
            if(balls[0][i]>0){
                num++;
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
                if(balls[i][l] > 0){
                    str += str?(' '+views[i][l]):views[i][l];
                }   
            }
        }
        return str;
    }
}
