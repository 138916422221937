import React, {useState} from 'react';
import {Foot, Foot2, HeadA, HeadB, LaunchGame} from "../common/com";
import {useThirdGame} from "../../hooks";
import './s.scss';
import {animate} from "../../utils";
import {panel} from "../../components/tabs";
import {widthStore} from "../../store";

const Card = widthStore((_, {d, i, onClick, mini, act}) => {
    const [k, id, cls, nm, bls, plt, win] = d;
    const fx = v => /\d/.test(v + '') ? v : '---';
    if (mini) return <LaunchGame
        cls={'launchGame sp-btn ' + cls + (act ? ' act' : '')}
        product_type={k}
        platform={plt}
        game_mode={1}
        game_code={id}
        local={win}
        launch={onClick}
    ><i/><span>{nm}</span></LaunchGame>;
    return <div className={'sp-card ' + cls}>
        <div className={"c-bg" + (i % 2 ? ' lf' : '')}/>
        <div className="lc">
            <div className="l-bg">
                <i/><h3>{nm}钱包</h3>
                <div>
                    ￥<span>{fx(bls.balance)}</span>
                    <s className={'ld' + (bls.loading ? ' act' : '')} onClick={() => bls.load()}/>
                </div>
            </div>
            <LaunchGame
                platform={plt}
                cls={'launchGame'}
                product_type={k}
                game_mode={1}
                game_code={id}
                local={win}
                launch={onClick}
            >立即游戏</LaunchGame>
        </div>
    </div>
})
const initCvs = box => {
    if (!box) return;
    const canvas = box.querySelector('#sp-bg');
    const canvas1 = box.querySelector('#sp-bg-1');
    const w = canvas.width = canvas1.width = canvas.offsetWidth;
    const h = canvas.height = canvas1.height = canvas.offsetHeight;
    const ctx = canvas.getContext('2d');
    const ctx2 = canvas1.getContext('2d');
    ctx2.globalCompositeOperation = 'copy';
    ctx.globalAlpha = 0.75;
    const starts = [];
    const max = 100;
    for (let i = 0; i < max; i++) {
        starts.push([
            -0.5 * w + w * 15 * Math.random(),
            -h * 2 * Math.random(),
            0.7 + Math.random(),
            0.2 + Math.random()
        ])
    }

    function draw() {
        ctx2.drawImage(canvas, 0, 0, w, h);
        ctx.clearRect(0, 0, w, h);
        starts.forEach(function (o) {
            ctx.beginPath();
            ctx.arc(o[0], o[1], o[2], 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fillStyle = 'hsla(0,0%,100%,' + o[3] + ')';
            ctx.fill();
        })
        ctx.drawImage(canvas1, 0, 0, w, h);
        move();
    }

    function move() {
        starts.forEach(function (o) {
            const r = o[2];
            const s = r * 0.5 + 1;
            o[0] = (o[0] - s + w) % w;
            const y = o[1] += s;
            // o[2] *= 0.99;
            if (y > w || r < 0.05) {
                o[0] = w * Math.random();
                o[1] = -h / 5 * Math.random();
                o[2] = 0.7 + Math.random();
                o[3] = 0.2 + Math.random();
            }
        });
    }

    animate([0, 1], {
        r: 1,
        next(_, stop) {
            if (window.document.body.contains(canvas)) {
                draw()
            } else stop()
        }
    })
}

export const SportPage = () => {
    const cfg = useThirdGame('sport', [30,19,28, 16,18,14]);
    const [u, uu] = useState('');
    const [a, aa] = useState(-1);
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'full c-page sport-page'}>
            <div className={'ff'}>
                <div className="bg0"/>
                <div className="bg1"/>
                <div ref={initCvs}>
                    <canvas id={'sp-bg'}/>
                    <canvas id={'sp-bg-1'}/>
                </div>
                <div className={'sn'}>
                    <div className="sh"/>
                    <div className={'sm' + (u ? ' gm' : '')}>
                        {panel(u, <div className={'si'}
                                       ref={a => a && a.scrollIntoView({
                                           behavior: 'smooth'
                                       })}>
                            {cfg.map((d, i) => <Card d={d} onClick={v => {
                                uu(v);
                                aa(i);
                            }} key={i} mini={1} act={a === i}/>)}
                        </div>)}
                        {cfg.map((d, i) => <Card onClick={v => {
                            uu(v);
                            aa(i);
                        }} d={d} i={i} key={i}/>)}
                        <div className={'gg' + (u ? ' act' : '')}>{panel(u,
                            () => <iframe title={'gm'} src={u}/>)}</div>
                    </div>
                </div>
            </div>
        </div>
        <Foot2/>
        <Foot/>
    </div>
}
