import React from 'react';
import {Foot, Foot2, HeadA, HeadB, LaunchGame} from "../common/com";
import './p.scss';
import {widthStore} from "../../store";
import {thirdGameStore} from "../../store/third";
/**
 *  {
            PVP: [
                ['xy', '幸运棋牌', 'XY0013', '11612', 'XY', 'd4'],
                ['ky', '开元棋牌 ', 'KY0001', '9801', 'KY', 'd5']
            ]
        },
 [0],
 {
            SPORTS: [
                ['ag', 'AG捕鱼', 'A00012', '10', 'AG', 'd3'],
                ['gg', 'GG捕鱼', 'G00009', '10020', 'GG', 'd0'],
                ['cq9', 'CQ捕鱼', 'CQ0158', '10294', 'CQ9', 'd1'],
                ['rtg', 'RTG捕鱼', 'RTG080', '10327', 'RTG', 'd2']
            ]
        }
 * */


export const PVPList = widthStore(({thirdGameStore})=>{
    return thirdGameStore.pvp.map(({code,id,platform,cls},k)=>{
           return <LaunchGame
                key={k}
                cls={cls}
                product_type={id}
                game_mode={1}
                game_code={code}
                platform={platform}
            />
        })
})

export const PVPPage = () => {
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'full c-pvp'}>
            <div className={'b'}/>
            <div className={'bx'}>
               <div className={'b1'}>
                   <i className={'i0'}/>
                   <i className={'i1'}/>
                    <div className={'ls'}>
                        <PVPList/>
                    </div>
               </div>
            </div>
        </div>
        <Foot2/>
        <Foot/>
    </div>
}
