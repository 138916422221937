import React, {useState, useEffect, useMemo} from 'react';
import {widthStore} from "../../store";
import './tv.scss'
import {sApi} from "./apis";
import {Tabs} from "../tabs";
import {tvCfg} from "./cfg";
import {Loading} from "../loading";
import {PWay} from "./ways";
import {useQuery} from "../../hooks";

let stop = 0;
let lastQ = 0;
export const SportTV = widthStore(({globalStore: {tvTk}}) => {
    const [getTk] = useQuery('token');
    useEffect(()=>{
        getTk()
    },[])
    const ctx = useMemo(() => {
        return {win: null, m: {}, t: 0}
    }, []);
    const [p, pp] = useState('');
    const mus = [
        [3020101, `足球`],
        [3020102, `篮球`],
        [3020103, `网球`]
    ]
    //
    //3020102 - 篮球
    const [t, tb] = useState(0);
    const [u, uu] = useState('');
    const [mc, mmc] = useState([]);
    const [m, mm] = useState({});
    const [ld, lld] = useState(0);

    useEffect(() => {
        stop = 0;
        const t = setInterval(function () {
            if (!p) return;
            const b = document.querySelector('.sport-tv .sub');
            const h = b.offsetHeight;
            const h1 = b.scrollTop;
            const ids = [].filter.call(document.querySelectorAll('.sport-tv .suc'), function ({offsetTop, className}) {
                return /act/.test(className) || offsetTop > h1 - 100 && offsetTop < h1 + h + 100
            }).map(({id}) => id).join();
            const q = lastQ = Math.random();
            sApi('mcs', [mus[ctx.t][0], ids], function (a) {
                if (stop || q !== lastQ) {
                    return;
                }
                let x = 0;
                const v = mc.slice();
                v.forEach(d => {
                    const o = a.find(({mid, tid}) => {
                        return d.mid === mid && d.tid === tid
                    });
                    if (o) {
                        x = 1
                        Object.assign(d, o);
                        const m = ctx.m;
                        if (o.mid === m.mid && o.tid === m.tid) {
                            mm(ctx.m = o);
                        }
                    }
                });
                if (x) mmc(v);
            });
        }, 2000);
        return () => {
            clearInterval(t)
        }
    }, [mc, p, ctx])
    useEffect(() => {
        if (!p) return;
        let a = 0;
        const i = setTimeout(() => lld(1), 60);
        const q = lastQ = Math.random()
        sApi('matchs', mus[ctx.t][0], ({t, d}) => {
            clearTimeout(i);
            lld(0);
            if (a || stop || q !== lastQ) return;
            mmc(d);
        })
        return () => {
            lld(0)
            a = 1;
            clearTimeout(i)
        }
    }, [t, p]);
    useEffect(() => {
        if (mc.length) {
            if (!m.mid || !mc.find(({mid}) => mid === m.mid)) {
                mm(ctx.m = mc[0])
            }
        }
    }, [mc, ctx])
    const {mid, mms, mvs} = m;
    useEffect(() => {
        uu('');
        let s = 0;
        if (mid && p) {
            const v = `${p}?mid=${mid}&domain=${tvCfg.domain}&volume_number=50&sound=1&controls=1&style=theme01&token=${tvTk}`;
            if (+mms === 2) {
                uu(v)
            } else if (+mvs > 0) {
                sApi('video', mid, a => {
                    if (s) return;
                    uu(a);
                });
                return () => {
                    s = 1;
                }
            } else {
                uu(v)
            }
        }
    }, [mid, mms, mvs, p])
    const url = k => {
        if (k && k[0])
            return {
                style: {
                    backgroundImage: `url("${tvCfg.domain}/file/fastdfs/download/image?filePath=${k[0]}")`
                }
            }
    }
    useEffect(() => {
        if (!tvTk) return;
        stop = 0;
        sApi('player', 0, a => {
            if (stop) return;
            pp(a);
        })
        return () => {
            stop = 1
        }
    }, [tvTk])
    const msg = t => {
        try {
            if (ctx.win)
                ctx.win.postMessage(t, "*")
        } catch (e) {

        }
    }
    const showCtrl = (a) => {
        msg({
            cmd: "show_controller",
            val: !!a
        })
    }

    const getS = a => {
        a = a && a[0];
        if (/S1\|/i.test(a)) {
            return a.substr(3).split(':')
        }
        return ['-', '-']
    }
    const {
        msc,// [{S1|21:30}，{S2|21:30}，{S3|21:30}] --比分（比分類型|比分）詳情參攷比分檔案 ：比分定义
        man,// 客隊名稱
        malu,//客隊logo。圖標的url地址
        mhn, //主隊名稱
        tn,
        mhlu,//主隊logo。圖標的url地址, 默认取0,双打球队取 0,1元素
    } = m
    const [sc0, sc1] = getS(msc);
    return <>
        {p ? <h1 className={'tv-ti'}>赛事直播 精彩呈现</h1> : null}
        <div className={'sport-tv' + (p ? ' act' : '')}>
            <div className={'stv-bg'}>
                <div className={'stv-h'}>
                    <div className={'stc-t'}>
                        <span>{tn}</span>
                        <div className={'su-m'}>
                            <div className={'g'}>
                                <i {...url(mhlu)}/>
                                <span>{mhn}</span>
                                <p> {sc0} </p>
                            </div>
                            <b>:</b>
                            <div className={'g'}>
                                <p>{sc1} </p>
                                <span>{man}</span>
                                <i {...url(malu)}/>
                            </div>
                        </div>
                    </div>
                    <Tabs act={t} change={a => {
                        ctx.t = a;
                        tb(a)
                    }
                    }>{o => <div className={'bns'}>
                        {mus.map(([, nm], i) => <div {...o(i)} key={i}>{nm}</div>)}
                    </div>}</Tabs>
                </div>
                <div className={'ctx'}>
                    <div className={'ply'}>
                        {u ? <iframe src={u}
                                     onMouseEnter={() => {
                                         showCtrl(1)
                                     }}
                                     onMouseLeave={() => {
                                         showCtrl(0)
                                     }}
                                     onLoad={a => {
                                         ctx.win = a.target.contentWindow;
                                     }}/> : <iframe/>}
                        <div className={'be'}>
                            <div className={'ls'}>
                                <PWay/>
                            </div>
                        </div>
                    </div>
                    <div className={'sub'}>
                        <Loading act={ld}>正在加载</Loading>
                        {mc.length?mc.map((a, i) => {
                            const {
                                msc,// [{S1|21:30}，{S2|21:30}，{S3|21:30}] --比分（比分類型|比分）詳情參攷比分檔案 ：比分定义
                                mms,// data.mms  	Integer	视频状态 -1 没有配置视频源 ，0 ： 已配置，但是不可用   1： 已配置，可用,暂未播放  2：已配置，可用，播放中
                                man,// 客隊名稱
                                malu,//客隊logo。圖標的url地址
                                mhn, //主隊名稱
                                mgt,// 賽事開始時間
                                mhlu,//主隊logo。圖標的url地址, 默认取0,双打球队取 0,1元素
                                mid: id,
                                tn
                            } = a
                            const dt = (function () {
                                const d = new Date(+mgt);
                                if (d.getFullYear() > 2000) {
                                    return (100 + d.getMonth() + 1 + '-').substr(1)
                                        + (100 + d.getDate() + '  ').substr(1)
                                        + (100 + d.getHours() + ':').substr(1)
                                        + (100 + d.getSeconds() + '').substr(1)
                                }
                                return ''
                            }());
                            const [sc0, sc1] = getS(msc);
                            return <div
                                id={id}
                                onClick={() => mm(ctx.m = a)} key={a.mid + '-' + i}
                                className={'suc' + (m.mid === id ? ' act' : '')}>
                                <div className={'su-t'}>
                                    <span>{tn}</span>
                                    <div className={'stu'}>
                                        {+mms === 2 ? <div><i/><p>视频播放中</p></div> : null}
                                    </div>
                                </div>
                                <div className={'su-m'}>
                                    <div className={'g'}>
                                        <i {...url(mhlu)}/>
                                        <span>{mhn}</span>
                                        <p> {sc0} </p>
                                    </div>
                                    <b>VS</b>
                                    <div className={'g'}>
                                        <p>{sc1} </p>
                                        <span>{man}</span>
                                        <i {...url(malu)}/>
                                    </div>
                                </div>
                                <div className={'tm'}>{dt}</div>
                            </div>
                        }):<p style={{textAlign:'center',marginTop:'30px'}}>暂无赛事</p>}
                    </div>
                </div>
            </div>
        </div>
    </>
})
