import React, {useState, useEffect, useMemo} from 'react';
import {useQuery} from "../../hooks";
import {Table} from "../../components/table/table";
import {widthStore} from "../../store";
import {Link} from "react-router-dom";
import {issuesTimer, WS} from "../../utils";
import {listenNoticeIds} from "../../api/config";

export const His = widthStore(({betStore}) => {
    const {lotteries} = betStore;
    const [tab, setTab] = useState(0);
    const [
        traceQuery, traceLoading,
        traceLis
    ] = useQuery('traceHistory');
    const [
        traceLoop, ,
        traceMix
    ] = useQuery('traceHistory');
    const [
        betQuery, betLoading,
        betLis
    ] = useQuery('getGameRecord');
    const [
        betLoop, ,
        betMix
    ] = useQuery('getGameRecord');
    useEffect(() => {
        if (!tab) betQuery();
        else traceQuery();
    }, [betStore.refresh, tab]);
    const data = ([betLis, traceLis][tab] || {}).data || [];
    const patchData = ([betMix, traceMix][tab] || {}).data || [];
    const loading = [betLoading, traceLoading][tab];
    const ids = [];
    useEffect(() => {
        const fn = d => {
            if (data.find(({status,issue})=>{
                if(!status&&issue===d.issue)return 1
            })) {
                betStore.refresh++;
            }
        }
        WS.subscribe('win_number', fn);
        return () => {
            WS.unsubscribe('win_number', fn);
        }
    }, [data])

    const mixData = data.slice();
    mixData.forEach(n => {
        const d = patchData.find((x) => x.id === n.id);
        if (d && d.status) Object.assign(n, d)
    });
    mixData.forEach(({id, lottery_id, status, issue, start_issue}) => {
        if (!status) {
            if (ids.indexOf(id) === -1) ids.push(id);
            if (!tab) {
                if (issuesTimer.waiting(lottery_id, issue)) return;
            } else if (issuesTimer.waiting(lottery_id, start_issue)) return;
            listenNoticeIds[id] = 1;
        }
    });
    const ctx = useMemo(() => ({}), [])
    ctx.ids = !loading && ids.join();
    useEffect(() => {
        ctx.next = 1;
        let timer = -1;
        timer = setInterval(() => {
            if (!ctx.next) return;
            const {ids} = ctx;
            if (ids) {
                const fn = [betLoop, traceLoop][tab];
                fn({id: ids});
            }
        }, 1e4);
        return () => {
            ctx.next = 0;
            clearInterval(timer);
        }
    }, [ctx.ids, betLoop, tab, traceLoop, ctx]);

    const getName = ({lottery_id}) => lotteries.getNameById(lottery_id);
    const tableCfg = [
        [
            {
                name: '期号',
                key: 'issue'
            },
            {
                name: '游戏',
                cell: getName
            },
            {
                name: '玩法',
                key: 'title'
            },
            {
                name: '开奖号',
                cell: ({winning_number = []}) => winning_number.join((winning_number[0] + '').length > 1 ? ',' : '')
            },
            {
                name: '投注内容',
                cell: ({display_bet_number}) => <div title={display_bet_number}
                                                     className={'dpn'}>{display_bet_number}</div>
            },
            {
                name: '投注金额',
                cell: ({amount}) => parseFloat(amount).toFixed(2)
            },
            {
                name: '状态',
                cell({status, prize}) {
                    const pr = parseFloat(prize);
                    const val = ['待开奖', '已撤销', '未中奖', '已中奖', '已派奖', '系统撤销'][status];
                    return <span className={'status' + (status === 3 ? ' act' : '')}>
                        {pr ? pr.toFixed(4) : val}
                    </span>
                }
            },
            {
                name: '操作',
                cell(d) {
                    const path = {
                        pathname: '/gameDetail',
                        state: d
                    };
                    return <Link to={path}>详情</Link>
                }
            }
        ], [
            {
                name: '游戏',
                cell: getName
            },
            {
                name: '玩法',
                key: 'title'
            },
            {
                name: '起始奖期',
                key: 'start_issue'
            },
            {
                name: '追号进度',
                cell({total_issues, finished_issues}) {
                    return `${finished_issues}/${total_issues}`;
                }
            },
            {
                name: '总追号金额',
                key: 'amount'
            },
            {
                name: '已中奖金额',
                key: 'prize'
            },
            {
                name: '追中即停',
                key: 'stop_on_won'
            },
            {
                name: '状态',
                cell({status}) {
                    // todo check status
                    return ['进行中', '已完成', '用户终止', '管理员终止', '系统终止'][status]
                }
            },
            {
                name: '操作',
                cell(d) {
                    const path = {
                        pathname: '/traceDetail',
                        state: d
                    };
                    return <Link to={path}>详情</Link>
                }
            }
        ]
    ][tab];

    return <div className="his">
        <div className={"his-tab"}>
            <div
                onClick={() => setTab(0)}
                className={tab === 0 ? 'act' : ''}><span>我的投注</span>
            </div>
            <div
                onClick={() => setTab(1)}
                className={tab === 1 ? 'act' : ''}><span>我的追号</span>
            </div>
        </div>
        <div className={'his-lis-box'}>
            <div className={'his-lis'}>
                <Table
                    data={mixData}
                    row={tableCfg}
                />
            </div>
            <div className={'loading' + (loading ? ' act' : '')}><span>正在加载...</span></div>
        </div>
    </div>
});
