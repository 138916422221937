import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Todo: change the version
const version = '1.1.3.1';
if (localStorage.getItem('app_version') !== version) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('app_version',version);
}

Number.prototype.toFixed = function (a = 0) {
    const va = Math.abs(this);
    const pf = this >= 0 ? '' : '-';
    if (isNaN(this)) return NaN + '';
    const k =  Math.pow(10, a);
    const v = (k + Math.floor((va * k) % k) + '')
        .replace(/^1(\d)?/, function (a, b) {
            if (b) return '.' + b;
            return ''
        })
    return pf + Math.floor(va) + v;
};
ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// serviceWorker.register();
