import React, {useCallback, useEffect, useMemo, useState} from "react";
import {history, logout, stores, widthStore} from "../../store";
import './co.scss'
import {Link, NavLink} from "react-router-dom";
import {useQuery, useThirdGame} from "../../hooks";
import {
    animate,
    bubbles,
    fakeNumber,
    jumEncode,
    openWin,
    randArr,
    scrollHorizontal,
    service, showAna,
    tip,
    trim, useMove
} from "../../utils";
import {SlideToUp} from "../home/slideToUp";
import {change} from "../../components/pwd";
import {Select} from "../../components/select/select";
import {popWallet} from "../pop/popWallet";
import {NAV_ACTIVITIES} from "../../api/enum";
import {popVideo} from "../../components/popvideo";
import {query} from "../../api";
import QRCode from "qrcode";
import {popPro} from "../promotion";

const {
    globalStore, walletStore,
} = stores;
const Notice = React.memo(() => {
    const [get, , res] = useQuery('article');
    useEffect(() => {
        get()
    }, []);
    return <div className={'n-notice-ls'}>
        <SlideToUp>
            {
                (res || []).slice(0, 5).map((v, i) => <Link
                    key={i}
                    to={{pathname: `/msg/${v.id}`, state: {data: v}}}>
                    {v.title}
                </Link>)
            }
        </SlideToUp>
    </div>
});

const trans = x => {
    return {
        WebkitTransform: `translate3d(${x}px, 0, 0)`,
        transform: `translate3d(${x}px, 0, 0)`
    }
};

export const GameCate = widthStore(({thirdGameStore}, {keys = [], act, names = {}, filter, click = () => 0}) => {
    const [hasL, sL] = useState(0);
    const [hasR, sR] = useState(1);
    const [move, sM] = useState(0);
    const [node, sN] = useState(null);
    const [p, sP] = useState(0);
    const {thirdGameStore: {productType}} = stores;
    const mv = useCallback((isL) => {
        if (node) {
            const {children: c} = node;
            const l = c.length;
            return () => {
                let s = 0;
                const lf = move + p;
                for (let i = 0; i < l; i++) {
                    const v = c[i].offsetWidth;
                    const x = s + v;
                    if (isL) {
                        if (x >= move) {
                            sM(s);
                            break;
                        }
                    } else {
                        if (x > lf) {
                            sM(x - lf + move);
                            break;
                        }
                    }
                    s = x;
                }
            }
        }
    }, [move, node, p]);
    const sl = mv(1);
    const sr = mv();
    const ckLR = useCallback(() => {
        if (node) {
            sL(+(move > 0));
            sR(+(move + p < node.offsetWidth));
        }
    }, [move, node, p]);
    useEffect(() => {
        ckLR();
    }, [ckLR, move]);
    useEffect(() => {
        if (node) {
            sP(node.parentNode.offsetWidth);
            if (scroll) node.parentNode.addEventListener('wheel', e => {
                e.preventDefault();
                e.stopPropagation();
                if (!scroll) return;
                if (e.deltaY > 0) sr();
                else sl();
            }, true)
        }
    }, [node]);
    useEffect(() => {
        if (node) {
            const n = node.querySelector('.act');
            if (n) {
                const ll = n.offsetLeft;
                const l = ll + n.offsetWidth;
                const p = n.parentElement.offsetWidth;
                if (l > p + move) sM(l - p);
                if (ll < move) {
                    sM(ll)
                }
            }
        }
    }, [act, node, p]);
    // useEffect(()=>click(),[act]);
    const scroll = keys.length > 7;
    const B = ({k}) => {
        const isAll = k === 'ALL';
        const cls = ('i-' + (/^\d+$/g.test(k) ? 'p' + k : k.toLowerCase())) + (act === k ? ' act ' : '');
        if (isAll || !filter || filter(k)) return <div
            onClick={() => click(k)}
            key={k}
            className={cls}
        >
            <i/>
            <span>{isAll ? '全部' : names[k] || productType[k]}</span>
        </div>;
        return null
    };

    return <div className={'game-cate'}>
        {scroll ? <div className={'arr l' + (hasL ? '' : ' hide')} onClick={sl}/> : null}
        <B k={'ALL'}/>
        <div className={'s-warp' + (scroll ? ' scroll' : '')}>
            <div ref={sN} style={trans(-move)}>
                {keys.map(k => <B key={k} k={k}/>)}
            </div>
        </div>
        {scroll ? <div className={'arr r' + (hasR ? '' : ' hide')} onClick={sr}/> : null}
    </div>
});


export const Tip = {
    Ok: ({children}) => <p className={'tp-txt'}><i className={'tp-done'}/><span>{children}</span></p>,
    Err: ({children}) => <p className={'tp-txt'}><i className={'tp-fail'}/><span>{children}</span></p>
}

export const FakeNum = ({a = 1, b = c => c, t = 1}) => {
    const f = () => b(fakeNumber(v => v / a));
    const [n, nn] = useState(f())
    useEffect(() => {
        const tm = setInterval(() => nn(f()), 1e3 * t);
        return () => clearInterval(tm)
    }, [])
    return '' + n;
}

export const OLink = ({url}) => <a href={url} className={'op-lk'} target="__blank"><i/>{url}</a>

const UserInfo = widthStore(() => {
    const {nick_name, is_agent, avatar, user_level} = globalStore;
    const {vip_level} = globalStore;
    const ws = walletStore.wallets;
    const ld = walletStore.loading;
    const total = parseFloat(walletStore.totalBalance).toFixed(2);
    const reload = () => {
        walletStore.load().query();
    }
    useEffect(() => {
        walletStore.load();
    }, [])
    // todo change default to agent/analysis
    return <>
        <div className={'u'}><s className={'ava-' + avatar}/>
            {nick_name}
            <div className={'v'}><span>VIP{vip_level || 0}</span></div>
            <Unread/>
            <i/>
            <div className={'usr-sub'}>
                <Link to={"/hisLotty"}>游戏记录</Link>
                <Link to={"/personal"}>用户中心</Link>
                {is_agent && <Link
                    to={showAna ? "/agent/analysis" :
                        user_level ? "/agent/myInc" :
                            "/agent/subManage"}>代理中心</Link> || ''}
                <div onClick={() => {
                    logout()
                }}>退出登录
                </div>
            </div>
        </div>
        <div className={'wal'}>总资产: <b>{total}</b>元<s/><i className={'ld' + (ld ? ' act' : '')}/><i/>
            <div className={'sub-wal'}>
                <div>
                    <label>主钱包:</label>
                    <span>{(ws[0] && ws[0].balance) || 0}元</span>
                </div>
                <div className={'rd btn'} onClick={reload}>一键刷新</div>
                {ws && ws.slice(1).filter(a => a.balance).map(({balance, name, id}) =>
                    <div className={'r'} key={id}>
                        <label>{name} :</label>
                        <span>{balance}</span>
                    </div>)}
                <p className={'t'}>
                    <i>!</i>
                    只显示有余额的钱包
                </p>
            </div>
        </div>
    </>
});
const cls = (v, c, i) => {
    const {className} = v;
    v.className = className.replace(' ' + c, '');
    if (i) v.className += ' ' + c
}

const rf = fn => {
    return r => {
        fn && fn(r);
        if (r) {
            const p = r.parentElement;
            r.addEventListener('focus', () => cls(p, 'focus', 1))
            r.addEventListener('blur', () => cls(p, 'focus', 0))
        }
    }
}
const Reg = widthStore(({globalStore: {allow_register}}) => {
    const [_, aa] = useState(0);
    useEffect(() => {
        const t = setInterval(() => {
            aa(Math.random())
        }, 300);
        return () => clearInterval(t)
    }, []);
    return allow_register ? <div className={'reg'} onClick={() => tip.popLogin(3)}>免费注册</div> : null
});
export const HeadA = widthStore(
    ({globalStore: {token, user_level}}) => {
        const cls = v => (window.srv || 'srv0') === v ? 'act' : ''
        return <div className={'nhd0'}>
            <div className={'container'}>
                <div className={'nhd-n'}>
                    <Link to={'/quick'} className={'ru'}>线路检测</Link>
                    <i/><Notice/></div>
                <div className={'nhd-m'}>{token ? <>
                    <UserInfo/>
                    <div className={'btn'}>
                        {user_level ? <>
                            {/*<a onClick={() => popWallet.show(0)} className={'c2'}>钱包互转</a>*/}
                            <a onClick={() => popWallet.show(1)} className={'c0'}>存款</a>
                        </> : null}
                        <a onClick={() => popWallet.show(2)} className={'c1'}>取款</a>
                    </div>
                </> : <>
                    <div className={'lg'} onClick={() => tip.popLogin(1)}>登录</div>
                    <Reg/>
                </>}
                    <div className={'srv'}>
                        <s/>
                        <span>客服</span>
                        <div className={'srs'}>
                            <div className={cls('srv0')} onClick={() => service.open('srv0')}>线路一</div>
                            <div className={cls('srv1')} onClick={() => service.open('srv1')}>线路二</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
);

export const DashTitle = ({title = '', icon = '', desc = '', desc1 = '', cls = ''}) => {
    return <div className={'dash-title ' + cls}>
        {icon && <i className={icon}/>}
        <h1>{title}</h1>
        <span>{desc}</span>
        <p>{desc1}</p>
    </div>
};

const SubNav = widthStore(({thirdGameStore}, {name, cls, to, split}) => {
    const c = 'nv-su nv-' + cls;
    let lk = <Link className={c} to={(to + '/' + cls).replace('/lottery/bt', '/lottery')}/>
    if (/live|sport/.test(to)) {
        const cfg = thirdGameStore[to.substring(1)];
        if (cfg && cfg.length) {
            const {code, platform} = cfg.find(a => a.id === +cls) || []
            if (code) lk = <LaunchGame
                cls={c}
                product_type={cls}
                game_mode={1}
                game_code={code}
                platform={platform}
            />
        }
    }
    return <>
        {cls === 'wt' ? <span className={c}/> : lk}
        {split ? <s/> : null}
    </>
});

const N = widthStore(({thirdGameStore}, {to = '', exact = true, children, cls = ''}) => {
        const k = to.substr(1);
        const {availableGames, productType} = thirdGameStore;
        let s = availableGames[k];
        if (k === 'sport') {
            s = (availableGames.sport || []).filter(k => !/24|26|27/.test(k)).sort((a, b) => {
                return b === 30 ? 1 : -1
            })
        } else if (k === 'elect') {
            s = (availableGames.sport || []).filter(k => /24|26|27/.test(k))
        }
        if (k === 'lottery') {
            s = ['bt', 'tcg']
        }
        let ts;
        if (s && s.length > 1 && !/pvp|fish|elect/.test(k)) {
            let v = '';
            if (k === 'lottery') v = 'LOTTO';
            ts = s.map(k => {
                return {
                    cls: k,
                    id: v || productType[k],
                    name: (v || productType[k]) + ' ' + k
                }
            });
            if (s.length === 1) ts.push({cls: 'wt'})
        }
        return <span className={'nv-lk'}><NavLink exact={exact}
                                                  activeClassName={'act'}
                                                  className={`${cls}`}
                                                  to={to}>
             {children}
            {ts && <i/>}
            </NavLink>
            {ts && <div className={'sub-nav' + (' sn-' + k)}>
                <div>
                    <a onClick={() => popPro.show(NAV_ACTIVITIES[k])} className={'pmt p-' + k}/>
                    <div className={'src'} ref={s => {
                        if (s) s.onwheel = scrollHorizontal;
                    }}>
                        <div>
                            {ts.map((o, i) => <SubNav split={ts.length <= 2 && !i} {...o} to={to} key={i}/>)}
                        </div>
                    </div>
                </div>
            </div>
            }</span>
    }
)

export const HeadB = () => <div className={'nhd1'}>
    <div className={'container'}>
        <Link to={'/'} className={'logo'}/>
        <div className={'mu'}>
            <N to={'/'}>首页</N>
            <N to={'/sport'}>体育</N>
            <N to={'/live'}>真人</N>
            <N to={'/lottery'}>彩票</N>
            <N to={'/rng'}>电子</N>
            <N to={'/pvp'}>棋牌</N>
            <N to={'/fish'}>捕鱼</N>
            <N to={'/elect'}>电竞</N>
            <N to={'/vip'}>VIP</N>
            <N cls={'hot'} to={'/promotion'}>优惠</N>
            <N to={'/app'}>手机APP</N>
        </div>
    </div>
</div>;

export const Foot2 = () => <div className={'ft1'}>
    <div className={'ff11'}>
        <div className={'ft0'}>
            <i className={'i0'}/>
            <i className={'i1'}/>
            <s/>
            <i className={'i2'}/>
            <i className={'i3'}/>
            <i className={'i4'}/>
            <s/>
            <i className={'i5'}/>
            <i className={'i6'}/>
        </div>
        <div className={'ft2'}>
            <div>
                <i className={'i7'}/>
                <span>英国GC监督委员会</span>
            </div>
            <div>
                <i className={'i8'}/>
                <span>马耳他牌照（MGA）认证</span>
            </div>
            <div>
                <i className={'i9'}/>
                <span>英属维尔京群岛（BVI）认证</span>
            </div>
            <div>
                <i className={'ia'}/>
                <span>菲律宾（PAGCOR）监督竞猜牌照</span>
            </div>
        </div>
        <div className={'ft3'}>

        </div>
    </div>
</div>;

export const Foot = () =>
    <div className={'nft0'}>
        <div className="cpr">Copyright © 2010 BT Group. All Rights Reserved.
        </div>
    </div>;

export const X = ({cls, full, children}) =>
    <div className={(full ? '' : 'container') + ' sec' + (cls ? ' ' + cls : '')}>
        {children}
    </div>;

export const BubbleBtn = ({children, cls = '', click = () => 0}) => {
    const [btn, sb] = useState(null);
    useEffect(() => {
        if (btn) {
            const fn = () => bubbles(btn, click);
            btn.addEventListener('mouseover', fn, true);
            return () => {
                btn.removeEventListener('mouseover', fn)
            }
        }
    }, [btn, click]);

    return <div ref={sb} className={cls} onClick={click}>
        {children}
    </div>
};
export const LaunchGame = ({
                               link,
                               style,
                               local = 0,
                               launch,
                               product_type,
                               game_mode,
                               game_code,
                               children,
                               cls = '',
                               frameMode,
                               platform = ''
                           }) => {
    const plat = useMemo(() => {
        const s = ['html5-desktop', 'flash', 'html5'];
        const ps = (platform || '').split(',');
        return s.find(a => ps.indexOf(a) !== -1);
    }, [platform]);
    const c = useMemo(() => ({}), [frameMode])
    const url = () => {
        return '/launch.html?' + btoa([product_type, game_mode, game_code, plat, local, stores.globalStore.token].join('&'))
    }
    const open = useCallback(() => {
        if (!stores.globalStore.token) return tip.popLogin(1);
        const next = () => {
            let src = url();
            if (local) {
                if (launch) launch();
            } else if (launch) {
                if (typeof launch === 'function')
                    return launch(src);
            }
            openWin(src, 'game')
        }
        if (!frameMode) next()
    }, [c, frameMode, game_code, game_mode, product_type])
    return frameMode ?
        <iframe id={'ifr'} key={'ifr'} title={'launch'} src={url()}/>
        : link ? <a onClick={open} className={cls} style={style}>
            {children}
        </a> : <div onClick={open} className={cls} style={style}>
            {children}
        </div>
};

export const UList = ({list = [], onChange}) => <div className={'x-tab-a'}>
    {list.map(([n, id], i) => <div
        key={i}
        onClick={() => {
            onChange(list.slice(0, i + 1))
        }}
        className={i === list.length - 1 ? 'act' : ''}>
        {n}
    </div>)}
</div>

export const V = ({v, act, iconOnly, f}) => <div
    onMouseEnter={f}
    className={`vip-btn v-${v}${act ? ' act' : ''}`}>
    {iconOnly ? '' : <span>VIP{v}</span>}
</div>;

export const L = ({cls, title}) => <label className={'tt-a'}><i className={cls}/>{title}</label>;

export const Ipt = ({
                        cls = '',
                        placeholder,
                        value,
                        onChange, type = 'text', onCheck, check = []
                    }) => {
    const [err, sErr] = useState('');
    const runCheck = () => {
        for (let i = 0, l = check.length; i < l; i++) {
            const [test, errText = ''] = check[i] || [];
            let e = false
            if (test instanceof RegExp) e = !test.test(value);
            else if (typeof test === 'function') e = !test(value);
            sErr(e ? errText : '');
            if (onCheck) onCheck(e);
            if (e) return;
        }
    }
    return <div className={'x-ipt ' + cls}>
        <i/>
        <input
            placeholder={placeholder}
            type={type}
            onBlur={runCheck}
            value={value} onChange={change(onChange)}
            onFocus={() => sErr('')}/>
        {err ? <span>{err}</span> : ''}
    </div>
}

export const PrizeNum = ({value, simple}) => {
    const v = parseFloat(value);
    const isNa = isNaN(v);
    const negative = v < 0;
    return <div
        className={'prz-num' + (v ? negative ? ' b' : '' : ' a') + (simple ? ' s' : '')}>{isNa ? '-' : v}</div>
}

export const NoDate = ({children = '暂无数据', cls = ''}) => <div className={'no-data ' + cls}>
    <i/>
    <span>
        {children}
    </span>
</div>

export const SelIpt = ({value = [], onChange, data}) => {
    const ch = (a, b) => {
        const va = value.slice();
        if (a !== undefined) va[0] = a;
        if (b !== undefined) va[1] = b;
        onChange(va);
    }
    return <div className={'sel-ipt'}>
        <Select
            value={value[0]}
            items={data}
            onChange={v => ch(v)}
        />
        <input type={'text'} value={value[1]} onChange={change(v => ch(undefined, trim(v)))}/>
    </div>
}

export const Inf = ({children, cls = ''}) => {
    return <div className={'inf-tip ' + cls}>
        ?
        <div className={'cc'}>
            {children}
        </div>
    </div>
}

export const MoreDate = ({data, cfg = [], cls = '', children, act, Warp}) => {
    return act ? <div className={'mr-data ' + cls}>
        {children}
        <div className={'mr-extra'}>
            {
                cfg.map(([name, key], i) => {
                    let v;
                    if (typeof key === 'function') v = key(data);
                    else v = data[key];
                    return <div key={i}>
                        <label>{name}</label>
                        <span>{Warp ? <Warp value={v}/> : v}</span>
                    </div>
                })
            }
        </div>
    </div> : children
}


const TimerAd = ({children, desc, cls, r}) => {
    const b = r - 180;
    const r0 = `rotateZ(${b >= 0 ? 180 : r}deg)`;
    const r1 = `rotateZ(${b >= 0 ? b : 0}deg)`;
    const a = {
        transForm: r0,
        WebkitTransform: r0,
        MozTransform: r0
    };
    const c = {
        transForm: r1,
        WebkitTransform: r1,
        MozTransform: r1
    };
    return <div className={'cm-tp c-' + cls}>
        <div className={'ci'}>
            <div>
                <div className={'a'} style={a}/>
            </div>
            <div>
                <div className={'b'} style={c}/>
            </div>
            <i className={cls}/>
        </div>
        <div>
            {children}
            <p>{desc}</p>
        </div>
    </div>
}

const vc = {
    v0: 0,
    v1: 0,
    v2: 0,
    r0: 0,
    r1: 0,
    r2: 0
}
export const BannerAd = widthStore(({globalStore: {token, allow_register}}) => {
        const ctx = useMemo(() => (vc), [])
        const [u, uu] = useState(0);
        useEffect(() => {
            return animate([0, 1], {
                r: 1,
                next(v) {
                    const n = new Date();
                    const c2 = 15 * n.getHours() + n.getMinutes() / 4;
                    const t = 0.3;
                    const cur = parseInt(fakeNumber(a => {
                        let x = a / 5000 * Math.abs(Math.sin(n.getMinutes()));
                        x = x - x % 13;
                        return 1000 + a / 2000 + x
                    }).replace(',', ''))
                    const {v0, v1, r2} = ctx;
                    if (v0 < 30) {
                        ctx.v0 += t;
                        ctx.r0 = 360 * ctx.v0 / 30
                    }
                    if (v1 < 180) {
                        ctx.v1 += t * 6;
                        ctx.r1 = 360 * ctx.v1 / 180
                    }
                    ctx.v2 = Math.floor(ctx.v2 + (cur - ctx.v2) / 30);
                    ctx.r2 = Math.floor(ctx.r2 + (c2 - ctx.r2) / 30);
                    uu(v);
                }
            })
        }, [])
        return useMemo(() => <div className={'sub-ban'}>
            <div className={'s0'}>
                <div className={'s01'}>
                    <TimerAd
                        r={ctx.r0}
                        cls={'b0'}
                        desc={'存款平均时间'}>
                        <span>充值<b><span>{Math.floor(ctx.v0)}</span>秒</b></span>
                    </TimerAd>
                    <TimerAd
                        cls={'b1'}
                        r={ctx.r1}
                        desc={'取款平均时间'}>
                        <span>提款<b><span>{Math.floor(ctx.v1)}</span>秒</b></span>
                    </TimerAd>
                    <TimerAd
                        r={ctx.r2}
                        cls={'b2'}
                        desc={'24小时内游戏玩家'}>
                        <span><b><span>{ctx.v2}</span>人</b>正在玩</span>
                    </TimerAd>
                    <div className={'do'}>
                        <i/>
                        <i/>
                        <i/>
                        <i/>
                    </div>
                    <div className={'abs'}/>
                </div>
                <div className={'s1'} onClick={() => {
                    if (token) {
                        history.push('/promotion/')
                    } else {
                        tip.popLogin(allow_register ? 3 : 1)
                    }
                }}>
                    <div className={'s10'}>
                        <div className={'s11'}/>
                    </div>
                    <span>
                 {(token || !allow_register) ? '立即领取' : '立即注册'}
            </span>
                </div>
            </div>
            <div className={'bg'}/>
        </div>, [u])
    }
)


export const VideoBox = ({cls, name, src}) => {
    return <div className={'video-bx'}>
        <div className={'ctx'} onClick={() => popVideo.show(src)}>
            <i className={'ico-play'}/>
            <div className={'v-bg ' + cls}/>
        </div>
        <span>{name}</span>
    </div>
}
export const Unread = widthStore(({globalStore: {unread}}) => {
    useEffect(() => {
        query('unread')
    }, [])
    const un = unread > 99 ? 99 : unread;
    return <span className={'unread'} onClick={() => history.push('/msg')}>{un}</span>
})

export function randName() {
    const players = 'abcdefghijklmnopqrst1234567890';
    const dd = [];
    for (let i = 0; i < 3; i++) {
        const n = players[Math.floor(Math.random() * 30)] + players[Math.floor(Math.random() * 30)];
        dd.push(n)
    }
    return dd.join('').replace(/^(..)(...)/g, '$1***')
}

export const FakeGm = ({games = [], cls = '', children}) => {
    const ar = useMemo(() => [], [])
    const [gm, gg] = useState([]);
    const bu = d => {
        if (!d) return {}
        const nm = randName();
        const pr = Math.floor((Math.random() * 40000 + 5000) / 100) * 100;
        const {name} = d;
        const av = Math.floor(Math.random() * 8);
        return {av, nm, pr, gm: name}
    }
    useEffect(() => {
        const t = setTimeout(() => {
            const b = randArr(games, 1)[0];
            ar.shift();
            ar.push(bu(b));
            gg(ar.slice());
        }, 5e3);
        return () => clearTimeout(t);
    }, [gm])
    useEffect(() => {
        const a = randArr(games, 3);
        ar.push(bu(a[0], 0), bu(a[1], 1), bu(a[2], 2))
        gg(ar.slice())
    }, [])
    return <div className={'fk-l ' + cls}>
        {gm.map((g, i) => children(g, i))}
        <div className={'f-mk'}/>
    </div>
};
const lo = window.location;
const ur = lo.protocol + '//' + lo.hostname + '/app/index.html';
let code = '';
export const AppCode = widthStore(({globalStore}) => {
    const [qr, sQr] = useState('');
    useEffect(() => {
        let stop = 0;
        if (code && !qr) {
            sQr(code)
        } else if (!code) {
            QRCode.toDataURL(globalStore.jumper_page + '?' + jumEncode(ur)).then(u => {
                if (!stop) {
                    code = u;
                    sQr(u);
                }
            })
        }
        return () => {
            stop = 1
        }
    }, [qr, code])
    const s = qr ? {
        backgroundImage: `url(${qr})`
    } : {}
    return <div className={'qr'} style={s}/>
});

const FG = ({av, nm, gm, pr, i = 0}) => {
    return <div className={'fg ' + ['a', 'b', 'c'][i]}>
        <i className={'ava-' + av}/>
        恭喜<span>{nm}</span>,在<span>{gm}</span>中奖<span>{pr}</span>元
    </div>
}

export const Empty = ({
                          p = '/bank',
                          t = '立即绑卡',
                          v = '您还没有绑定银行卡，请绑定银行卡。'
                      }) => <div className={'no-card x-sheet'}>
    <p><i/><span>{v}</span></p>
    <div className={'btn'}
         onClick={() => {
             popWallet.hide();
             if (typeof p === 'string') history.push(p);
             else p()
         }}
    >{t}
    </div>
</div>

export const FakeToday = widthStore(({betStore}) => {
    const gms = betStore.lotteries;
    const r = (g, i) => <FG {...g} i={i} key={g.nm + g.pr + ''}/>;
    return <div className={'today-win'}>
        <div className={'ct'}>
            <div className={'ct0'}>今日已实现兑奖￥<b><FakeNum t={5}/></b></div>
            <div className={'ct1'}>
                <FakeGm games={gms}>{r}</FakeGm>
                <FakeGm games={gms} cls={'a'}>{r}</FakeGm>
                <FakeGm games={gms} cls={'b'}>{r}</FakeGm>
                <FakeGm games={gms} cls={'c'}>{r}</FakeGm>
            </div>
        </div>
    </div>
})
export const ServiceWin = widthStore(({globalStore: {username}}) => {
    const [o, oo] = useState(window.srv||'srv0')
    const [onMouseDown, style, _] = useMove(o, 0)
    const [s, ss] = useState(0)
    const [a, aa] = useState(0)
    const chats = {
        'srv0': '/srv.html?' + username,
        'srv1': '/serv.html?' + username
    }
    const src = useMemo(() => chats[o], [o])
    useEffect(() => {
        window.srv = o
        aa(0)
    }, [o])
    useEffect(() => {
        service.open = (v = window.srv || 'srv0') => {
            oo(v)
            ss(1)
        }
        service.hide = () => {
            ss(0)
        }
    }, [username])
    const name = {
        srv0: '线路一',
        srv1: '线路二',
    }[o] || ''
    const cls = v => o === v ? 'act' : ''
    return <div className={o + ' serv-win' + (s ? ' act' : '')} style={style}>
        <div className={'ti'}
             onMouseDown={onMouseDown}
        >
            <div
                className={'select'}>
                <span onClick={() => aa(1 - a)}>在綫客服 [{name}]</span><i/>
                <div
                    onMouseDown={e => e.stopPropagation()}
                    onClick={e => e.stopPropagation()} className={a ? 'sa' : ''}>
                    <div className={cls('srv0')} onClick={() => oo('srv0')}>綫路一</div>
                    <div className={cls('srv1')} onClick={() => oo('srv1')}>綫路二</div>
                </div>
            </div>

            <span
                onMouseDown={e => e.stopPropagation()}
                onClick={() => service.hide()}>X</span></div>
        {s ? <iframe src={src} key={o + s}/> : null}
    </div>
})


export const CheckBox = ({act, onChange, name}) => {
    return <div className={'x-check' + (act ? ' act' : '')} onClick={() => onChange(!act)}>
        <div>
            ✓
        </div>
        <span>{name}</span>
    </div>
}


export const UserLis = ({ls = [], onChange}) => {
    const l = ls.length;
    return <div className={'x-user-ls'}>
        {ls.map(([a], i) => <span onClick={() => l - 1 !== i && onChange(ls.slice(0, i + 1))} key={i}>{a}</span>)}
    </div>
}
