import React from 'react';
import {widthStore} from "../../store";
import {useTrace} from "../../hooks";
import {betStore} from "../../store/bet";

export const Chase = widthStore(
    ({betListStore,betStore}) => {
        const {
            traceWinStop, traceType, betList, traceIssues,
            betMap, trRateLis, trSameMultipleLis, trMultipleLis
        } = betListStore;
        const ls = betList.map(c => betMap[c]);
        const [create, err, close] = useTrace(ls, traceType);

        const ipt = (label = '', key, min = 1, max = 0, after = '') => <div className={'i'}>
            {label}
            <input
                type="text"
                step={1}
                title={betListStore[key] || 0}
                value={betListStore[key] || 0}
                onFocus={({target})=>target.select()}
                onBlur={({target: {value: v}})=>{
                    if (v < min) v = min;
                    betListStore[key] = v;
                }}
                onChange={({target: {value: v}}) => {
                    v = v.replace(/[^0-9]+/, '');
                    v = parseInt(v) || min;
                    if(v<0)v=0;
                    if (max && v > max) v = max;
                    betListStore[key] = v;
                }}
                min={min}
            />
            {after}
        </div>;
        const raSelAll = !trRateLis.find(({sel}) => !sel);
        const saSelAll = !trSameMultipleLis.find(({sel,err}) => !err&&!sel);
        const f = a => parseFloat(a).toFixed(2);
        const muIpt = a=><input
            value={a.multiple}
            title={a.multiple}
            type="text"
            onFocus={({target}) => target.select()}
            onChange={({target: {value}}) => {
                value = parseInt(value.replace(/[^0-9]/gi, '') || 1);
                if (value > a.max) value = a.max;
                if (value < 1) value = 1;
                a.multiple = value;
                betListStore.saveCurTrace();
            }}/>;
        const rateLs = <table>
            <thead>
            <tr>
                <th className='r7'><input
                    type="checkbox"
                    onChange={({target: {checked}}) => {
                        trRateLis.forEach(a => !a.err&&(a.sel = checked))
                    }}
                    checked={raSelAll}
                /></th>
                <th className='r0'>序号</th>
                <th className='r1'>追号期次</th>
                <th className='r2'>倍数</th>
                <th className='r3'>金额</th>
                <th className='r4'>奖金</th>
                <th className='r5'>盈利金额</th>
                <th className='r6'>盈利率</th>
            </tr>
            </thead>
            <tbody>
            {trRateLis.map((a, i) => <tr key={i} className={(a.sel ? 'act' : '')+(a.err?' err':'')}>
                <td><input checked={a.sel}
                           type="checkbox"
                           onChange={({target: {checked}}) => !a.err&&(a.sel = checked)}/></td>
                <td>{i + 1}</td>
                <td>{a.traceNumber}</td>
                <td>{muIpt(a)}</td>
                <td>{f(a.price * a.multiple)}</td>
                <td>{f(a.prize)}</td>
                <td>{f(a.winAmountAll)}</td>
                <td>{f(a.rate)}%</td>
            </tr>)}
            </tbody>
        </table>;
        const muLis = lis=><table>
            <thead>
            <tr>
                <th className='r7'><input
                    type="checkbox"
                    onChange={({target: {checked}}) => {
                        lis.forEach(c => c.sel = checked)
                    }}
                    checked={saSelAll}
                /></th>
                <th className='r0'>序号</th>
                <th className='s2'>追号期次</th>
                <th className='r2'>倍数</th>
                <th className='r3'>金额</th>
                <th className='s5'>预计开奖时间</th>
            </tr>
            </thead>
            <tbody>
            {lis.map((a, i) => <tr key={i} className={(a.sel ? 'act' : '')+(a.err?' err':'')}>
                <td><input checked={a.sel}
                           type="checkbox"
                           onChange={({target: {checked}}) => a.sel = checked}/></td>
                <td>{i + 1}</td>
                <td>{a.traceNumber}</td>
                <td>{muIpt(a)}</td>
                <td>{f(a.price * a.multiple)}</td>
                <td>{a.time}</td>
            </tr>)}
            </tbody>
        </table>;
        const tm = ipt('追号期数:', 'traceTimes', 2, traceIssues.length);
        const b = (text, v, k = 'traceOp') => <div
            onClick={() => betListStore[k] = v}
            className={(v === betListStore[k] ? 'act' : '')}>
            {text}
        </div>;
        const tool = [
            <>
                {ipt('最低收益率:', 'traceReturnRate')}
                {tm}
            </>,
            <>
                {ipt('起始倍数:', 'traceMultiple')}
                {tm}
            </>,
            <>
                {ipt('起始倍数:', 'traceMultiple')}
                {ipt('隔', 'tractStep', 1, 0, '期')}
                <div className={'op'}>
                    {b('乘', 1)}
                    {b('加', 0)}
                </div>
                {ipt('', 'traceOpMultiple', 1, 20500, '倍')}
                {tm}
            </>,
        ][traceType];
        const selList = [trRateLis, trSameMultipleLis, trMultipleLis][traceType];
        const  {current_number} = betStore;
        const ff = a=>parseInt(a.replace(/[^0-9]/gi,''));
        selList.forEach(s=>{
            if(current_number&&ff(current_number)-ff(s.traceNumber)>0){
                s.sel = false;
                s.err=1;
            }
        });
        let totalA = 0, totalB, totalC = 0;
        selList.forEach(item => {
            if (item.sel&&!item.err) {
                totalA++;
                totalC += item.price*item.multiple
            }
        });
        totalB = totalA * ls.length;
        return <div className={"chase-box"}>
            <div className={'c-tab'}>
                {b('利润追号', 0, 'traceType')}
                {b('同倍追号', 1, 'traceType')}
                {b('翻倍追号', 2, 'traceType')}
                <div
                    className={'btn'}
                    onClick={() => {
                        selList.length = 0;
                    }}
                >清除追号
                </div>
            </div>
            <div className={'da-r'}>
                <div className={'t'}>
                    {tool}
                    <div className={'fx1'}/>
                    <div className={'btn'} onClick={create}>生成追号计划</div>
                </div>
                <div className={'ls'}>
                    {err && <div className={'er'}>{err}<span onClick={close}>X</span></div>}
                    <div className={'c'}>
                        {[rateLs, muLis(trSameMultipleLis),muLis(trMultipleLis)][traceType]}
                    </div>
                </div>
            </div>
            <div className={'f'}>
                <label>
                    <input type="checkbox"
                           onChange={() => betListStore.traceWinStop = !traceWinStop}
                           checked={traceWinStop}
                    />
                    中奖后停止追号
                </label>
                <p>共追号 {totalA} 期,<span>{totalB}</span>注, 金额 <span>￥{totalC.toFixed(2)}</span>元</p>
            </div>
        </div>
    });
