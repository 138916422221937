import React, {useEffect, useState} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const rgba = (c = '', a = .1) => {
    const cc = c.substr(1);
    const p = i => parseInt(cc[2 * i] + cc[2 * i + 1], 16);
    const r = p(0);
    const g = p(1);
    const b = p(2);
    return `rgba(${r},${g},${b},${a})`
};


export const Rate = ({cls = '', n0 = '', large, n1 = '', v = 0, change, start, min = 0, max = 0, step = 1, color = '#2b82ff', children}) => {
    if (start === undefined || start > +min) {
        start = +min
    }
    const [_,update] = useState(0)
   useEffect(()=>{
       const t = setTimeout(()=>update(Math.random()),60)
       return ()=>clearTimeout(t)
   },[start,min,v])
    let color1 = rgba(color);
    const o = {
        height: large ? '12px:' : '6px',
        borderRadius: '6px',
    };
    const s = {color: color};
    const railStyle = {
        ...o,
        backgroundColor: color1
    };
    const handleStyle = {
        height: '20px',
        width: '20px',
        border: 0,
        boxShadow: `${color} 0 4px 13px -4px`
    };
    const dotStyle = {};
    const trackStyle = {
        ...o,
        color: color1,
        backgroundColor: color
    };
    const activeDotStyle = {};
    return <div className={'r ' + cls + (large ? ' lg' : '')}>
        <i style={s}/>
        <label>{n0}
            <span>
            {n1}
                <s className={'li'} style={s}>
                    <b/>
                    <b/>
                    <b/>
                 </s>
        </span>
        </label>
        <div className={'sc'}>
            <i style={s}/>
            <Slider
                trackStyle={trackStyle}
                handleStyle={handleStyle}
                dotStyle={dotStyle}
                railStyle={railStyle}
                activeDotStyle={activeDotStyle}
                value={+v || 0}
                min={start}
                max={+max || 0}
                step={step}
                onChange={v => {
                    if (v < min) v = min;
                    change(v)
                }}
            />
        </div>
        <div className={'de'}>
            {children}
        </div>
    </div>
};
