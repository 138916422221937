import React from 'react';

export const BetHeadSelect = ({value = [], change, min, max, view}) => {
    const click = (n) => {
        const v = value[n];
        const sed = value.reduce((a, b) => a + (b > 0), 0);
        if (v > 0 ? sed - v < min : v < 0 && sed + v > max) {
            return;
        }
        else value[n] = 1 - v;
        if (change) change(value);
    };
    return <div className={'bet-hd-select'}>
        {view.map((v, i) =>
            <div
                key={'any-' + v + '-' + i}
                className={value[i] > 0 ? 'act' : ''}
                onClick={() => click(i)}>{v}
            </div>)}
    </div>
};