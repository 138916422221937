import React from 'react';
import {HeadA, HeadB, Foot, Foot2, LaunchGame} from '../common/com'
import './elec.scss'
import {useThirdGame} from "../../hooks";

const ElectCard = ({d}) => {
    const [k, id, cls, , , ptf] = d;
    return <LaunchGame
        cls={"elect-card " + cls}
        product_type={k}
        game_mode={1}
        game_code={id}
        platform={ptf}
    />
}

export const ElectPage = () => {
    const config = useThirdGame('elect', [24, 27, 26])
    return <div className={'full-page'}>
        <HeadA/>
        <HeadB/>
        <div className={'full c-page elect'}>
            <div className={'ls'}>
                {config.map((a, b) => <ElectCard d={a} key={b}/>)}
            </div>
        </div>
        <Foot2/>
        <Foot/>
    </div>
};
