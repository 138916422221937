import React from 'react';
import {widthStore} from "../../store";
import {Timer} from "../../components/time";
import {BallNum} from "./ballNum";
import {HMu} from "./hmu";

const miss = ['bjkl8', 'cqkl28', 'sd-bjla1', 'sd-bjla2'
    , 'sd-bjla3', 'sd-bjlb1', 'sd-bjlb2', 'sd-bjlb3', 'sd-bjlc1'
    , 'sd-bjlc2', 'sd-bjlc3', 'sd-dicea1', 'sd-dicea2', 'sd-dicea3'
    , 'sd-diceb1', 'sd-diceb2', 'sd-diceb3', 'sd-dicec1'
    , 'sd-dicec2', 'sd-dicec3', 'sd-lhda1', 'sd-lhda2'
    , 'sd-lhda3', 'sd-lhdb1', 'sd-lhdb2', 'sd-lhdb3'
    , 'sd-lhdc1', 'sd-lhdc2', 'sd-lhdc3', 'shssl', 'xjkl28'];


export const BetHead = widthStore(({betStore: {current_number, lotteries = {getByCate: () => {}}}},
                                               {lotteryId, endTime = 0, onEnd, lastIssue: {issue = '---', lottery = []} = {}}) => {
        const {identifier = '', name} = lotteries.getByCate(lotteryId) || {};
        const cls = identifier.toLowerCase();
        let n = '';
        if (miss.indexOf(cls) !== -1 || !identifier) n = name;
        return <div className={"bet-hd"}>
            <div className={'bet-hd-ctx'}>
                <div className={"lg c-" + cls}>
                    {n}
                   <div className={'hove'}>
                       <b/>
                       <HMu cur={lotteryId}/>
                   </div>
                </div>
                <div className={"timer"}>
                    <div className={'i'}>
                        <span><b>{current_number}</b>期截止时间 </span>
                    </div>
                    <Timer trigger={current_number+''+lotteryId} className={'digital'} time={endTime}  onEnd={onEnd}/>
                </div>
                <div className={'issue-box'}>
                    <div className={"no"}><span>{issue}</span>期</div>
                    <BallNum num={lottery}/>
                </div>
            </div>
        </div>
    });
