import React, {useEffect, useMemo, useState} from 'react';
import "./bet.scss"
import {useGameWay, useQuery} from "../../hooks";
import {BetHead} from "./BetHead";
import {BetBall} from "./Balls";
import {GameWay} from "./GameWay";
import {Cart} from "./Cart";
import {His} from "./His";
import {TimeUp} from "./TimeUp";
import {Portals} from "../../components/portals";


import {widthStore} from "../../store";
import {Foot, Foot2, HeadA, HeadB} from "../common/com";
import {Issue} from "./Issue";
import {WS} from "../../utils";

const s2n = a => +((a + "").replace(/[^0-9]/g, ''));
const parseWinNum = (a = "") => {
    if (a) {
        if (/[^0-9]/.test(a)) return a.split(/[^0-9]+/);
        return a.split('');
    }
    return [];
}

export const Bet = widthStore(({betStore}, {match: {params}}) => {
    const [getLot] = useQuery('getLottery');
    const {lotteries} = betStore;
    useEffect(() => {
        getLot();
        betStore._submitting = false;
    }, []);

    const {id, type, way_id} = params;
    const [act, setAct] = useState(false);
    const [getGameWays, wayLd, rs] = useQuery('gameWay');
    const [getIssue, issueLoading, curIsu] = useQuery('issue');
    const [lastIssue, tt] = useState('');
    const [wsIsu, sWsIsu] = useState({});
    const issue = useMemo(() => {
        const o = {...curIsu}
        if (+wsIsu.lottery_id === +id) {
            const {issue: i} = lastIssue || {};
            const {
                cur_issue,
                cur_issue_time,
                cur_time,
                win_number,
                issue: i0
            } = wsIsu || {};
            if (s2n(cur_issue) > s2n(o.cur_issue)) {
                o.cur_issue = cur_issue;
                o.cur_issue_time = cur_issue_time;
                o.cur_time = cur_time;
            }
            if (s2n(i0) >= s2n(o.last_issue) && win_number) {
                o.last_issue = wsIsu.issue;
                if (s2n(i0) > s2n(i)) {
                    tt({
                        issue: i0,
                        lottery: parseWinNum(win_number)
                    })
                }
            }
        }
        return o
    }, [curIsu, wsIsu, id, lastIssue]);
    const [getBalance] = useQuery('balance');
    const {cur_time, cur_issue_time} = issue || {};
    const [getTrend, trendLoading, trend] = useQuery('trend');

    const [his, hh] = useState([]);
    const {current_number, headerData} = betStore;
    const [w0, w1, w2] = useGameWay(issueLoading, headerData);
    const {name_en: e0} = w0;
    const {name_en: e1} = w1;
    const {name_en: e2} = w2;
    useEffect(() => {
        const fn = ({lottery_id, issue, win_number, cur_issue, cur_issue_time, cur_time} = {}) => {
            if (+lottery_id === +id) {
                sWsIsu({
                    ...wsIsu,
                    cur_issue,
                    cur_issue_time,
                    cur_time,
                    win_number,
                    issue,
                    lottery_id
                });
            }
        };
        WS.subscribe('issue', fn);
        WS.subscribe('win_number', fn);
        return () => {
            WS.unsubscribe('win_number', fn);
            WS.unsubscribe('issue', fn);
        }
    }, [id])
    useEffect(() => {
        tt('');
        hh([]);
        return () => {
            betStore.history_gnumber = [];
            betStore._submitting = false
            betStore.betInfo = {};
            betStore.way0 = betStore.way1 = betStore.way2 = 0;
            console.log('clear')
        }
    }, [id]);
    useEffect(() => {
        if (way_id && rs) {
            for (let a of rs)
                for (let b of a.children)
                    for (let c of b.children) {
                        if (c.id === +way_id) {
                            betStore.gKey = null;
                            betStore.way0 = a.id;
                            betStore.way1 = b.id;
                            betStore.way2 = c.id;
                        }
                    }
        }
    }, [rs, way_id])
    const {original_data = []} = trend || {};
    const endTime = useMemo(() => {
        if (!cur_issue_time || !cur_time || issueLoading) return 0;
        const left = new Date(cur_issue_time * 1e3).getTime()
            - new Date(cur_time * 1e3).getTime();
        return left ? left + Date.now() : 0
    }, [issueLoading, cur_issue_time, cur_time])

    useEffect(() => {
        const params = {lottery_id: id};
        getGameWays(params);
        getBalance();
        getIssue(params);
        getTrend({...params, count: 10});
    }, [id]);


    useEffect(() => {
        if (current_number && lastIssue) {
            console.log(current_number, lastIssue, Date.now());
            const [a, b] = [s2n(current_number), s2n(lastIssue.issue)];
            if (a - b === 2) {
                const timer = setInterval(() => {
                    getTrend({lottery_id: id, count: 1});
                }, 3e3);
                return () => clearInterval(timer)
            }
        }
    }, [lastIssue, current_number, id]);


    useEffect(() => {
        const u = original_data[original_data.length - 1];
        if (!trendLoading && u) {
            const {issue: i0 = "0"} = lastIssue || {}
            if (s2n(i0) < s2n(u.issue)) {
                tt(u);
            }
            let x = his.concat();
            original_data.forEach(o => {
                if (!his.find(({issue}) => issue === o.issue)) {
                    x.push(o)
                }
            });
            hh(x.slice(Math.max(x.length - 10, 0)))
        }
    }, [lastIssue, original_data, trendLoading, id]);

    const {identifier = ''} = lotteries.getByCate(id) || {};
    const cls = identifier.toLowerCase();
    return <div className={"bet-page i-" + id + " t-" + type + ' ' + cls + '-bg'}>
        <HeadA/>
        <HeadB/>
        <BetHead endTime={endTime}
                 onEnd={() => setAct(true)}
                 lastIssue={lastIssue}
                 type={type}
                 lotteryId={id}/>
        <div className={"ctx"}>
            <div className={'ctx-bg'}/>
            <GameWay loading={wayLd} gameType={type} lid={id}/>
            <div className={"box"}>
                <BetBall type={type} loading={wayLd} gameId={id}/>
            </div>
            <Cart endTime={endTime} gameId={id}/>
            <div className={"cate-side"}>
                <Issue gameType={type} list={his} lid={id} loading={issueLoading} cate={[e0, e1, e2]}/>
            </div>
        </div>
        <div className={'ctx'}>
            <div className={'ctx-bg'}/>
            <His/>
        </div>
        <Portals>
            <TimeUp act={!issueLoading && issue && act} onEnd={() => {
                setAct(false);
                const params = {lottery_id: id};
                getIssue(params);
                getTrend({...params, count: 10});
            }}/>
        </Portals>
        <Foot2/>
        <Foot/>
    </div>
});
