import React, {useState, useEffect} from 'react';
import './lr.scss'
import {Portals} from "../../components/portals";
import {service, tip} from "../../utils";
import {widthStore} from "../../store";
import {SignWin} from "../../components/login";

let tim = -1;
let tim1 = -1;
export const PopLogin = widthStore(({globalStore}) => {
    const [v, sV] = useState(0);
    const [mo, sM] = useState(0);
    const {token} = globalStore
    const mod = token ? 0 : mo;
    useEffect(() => {
        /**
         * 登录弹窗
         * @param {Number} [mod]
         */
        tip.popLogin = (mod = 1) => {
            tip.popStatus = mod;
            clearTimeout(tim1);
            tim1 = setTimeout(() => sM(mod), 100)
        };
    }, []);

    useEffect(() => {
        clearTimeout(tim);
        tim = setTimeout(() => sV(token ? 0 : mo), 60);
        if (!mod) sM(0);
        return () => clearTimeout(tim);
    }, [mod, token]);
    const act = v && mod;
    return (mod || v) ? <Portals>
        <div className={'lr-mask' + (act ? ' act' : '')}>
            <SignWin key={mod} type={mod-1}/>
        </div>
    </Portals> : null
});
