import React from 'react';
import {Link} from "react-router-dom";
import {Table} from "../../components/table/table";

function renderRow(e0, e1, e2, n = 5) {
    const fn = (f = 0, m = 0, e = 0) => {
        let s = 0, ss = 0;
        if (f) ss = f;
        else if (e) {
            s = n - e;
            ss = n;
        } else if (m) {
            s = (n - m) / 2;
            ss = (n + m) / 2;
        }
        let a = [];
        for (let i = 0; i < n; i++) {
            a.push(+(i >= s && i < ss))
        }
        return a
    };
    const un = ['wan', 'qian', 'bai', 'shi', 'ge'];
    const mp = {
        wuxing: fn(5),
        sixing: fn(4),
        qiansan: fn(3),
        zhongsan: fn(0, 3),
        housan: fn(0, 0, 3),
        erxing: (() => {
            if (/qianer/.test(e2)) return fn(2);
            if (/zhonger/.test(e2)) return fn(0, 2);
            return fn(0, 2);
        })(),
        yixing: fn(5),
        houer: fn(0, 0, 2),
        qianer: fn(2),
    };
    for (let i = 0; i < 4; i++) {
        for (let j = i + 1; j < 5; j++) {
            const o = [0, 0, 0, 0, 0];
            o[i] = o[j] = 1;
            mp[un[i] + un[j]] = o;
        }
    }
    const kk = e2 && e2.replace(e0, '');
    const mm = mp[e0] || mp[kk] || mp[e2];
    const mk = (() => {
        if (mm) return mm;
        if (/guanya/.test(e2)) return fn(2);
        if (/qiansan/.test(e2)) return fn(3);
        if (/qiansi/.test(e2)) return fn(4);
        if (/qianwu/.test(e2)) return fn(5);
        return fn(n);
    })();
    // if (e2) debugger;
    return [
        ({issue}) => <>{issue.substr(-10)}</>,
        ({lottery = []}, n) => {
            return <div className={n ? '' : 'f'}>
                {lottery.map((c, k) =>
                    <span
                        className={mk[k] ? 'b' : ''}
                        key={k}>{c}</span>)}
            </div>
        }
    ];
}


export const Issue = ({list, lid, loading, cate: [e0, e1, e2] = [], gameType = ''}) => {
    const row = renderRow(e0, e1, e2, {pk10: 10, '3d': 3}[gameType]);
    const [c0,c1]=row;
    if (gameType === 'pk10') {
        if (list && list.length > 0) {
            let t = [];
            list.map((v, k) => {
                t[k] = {};
                t[k].issue = v.issue.substr(-6) || '';
                t[k].lottery = v.lottery || [];
                t[k].miss_data = v.miss_data || [];
            })
            list = t;
        }
    }
    return <div className={`issueBox ${gameType}`}>
        <div className={'is-ro'}/>
        <div className={'is-bd'}>
            {loading ? <div className={'ld'}><i/>正在更新...</div> : ''}
            <div className={'iss-ls'}>
                <Table row={[
                    {name:'期号',cell:c0},
                    {name:'开奖',cell: c1}
                ]} data={list.concat().reverse()} />
            </div>
            <div className={'is-foot'}>
                <Link to={'/trend/' + lid}>更多走势</Link>
            </div>
        </div>
    </div>
};
