import React, {useEffect, useMemo, useState} from 'react';
import {widthStore, history} from "../../store";
import {PageFooter} from "../../components/foot/pageFooter";
import "./trend.scss";
import {useQuery} from "../../hooks";
import {useTrend} from "../../hooks/trend";
import {Loading} from "../../components/loading";
import {HeadA, HeadB} from "../common/com";
import {panel, Tabs} from "../../components/tabs";
import {Chart} from "./chart";


export const Trend = widthStore(({trendsStore, betStore}, {match: {params: {id}}}) => {
    id = +id;
    const {lotteries,allLottery} = betStore;
    const [cur,ccur] = useState('')
    const {count, data: {original_data = []}, line, miss, trend, missBar, numHot} = trendsStore;
    const [query, loading] = useQuery('trend');
    const ks = ['SSC', '11-5', 'K3', 'PK10', 'DPC'];
    const [t,tt] = useState(0)
    const [h,hh] = useState(0)
    const [type,ttp] = useState('')
    useEffect(() => {
        query({lottery_id: id, count})
    }, [id, count]);
    useEffect(()=>{
        trendsStore.act=0;
       const l =  lotteries.find(({id:a})=>a===id);
       if(l){
           ccur(l.name);
           ttp(l.cate);
           tt(ks.indexOf(l.series.replace('115','11-5')));
       }
    },[id])
    const  menu = useMemo(()=>{
        const lt = allLottery[ks[h]];
        if(lt){
            return  lt.map(({name,id:lId})=>{
              return   <div
                    onClick={() => {
                        history.replace('/trend/' + lId);
                    }}
                    key={lId} className={id === lId ? 'act' : ''}>
                    {name}
                </div>
            })
        }
        return []
    },[h,id])
    const actCate = trendsStore.act;
    const [cfg, data] = useTrend(type, original_data, actCate);
    const setAct = i => trendsStore.act = i;
    const headMenu = useMemo(()=>{
        if(Array.isArray(cfg))return  cfg.map((name,i)=>{
            const act = actCate === i;
            return {name, act, idx: i};
        })
        return []
    },[id,cfg]);
    const bd = ([key, name], idx) => {
        const v = trendsStore[key];
        return <div key={idx} className={'c-btn'} onClick={() => trendsStore[key] = !v}>
            <input type='checkbox' checked={v} onChange={() => {
            }}/>
            <span>{name}</span>
        </div>
    };
    const bs = [
        ['line', '辅助线'],
        ['miss', '遗漏'],
        ['missBar', '遗漏条'],
        ['trend', '走势'],
        ['numHot', '号温']
    ].map(bd);
    return <div className={'trend-page bet-page ' + type}>
        <HeadA/>
        <HeadB/>
         <div className={'trend-h'} onMouseLeave={()=>hh(t)}>
             <Tabs
                 act={t}
                 cur={h}
                 hoverChange={hh}>{o=><div className={'trend-head'}>
                 <div {...o(0)}>时时彩</div>
                 <div {...o(1)}>11选5</div>
                 <div {...o(2)}>快三</div>
                 <div {...o(3)}>PK10</div>
                 <div {...o(4)}>低频彩</div>
             </div>}</Tabs>
             <div className={'trend-m1'}>
                 {menu}
             </div>
         </div>
        <div className={'trend-ctx'}>
            <div className={'lts'}>{cur}走势图</div>
            {panel(headMenu.length>1,()=><div className={'td-sub-head'}>
                {headMenu.map(({name, act, idx}) =>
                    <div key={idx} className={act ? 'act' : ''} onClick={() => setAct(idx)}>{name}</div>)}
            </div>)}
            <div className={'td-sub-head1'}>
                {bs}
                <div className={count === 30 ? 'act' : ''}
                     onClick={() => trendsStore.count = 30}>近30期
                </div>
                <div className={count === 50 ? 'act' : ''}
                     onClick={() => trendsStore.count = 50}>近50期
                </div>
            </div>
            <Chart data={data} line={line} miss={miss} trend={trend} missBar={missBar} numHot={numHot}/>
            <Loading act={loading}>
                正在更新数据...
            </Loading>
        </div>
        <PageFooter/>
    </div>
});
