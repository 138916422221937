import React, {useEffect, useMemo} from 'react';
import {widthStore} from "../../store";
import './i.scss'
import {query} from "../../api";
import {panel} from "../tabs";


export const Captcha = widthStore(({globalStore: {captchaCode, regCode, capLd}}, {
    useFor = '',
    sc = 1
}) => {
    const d = useMemo(() => ({}), []);
    // useEffect(() => {
    //     if (useFor) {
    //         d.useFor = useFor;
    //         query('captcha', d);
    //     }
    // }, [useFor, d]);
    const code = useFor ? regCode : captchaCode;
    return <div className={'captcha' + (capLd ? ' ld' : '')}
                onClick={capLd ? null : () => {
                    if(useFor)  d.useFor = useFor;
                    query('captcha', d)
                }}
                style={{
                    backgroundImage: `url(${code})`
                }}>{panel(sc, <img src={code} alt={'captcha'}/>)}</div>
})
