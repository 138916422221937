import {Foot, randName, Tip} from "../../common/com";
import React, {useEffect, useMemo, useState} from 'react';
import './a.scss';
import {Link} from "react-router-dom";
import {useQuery} from "../../../hooks";
import {animate, fmtErr, hCurrency, randArr, tip} from "../../../utils";
import {Loading} from "../../../components/loading";
import {popWallet} from "../../pop/popWallet";
import {widthStore} from "../../../store";

let ani = 0;
let act = 0;
let stop = 0;
const Gift = ({data = [], r = 0, token}) => {
    const items = useMemo(() => {
        const da = [{}, {}, {}, {}, {}, {}];
        const l = data.length;
        return data.concat(da).slice(0, Math.max(6, l))
    }, [data]);
    const l = items.length
    const step = 360 / l;
    const rr = 90 * (l - 4) / l;
    let f = null;
    const ls = items.map((a, i) => {
        const r = step * i;
        const st = {
            transform: `rotate(-${r}deg) skewX(${rr}deg)`
        }
        if (!i) f = <div className={'g-dt act'} style={st}/>
        return <div className={'g-dt'} style={st} key={i}/>
    })
    const r0 = {
        transform: `rotate(${((90 - step / 2))}deg)`
    }
    const ro = {
        transform: `rotate(${-r}deg)`
    }
    const r1 = {
        transform: `rotate(${(90 - rr - step / 2)}deg)`
    }
    const p = Math.PI;
    const w = 100 / l / 2 * p + '%';
    const h = 100 / l / 2 * p + '%';
    return <div className={'g-cir-o'} style={r0}>
        {f}
        <div className={'g-cir'} style={ro}>
            {ls}
            <div className={'g-ls'} style={r1}>
                {items.map((a, i) => {
                    const q = 2 * p / l * i;
                    const r = step * i;
                    const st = {
                        width: w,
                        height: h,
                        left: `${50 - 40 * Math.cos(q)}%`,
                        top: `${50 + 40 * Math.sin(q)}%`,
                        transform: `rotate(${-r - 90}deg)  translate3d(-50%,-50%,0)`,
                    }
                    const {
                        name = token ? '谢谢参与' : '请先登录', pic
                    } = a
                    const ss = pic ? {
                        backgroundImage: `url(${pic})`
                    } : {};
                    return <div className={'g-ft'} key={i} style={st}>
                        <i style={ss}/>
                        <div className={'g-ti'}>
                            {hCurrency(name)}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}
const Fkls = ({data = [], loading}) => {
    const [, uu] = useState(0);
    const ls = useMemo(() => [], []);
    const update = () => {
        if (stop) return;
        uu(Math.random())
    }
    useEffect(() => {
        stop = 0;
        return () => {
            stop = 1
        };
    }, [])
    const next = () => {
        ani = 1;
        update();
        setTimeout(() => {
            act = 1;
            update();
        }, 100)
        setTimeout(() => {
            ani = 0;
            act = 0;
            ls.shift();
            ls.push(build(data));
            update();
        }, 2000)
    }
    const build = () => {
        const ar = [];
        data.forEach((a) => {
            const {name} = a
            if (name) {
                const v = /\d/.test(name);
                if (v) ar.push(name);
            }
        })
        return [
            Math.floor(Math.random() * 8),
            randName(),
            randArr(ar, 1)[0]
        ]
    }

    useEffect(() => {
        ls.length = 0;
        ls.push(build(data), build(data), build(data), build(data));
        const t = setInterval(() => {
            next()
        }, 3e3)
        return () => clearInterval(t)
    }, [data])

    return <div className={'pz-ls'}>
        {ls.map((a, i) => {
            const [av, nm, pz] = a;
            const cls = i ? '' : ((act ? ' hi' : '') + (ani ? ' ani' : ''))
            return <div className={'pl' + cls} key={i}>
                <div className={'a'}>
                    <i/>
                    <s className={'ava-' + av}/>
                </div>
                <span>用户名：{nm}</span>
                <div className={'pv'}>{hCurrency(pz)}</div>
            </div>
        })}
        <Loading act={loading}>正在加载...</Loading>
    </div>
}


const ctx = {
    v: 0,
    rolling: 0,
    r: 0,
    a: 0,
    max: 9,
    min: 0,
    number: 1
};
export const ActPage0 = widthStore(({globalStore: {token}}) => {
    const [q0, l0, r0] = useQuery('roulette');
    const [q1, l1, r1] = useQuery('roll');
    const [u, uu] = useState(0);
    useEffect(() => {
        return () => {
            Object.assign(ctx, {
                v: 0,
                rolling: 0,
                r: 0,
                a: 0,
                max: 9,
                min: 1,
                ani: 0,
                number: 1,
                s: 0,
                tg: -1,
            })
        }
    }, [])
    const start = () => {
        if (!token) return tip.popLogin(1);
        if (ctx.rolling) return;
        ctx.a = 0.2;
        ctx.rolling = 1;
        q1(undefined, (a) => {
            ss(a);
            setTimeout(() => end(3), 3000);
            cc(a.number);
        }, (e) => {
            tip.alert({text: <Tip.Err>{fmtErr(e || '请求失败,请稍后尝试。')}</Tip.Err>});
            try {
                const a = e.data.number;
                if (/\d/.test(a)) cc(a);
            } catch (e) {
            }
            end(0)
        })
    }
    useEffect(() => {
        if (!token) {
            return
        }
        q0()
    }, [token])
    const [
        num,
        data
    ] = useMemo(() => {
        const {
            number = 0,
            prizes
        } = r0 || {};
        return [number, prizes || [0, '']]
    }, [r0]);

    const [counts, cc] = useState(num);
    useEffect(() => {
        cc(num)
    }, [num]);

    useEffect(() => {
        const {r, tg} = ctx;
        if (ctx.tg > -1 && ctx.rolling) {
            const st = 360 / (data.length || 6);
            const rr = tg * st;
            return animate([r, rr + 360], {
                done() {
                    ctx.rolling = 0;
                    ctx.tg = -1;
                },
                next(v) {
                    ctx.r = v;
                    uu(v);
                }
            })
        }
    }, [data.length, ctx.tg, ctx.rolling])
    useEffect(() => {
        return animate([0, 1], {
            r: 1,
            next(x) {
                const {v, rolling, max, r, a, tg, ani} = ctx;
                if (!rolling || ani || tg !== -1) return uu(x);
                ctx.v = v + a;
                if (v > max) ctx.v = max;
                ctx.r = (r + v) % 360;
                uu(x);
            }
        })
    }, [data.length])
    const end = n => {
        ctx.tg = n;
    }
    const [s, ss] = useState(null)
    let {name, id} = s || {};
    if (!name) name = ((data || []).find(a => a.id === id) || {}).name
    return <div className={'full-page'}>
        <div className={'full c-page act-0'}>
            <div className={'p0'}>
                <div className={'h'}>
                    <div className={'xt'}><Link to={'/'}><i/></Link></div>
                </div>
                <div className={'ti'}>
                    <i className={'t0'}/>
                    <i className={'t1'}/>
                    <div className={'tb'}>
                        <i className={'y'}/>
                        <i className={'t2'}/>
                        <i className={'t3'}/>
                        <i className={'t4'}/>
                        <i className={'t5'}/>
                    </div>
                </div>
                <div className={'ca'}>
                    <Gift data={data} r={ctx.r} token={token}/>
                    <i className={'c0'} onClick={start}/>
                    <i className={'c1'}/>
                    <Loading act={l0}>正在加载...</Loading>
                </div>
                <div className={'pz-msk' + (s ? ' act' : '')} onClick={() => ss(null)}>
                    <div className={'cd'}>
                        <div className={'ti'}>
                            抽奖结果
                        </div>
                        <div className={'ct'}>
                            <div className={'c'}>{}</div>
                            {/存/.test(name) ? <div className={'bn'} onClick={() => {
                                ss(null);
                                popWallet.show(1)
                            }}>立即充值</div> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'p1'}>
                <div className={'tp'}><i/>提示：充值200元，即可获取一次抽奖机会</div>
                <div className={'tms'}>
                    <span>BT</span>
                    <div className={'lst'}>
                        <span>剩余抽奖次数</span>
                        <b className={'crt'}>{counts}次</b>
                    </div>
                </div>
                <div className={'prz'}>
                    <i className={'crt'}><b>9999</b>元</i>
                    <span>神秘大礼等你来拿</span>
                </div>
                <div className={'pz-ti'}>
                    <div><i/><i/><i/></div>
                    <span className={'crt'}>中奖名单</span>
                    <div className={'r'}><i/><i/><i/></div>
                </div>
                <Fkls data={data} loading={l0}/>
                <div className={'ru'}>
                    <h1>活动时间：</h1>
                    <p>活动时间：长期有效</p>
                    <h1>活动规则：</h1>
                    <p>1. 所有用户完成充值200元，即可获取一次抽奖机会。</p>
                    <p>2. 充值统计时间为当日00:00:00至23:59:59</p>
                    <p>3. 每日充值200元以上即可获得一次抽奖机会。</p>
                    <p><span>每人每日最多参与两次。用户第二次参加活动则需要判断该用户参加第一次抽奖后，充值额度>=200,达到要求后可参与第二次抽奖。</span></p>
                    <p>4. “百家乐充值送10%”“老虎机充值送20%”需完成8倍流水，其他优惠仅需一倍流水即可提款。</p>
                    <p>5. 优惠中奖后可联系在线客服进行申请相应优惠。</p>
                    <p>6. BT游戏拥有此活动的最终解释权，保留对该活动的修改、暂停、终止等所有权利~</p>
                </div>
            </div>
        </div>
        <Foot/>
    </div>
})
