import React, {useEffect, useMemo, useState} from 'react';
import './v.scss'
import {animate} from "../../utils";

export const VScroll = ({horizon = false, ctx}) => {
    const target = useMemo(() => {
        return {
            get h() {
                return horizon ? ctx.vx : ctx.vy
            },
            get t() {
                return horizon ? ctx.x : ctx.y
            },
            set t(v) {
                if (horizon) {
                    ctx.ox = v;
                    ctx.x = v;
                } else {
                    ctx.oy = v;
                    ctx.y = v;
                }
            },
            get m(){
                return horizon ? ctx.mx : ctx.my
            }
        }
    }, [ctx,horizon])
    const cfg = useMemo(() => ({
        _t: 0,
        ot: 0,
        stop: 1,
        h: 0,
       get t(){
            return    target.t*this.r
       },
        mh: 0,
        o: 0,
        r: 0
    }), [target]);
    const [, u] = useState(0)
    useEffect(() => {
        return animate([0, 1], {
            r: 1,
            next(v) {
                if (!cfg.stop) {
                    u(v)
                }
            }
        })
    }, [cfg])

    const getT = e => {
        const {clientX, clientY} = e;
        return horizon ? -clientX : -clientY;
    }
    const mv = e => {
        if(cfg.stop)return ;
        const t = getT(e);
        const {_t, ot, r, ma} = cfg;
        const nt = Math.max(-ma, Math.min(0, t - _t + ot));
        target.t = nt / r;
    }
    const dw = e => {
        cfg._t = getT(e);
        cfg.ot = cfg.t;
        cfg.stop = 0;
    }

    const up = () => {
        cfg.stop = 1
    }

    const style = {}
    if (horizon) {
        style.left = -cfg.t + 'px';
        style.width = cfg.mh+'px';
    } else {
        style.top = -cfg.t + 'px';
        style.height = cfg.mh+'px';
    }

    return <div className={'v-scroll ' + (horizon ? 'horizon' : 'vertical') + (cfg.ma ? '' : ' hi')}
                onMouseMove={mv}
                onMouseUp={up}
                onMouseLeave={up}
                ref={r => {
        if (r) {
            cfg.h = horizon ? r.offsetWidth : r.offsetHeight;
            cfg.ma = cfg.h / target.h * target.m;
            cfg.mh = cfg.h - cfg.ma;
            cfg.r = cfg.ma / target.m;
        }
    }}>
        <div className={'vs-track'}>
            <div className={'vs-btn'} onMouseDown={dw} style={style}/>
        </div>
    </div>
}
