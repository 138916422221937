import React, {useEffect, useMemo, useState} from 'react'
import './c.scss'
import {animate} from "../../utils";
import {NoDate} from "../common/com";
import {VScroll} from "../../components/vScroll";
let cx=''
export const Chart = ({data, line, miss, trend, missBar, numHot}) => {
    const ctx = useMemo(() => ({stop: 1, x: 0, vx:0,vy:0,y: 0, ox: 0, oy: 0, _x: 0, _y: 0, mx: 0, my: 0}), []);
    useEffect(()=>{
        ctx.x = ctx.mx=ctx.ox = 0;
    },[ctx,data])
    const [_, ll] = useState(0)
    useEffect(() => {
        return animate([0, 1], {
            r: 1,
            next(v) {
                const o = JSON.stringify(ctx);
                if(cx!==o){
                    cx=o;
                    ll(v);
                }
            }
        })
    }, [])
    const hg = 30;
    const rLines = v => {
        if (v) {
            v.width = v.offsetWidth;
            v.height = v.offsetHeight;
            const ctx = v.getContext('2d');
            ctx.strokeStyle ='#fd4e5a';
            const clear = [];
            [].forEach.call(
                v.parentElement.querySelectorAll('.act'),
                ({offsetLeft,offsetTop},i) => {
                    const x = offsetLeft+10;
                    const y = offsetTop+10;
                    clear.push([x,y])
                    if(!i){
                        ctx.beginPath();
                        ctx.moveTo(x, y);
                    }
                    else   {
                        ctx.lineTo(x, y);
                    }
                    ctx.stroke();
                });
            clear.forEach(([x,y])=> ctx.clearRect(x-5,y-5,10,10))
        }
    }
    const build = (arr = [],cvs) => {
        const top = {
            top: `${ctx.y}px`
        }
        const ar = JSON.parse(JSON.stringify(arr));
        const fx = (a, b, lv = 0) => {
            if (a === null) return null;
            if (Array.isArray(a)) {
                const g = a.map((c, d) => fx(c, '' + b + d, lv + 1));
                return (g.length && lv === 1) ? <div key={'' + b} className={'wrp'}>{g}</div> : g
            }
            const x = typeof a !== "object" ? {
                value: a,
                key: a + '' + b,
            } : a;
            const {cls, rs = 1, cs = 1, value = '', key, miss} = x;
            return <div
                className={(missBar && miss) ? 'ms' : ''}
                key={'' + key + b}
                style={{
                    flex: cs,
                    height: rs * hg + 'px'
                }}
            >
                <span className={cls}>{value}</span>
            </div>
        }
        return ar.map((o, i) => {
            const [h, b, f] = o.map((a, b) => fx(a, b))
            return <div key={i} className={'ro'}>
                <div className={'fz-hd'}>
                    {h}
                </div>
                <div className={'fz'}>
                    <div className={'fz-ctx'} style={top}>
                        {b}
                        {cvs?  <canvas ref={rLines} style={{height:b.length*hg+'px'}}/>:null}
                    </div>
                </div>
                <div className={'fz-ft'}>
                    {f}
                </div>
            </div>
        })
    }
    if (!data || data.length < 2) return <NoDate cls={'b'}>暂无相关数据</NoDate>;
    const down = e => {
        ctx._x = e.clientX;
        ctx._y = e.clientY;
        ctx.stop = 0;
        ctx.ox = ctx.x;
        ctx.oy = ctx.y;
    }
    const up = () => {
        ctx.stop = 1;
    }
    const mv = e => {
        if (ctx.stop) return e;
        const {clientX, clientY} = e;
        const {_x, _y, ox, oy, mx, my} = ctx;
        ctx.x = Math.max(-mx, Math.min(0, clientX - _x + ox));
        ctx.y = Math.max(-my, Math.min(0, clientY - _y + oy));
    }
    const wh = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {deltaY,deltaX} = e;
        ctx.oy = ctx.y;
        ctx.ox = ctx.x;
        const {oy, my,mx,ox} = ctx;
        if(deltaY)ctx.y = Math.max(-my, Math.min(0, (deltaY > 0 ? -30 : 30) + oy));
        if(deltaX)ctx.x = Math.max(-mx, Math.min(0, (deltaX > 0 ? -30 : 30) + ox));
        return false;
    }
    const left = {
        left: `${ctx.x}px`
    }
    return <div className={'trend-ct-scroll'}>
        <VScroll ctx={ctx}/>
        <VScroll ctx={ctx} horizon />
        <div className={'trend-chart ' +
        (line ? ' line' : '') +
        (miss ? ' miss' : '') +
        (trend ? ' trend' : '') +
        (missBar ? ' msBar' : '') +
        (numHot ? ' hot' : '')
        }
             ref={v => {
                 if (v) {
                     v.onwheel = wh;
                 }
             }}
             onMouseMove={mv}
             onMouseUp={up}
             onMouseDown={down}
             onMouseLeave={up}>
            <div className={'fz-left'}>
                {build(data.slice(0, 2))}
            </div>
            <div className={'fz'}>
                <div className={'fz-right'} style={left} ref={v => {
                    if (v) {
                        const ch = v.querySelector('.fz-ctx');
                        const pc = ch.parentElement;
                        const pr = v.parentElement;
                        const iw = pr.offsetWidth;
                        const ow = v.offsetWidth;
                        const oh = ch.children.length * hg-hg;
                        const ih = pc.offsetHeight;
                        ctx.mx = ow - iw;
                        ctx.my = oh - ih;
                        ctx.vx=ow;
                        ctx.vy=oh;
                    }
                }}>
                    {build(data.slice(2),1)}
                </div>
            </div>
        </div>
    </div>
}
