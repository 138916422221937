import React from 'react';
import "./foot.scss"
import {Link} from "react-router-dom";
import {widthStore} from "../../store";

let othurl = 'https://www.shendeng8.com';
export const B1Foot = widthStore(
    ({}) => {
        return <div className={"b1-foot"}>
            <div className={"b1container"}>
                    <div className={"b1cb"}>
                        {/* <span className={"r2-1"}></span>
                        <span className={"r2-2"}></span>
                        <span className={"r2-3"}></span>
                        <span className={"r2-4"}></span>
                        <span className={"r2-5"}></span>
                        <span className={"r3-1"}></span>
                        <span className={"r3-2"}></span>
                        <span className={"r3-3"}></span> */}
                    </div>
                    <div className={'b1cc'}>
                        
                        <span>
                            <a href={`${othurl}/brand`} target="_blank">关于我们</a>
                        </span>
                        <span>
                            <a href={`${othurl}/mobile-download/`} target="_blank">手机客户端</a>
                        </span>
                        <span>
                            <a href={`${othurl}/pc-client/index.html`} target="_blank">电脑客户端</a>
                        </span>
                        <span>
                            <a href={`https://speed.sd188.info/`} target="_blank">快速登录</a>
                        </span>
                        <span>
                            <a href={`${othurl}/help`} target="_blank">帮助中心</a>
                        </span>
                    </div>
            </div>
            <div className={'b1cd'}>
                <div className={'b1cd_wrap'}>
                    <div className={'b1cd_l'}>
                    Copyright © 2004 - 2019 BT版权所有 PAGCOR牌照认证
                    </div>
                    <div className={'b1cd_r'}>
                    ICP备08026848号 增值电信业务经营许可证：B2-20060345
                    </div>
                </div>
            </div>
        </div>
    }
);
