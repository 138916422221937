import React, {useState, useEffect, useMemo} from "react";
import {Tabs} from "../tabs";
import {DashTitle, UserLis} from "../../pages/common/com";
import {useQuery} from "../../hooks";
import {Table} from "../table/table";
import {Paging} from "../paging/Paging";
import {Loading} from "../loading";
import {Observer} from "mobx-react-lite";
import {stores} from "../../store";
import {DashBoard} from "../dashboard/Dashboard";

import './s.scss'
import {mixFakeDate} from "../../utils";

const ft = d => {
    try {
        if (Array.isArray(d)) return d;
        if (Array.isArray(d.data)) return d.data;
        if (Array.isArray(d.data.data)) return d.data.data;
    } catch (e) {
    }
    return []
}
const A = ({
               uLs,
               sUls,
               userListMod = 0,
               mixData = [],
               noData = [],
               params,
               fakeKeys = [],
               tabs,
               cols = [],
               startTab = 0,
               tabChange,
               fixData = d => {
                   return ft(d)
               },
               beforeQuery,
               empty = '-',
               subHead,
               tableChild = null,
               ctxChild = null,
               title,
               total = d => {
                   if (d && d.last_page) {
                       return d.last_page;
                   }
                   return 1;
               },
               cls = '',
               filterCls = '',
               api,
               filter,
               foot,
               curTab,
           }) => {
    const [cPage, pp] = useState(1);
    const [query, loading, data] = useQuery(api);
    const ps = useMemo(() => ({}), []);
    Object.keys(ps).forEach(k => delete ps[k]);
    let p;
    if (params) {
        if (typeof params === 'function') {
            p = params(cPage)
        } else p = {...params, page: cPage, page_size: 10};
        Object.assign(ps, p);
    }
    delete ps.parent_id;
    if (userListMod) {
        const l = uLs.length;
        if (l > 1) {
            ps.parent_id = uLs[l - 1][1];
        }
    }
    const getData = () => {
        if (api) {
            if (beforeQuery) beforeQuery();
            const t = setTimeout(() => query(ps, undefined, undefined), 100);
            return () => clearTimeout(t);
        }
    };
    useEffect(getData, [cPage, api, uLs]);
    if (tabs) {
        if (Array.isArray(tabs) && tabs.length === 1) {
            title = tabs;
            tabs = '';
        }
    }
    const da = fixData(data, ft) || [];
    if (mixData && mixData.length) {
        mixData.forEach(([f, o]) => {
            for (let i = 0, l = da.length; i < l; i++) {
                const d = da[i];
                if (f(d, o) && o) {
                    Object.assign(d, o)
                    return;
                }
            }
        })
    }
    return <div className={'x-sheet ' + cls}>
        <div className={'x-st-h'}>
            <div className={'x-st-ti'}>
                <i/>
                {title ? <DashTitle title={title}/> : null}
                {tabs ? <Tabs
                    key={curTab}
                    change={(v) => {
                        sUls([['自己']]);
                        pp(1)
                        if (tabChange) {
                            if (tabChange.length === 2) tabChange(v, getData)
                            else tabChange(v)
                        }
                    }} index={startTab}>
                    {
                        o => <div className={'tbs'}>
                            {tabs.map((k, i) => <div {...o(i)} key={i}>{k}</div>)}
                        </div>
                    }
                </Tabs> : null}
            </div>
            {subHead && subHead(getData)}
            {filter ? <div className={'x-sc ' + filterCls}>
                {filter(getData)}
            </div> : null}
        </div>
        {userListMod? <UserLis ls={uLs} onChange={sUls}/> : null}
        <div className={'x-tba'}>
            <div className={'x-st x-ss'}>
                <Table
                    rowCfg={d => {
                        if (d && d.isSelf) return {
                            className: 'isSelf'
                        }
                    }}
                    empty={empty}
                    noData={noData}
                    data={da} row={cols} className={'x-table'}/>
                <Loading act={loading}>
                    正在加载...
                </Loading>
                {tableChild}
            </div>
            <div className={'x-tbf r'}>
                <div className={'x-ft'}>
                    {foot && foot(data)}
                </div>
                <div className={'x-ss'}/>
                <Paging data={{totalPage: total(data), cPage, handleBtnClick: pp}}/>
            </div>
            {ctxChild}
        </div>
    </div>
};

export const widthDashSheet = fc => props => {
    const [uLs, sUls] = useState([['自己']]);
    const isDirect = uLs.length <= 1;
    const {globalStore} = stores;
    return <DashBoard {...props} cls={'xt'}>
        <Observer>
            {
                () => fc(cfg => {
                    return <A
                        {...cfg}
                        uLs={uLs}
                        sUls={sUls}
                    />
                }, stores, {
                    ...props, isDirect, userClick: (a, b) => {
                        if (a !== globalStore.username && !uLs.find(([, d]) => d === b)) {
                            sUls(uLs.concat([[a, b]]))
                        }
                    }
                })
            }
        </Observer>
    </DashBoard>;
}


