export const menuStore = {
    lottery: [
        {
            id: 1,
            name: "时时彩",
            label: "ssc",
            className: "home_ssc",
            children: [
                {
                    id: 1,
                    name: "重庆时时彩",
                    status: 1
                },
                {
                    id: 68,
                    name: "腾讯分分彩",
                    status: 1
                },
                {
                    id: 3,
                    name: "黑龙江时时彩",
                    status: 0
                },
                {
                    id: 6,
                    name: "新疆时时彩",
                    status: 0
                },
                {
                    id: "7",
                    name: "天津时时彩",
                    status: 0
                },
                {
                    id: 62,
                    name: "吉利分分彩",
                    status: 1
                },
                {
                    id: 63,
                    name: "夺金60秒",
                    status: 2
                },
                {
                    id: "23",
                    name: "神灯分分彩",
                    status: 0
                },
                {
                    id: 64,
                    name: "新加坡2分彩",
                    status: 2
                },
                {
                    id: "11",
                    name: "神灯2分彩",
                    status: 0
                },
                {
                    id: 65,
                    name: "菲律宾2分彩",
                    status: 1
                },
                {
                    id: 24,
                    name: "神灯5分彩",
                    status: 0
                },
                {
                    id: 66,
                    name: "腾讯5分彩",
                    status: 1
                },
                {
                    id: 67,
                    name: "腾讯时时彩",
                    status: 1
                },
                {
                    id: 84,
                    name: "河内分分彩",
                    status: 2
                },
                {
                    id: 85,
                    name: "河内5分彩",
                    status: 2
                }
            ]
        },
        {
            id: 2,
            name: "11选5",
            label: "115",
            className: "home_11x5",
            children: [
                {
                    id: 2,
                    name: "山东11选5",
                    status: 1
                },
                {
                    id: "8",
                    name: "江西11选5",
                    status: 0
                },
                {
                    id: 9,
                    name: "广东11选5",
                    status: 1
                },
                {
                    id: 60,
                    name: "上海11选5",
                    label: "",
                    status: 2
                },
                {
                    id: "71",
                    name: "安徽11选5",
                    status: 0
                },
                {
                    id: 73,
                    name: "江苏11选5",
                    label: "",
                    status: 1
                },
                {
                    id: 78,
                    name: "内蒙古11选5",
                    status: 2
                },
                {
                    id: 79,
                    name: "辽宁11选5",
                    label: "",
                    status: 2
                },
                {
                    id: 80,
                    name: "福建11选5",
                    status: 2
                },
                {
                    id: "81",
                    name: "天津11选5",
                    label: "",
                    status: 2
                },
                {
                    id: 82,
                    name: "河北11选5",
                    status: 2
                },
                {
                    id: 83,
                    name: "湖北11选5",
                    label: "",
                    status: 2
                },
                {
                    id: "12",
                    name: "神灯11选5",
                    status: 0
                }
            ]
        },
        {
            id: 3,
            name: "快3",
            label: "k3",
            className: "home_k3",
            children: [
                {
                    id: "21",
                    name: "江苏快3",
                    status: 1
                },
                {
                    id: 22,
                    name: "安徽快3",
                    status: 1
                },
                {
                    id: "61",
                    name: "上海快3",
                    status: 2
                },
                {
                    id: 72,
                    name: "广西快3",
                    status: 0
                },
                {
                    id: 74,
                    name: "湖北快3",
                    status: 1
                },
                {
                    id: 75,
                    name: "北京快3",
                    status: 2
                },
                {
                    id: 77,
                    name: "甘肃快3",
                    status: 2
                },
                {
                    id: "20",
                    name: "神灯快3",
                    status: 0
                }
            ]
        },
        {
            id: 4,
            name: "pk10",
            label: "pk10",
            className: "home_pk10",
            children: [
                {
                    id: 53,
                    name: "北京PK10",
                    label:'pk10',
                    status: 1
                },
                {
                    id: 76,
                    name: "幸运飞艇",
                    label:'pk10',
                    status: 1
                },
                // {
                //     id: Math.random()*1e6,
                //     name: "射手赛车",
                //     status: 2
                // }
            ]
        },
        {
            id: 5,
            name: "其它",
            className: "home_others",
            children: [
                {
                    id: 13,
                    name: "福彩3D",
                    label:'3d',
                    status: 1
                },
                {
                    id: "14",
                    name: "体彩P3/5",
                    label:'p35',
                    status: 0
                },
                // {
                //     id: "20",
                //     name: "幸运28",
                //     label:'xxxxx',
                //     status: 2
                // }
            ]
        }
    ],
    sport: [
        {
            id: 1,
            name: "混合过关",
            label: "",
            status: 1
        },
        {
            id: 2,
            name: "让球/胜平负",
            label: "",
            status: 1
        },
        {
            id: 3,
            name: "半全场",
            label: "",
            status: 1
        },
        {
            id: 4,
            name: "比分",
            label: "",
            status: 1
        },
        {
            id: 5,
            name: "总进球",
            label: "",
            status: 1
        },
        {
            id: 6,
            name: "单关",
            label: "",
            status: 1
        }
    ],
    "elect": [
        {
            id: 1,
            title: "百家乐",
            className: "home-baijia",
            children: [
                {
                    id: 1,
                    name: "金沙厅",
                    "desc": "底注1元 上限5万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 2,
                    name: "永利厅",
                    "desc": "底注100元 上限10万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 3,
                    name: "云顶厅",
                    "desc": "底注1000元 上限20万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            title: "骰宝",
            className: "home-toubao",
            children: [
                {
                    id: 1,
                    name: "金沙厅",
                    "desc": "底注1元 上限5万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 2,
                    name: "永利厅",
                    "desc": "底注100元 上限10万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 3,
                    name: "云顶厅",
                    "desc": "底注1000元 上限20万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            title: "龙虎斗",
            className: "home-lhd",
            children: [
                {
                    id: 1,
                    name: "金沙厅",
                    "desc": "底注1元 上限5万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 2,
                    name: "永利厅",
                    "desc": "底注100元 上限10万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                },
                {
                    id: 3,
                    name: "云顶厅",
                    "desc": "底注1000元 上限20万",
                    "childrenLen": 3,
                    children: [
                        {
                            id: 1,
                            name: "1号桌",
                        },
                        {
                            id: 2,
                            name: "2号桌",
                        },
                        {
                            id: 3,
                            name: "3号桌",
                        }
                    ]
                }
            ]
        }
    ]
};