export const k3k3k3santonghao = {
	balls:[
	   [-1,-1,-1,-1,-1,-1],
	],
    num: [
	   [666,555,444,333,222,111]
    ],
    views: [
	   [666,555,444,333,222,111]
    ],
    titles: [
	   '三同号'
    ],
	chooseType: ['全','清'],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let view = ""; 
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(i == 0){
			    	if(balls[i][l] > 0){
					    view +=views[i][l]+ "|"
				    }
			    }
		    }
    	}
	    return view.substring(0,view.length-1);
    }
}
