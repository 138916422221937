
import {widthStore} from "../store";
import {Redirect} from 'react-router-dom';
import React,{useEffect} from 'react'
import {tip} from "../utils";

export const Reg = widthStore(({globalStore: {allow_register}}) => {
    useEffect(() => {
       setTimeout(function (){
           tip.popLogin(allow_register ? 3 : 1)
       },300)
    }, [])
    return <Redirect to={'/'}/>
})