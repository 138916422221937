import React, {useEffect, useMemo, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {panel, Tabs} from "../../components/tabs";
import './i.scss'
import {animate, trim} from "../../utils";
import {popSetting} from "../pop/popCenter";
import {history} from "../../store";
import {useQuery} from "../../hooks";
import {change} from "../../components/pwd";
import {dateStr, XDate} from "../../components/dateRange";
import {Loading} from "../../components/loading";
import {COMMISSION_TYPES} from "../../api/enum";

const O = ({v = 0}) => {
    let t = [
        ['低', 'r', 40, 'rgb(241,112,112)', 'rgb(240,71,71)', 'rgb(255,176,176)'],
        ['中', 'm', 50, 'rgb(241,164,112)', 'rgb(240,133,71)', 'rgb(255,203,176)'],
        ['高', 'h', 70, 'rgb(112,213,144)', 'rgb(73,199,100)', 'rgb(139,213,162)'],
    ][v]
    const [h, hh] = useState(0)
    const [h1, hh1] = useState(0)
    const [l, ll] = useState(0)
    useEffect(() => {
        return animate([0, t[2]], {
            next(v) {
                hh(v)
            },
            done() {
                ll(1)
            }
        })
    }, [])
    useEffect(() => {
        return animate([0, t[2]], {
            next(v) {
                hh1(v)
            },
            done() {
                ll(1)
            }
        })
    }, [])

    useEffect(() => {
        if (l) return animate([t[2], t[2] - 10], {
            r: 1,
            t: 1.5,
            next(v) {
                hh(v)
            }
        })
    }, [l])
    useEffect(() => {
        if (l) return animate([t[2], t[2] - 15], {
            r: 1,
            t: 1,
            next(v) {
                hh1(v)
            }
        })
    }, [l])

    const s = {borderColor: t[5]}

    return <div className={'status-ball ' + t[1]}>
        <i className={'a'} style={s}/>
        <i className={'e'} style={s}/>
        <i className={'b'} style={s}/>
        <i className={'c'} style={s}/>
        <i className={'d'} style={s}/>
        <svg>
            <g>
                <defs>
                    <clipPath id="cut">
                        <linearGradient id="Gradient">
                            <stop offset="0" stopColor="white" stopOpacity="1"/>
                            <stop offset=".5" stopColor="white" stopOpacity=".4"/>
                            <stop offset="1" stopColor="white" stopOpacity=".7"/>
                        </linearGradient>
                        <circle cx="63" cy="69" r="55" fill="url(#Gradient)"/>
                    </clipPath>
                    <mask id="Mask">
                        <circle cx="63" cy="69" r="55" fill="url(#Gradient)"/>
                    </mask>
                </defs>
                <circle cx="63" cy="69" r="55" stroke={t[5]} fill={'none'}/>
                <path clipPath="url(#cut)" d={`M0,${110 - h1},q 30  10,60 0,q 30 -10,60 0,L130 130,0 130,Z`}
                      mask="url(#Mask)" fill={t[4]}/>
                <path clipPath="url(#cut)" d={`M0,${110 - h},q 30 -25,60 0,q 30 20,60 0,L130 130,0 130,Z`}
                      mask="url(#Mask)" fill={t[3]}/>
            </g>
            <g className={'rot'}>
                <circle cx="10" cy="40" r="2" fill={t[5]}/>
                <path d="M50 10, A 60 60  0 1 1 10 40" stroke={t[5]} fill="none"/>
            </g>
        </svg>
        <span>{t[0]}</span>
    </div>
}

const Box = ({cls, name, desc, finished, t0, t1, onClick}) => {
    return <div className={'bx'}>
        <i className={cls}/>
        <div className={'if'}>
            <label>{name}</label>
            <p>{desc}</p>
        </div>
        <div
            onClick={() => onClick && onClick(finished)}
            className={'bt ' + (finished ? ' f' : '')}>
            {finished ? t1 : t0}
        </div>
    </div>
}

const A = widthDashBoard((props, {
    myBankStore: {banks},
    globalStore: {
        login_ip, signin_at, prize_group,
        username, commission_rate = [], nickname, birthday,
        has_fund_password, real_name, is_valid_phone, is_agent
    }
}) => {
    const [query] = useQuery('myBankCard');
    const [com, cLd, data] = useQuery('getPrize');
    const [set, sLd] = useQuery('setUserInfo');
    const fx = (a = {}) => (parseFloat(a).toFixed(2) || '0') + '%';
    useEffect(() => {
        com()
        query()
    }, [])
    const [v0, vv0] = useState(nickname)
    const [v1, vv1] = useState('')
    const hasBank = banks && banks.length;
    const s = +has_fund_password + (+!!real_name) + (+!!is_valid_phone) + (+!!hasBank);
    const [t, tt] = useState(0)
    const r = useMemo(() => {
        let m1 = 0, m2 = 0, m3 = 0, m4 = 0, m5 = 0, m6 = 0;
        commission_rate.forEach(({type_id, commission_rate: r}) => {
            switch (+type_id) {
                case  1:
                    m1 = r;
                    break;
                case  COMMISSION_TYPES.sport:
                    m2 = r;
                    break;
                case  COMMISSION_TYPES.live:
                    m3 = r;
                    break;
                case  COMMISSION_TYPES.rng:
                    m4 = r;
                    break;
                case  COMMISSION_TYPES.pvp:
                    m5 = r;
                    break;
                case  COMMISSION_TYPES.fish:
                    m6 = r;
                    break;
                default:
                    break;
            }
        });
        const {lottery = []} = data || {};
        return {
            fish: fx(m6),
            lottery: [(is_agent ? fx(m1) + ' / ' : '') + prize_group, lottery],
            pvp: fx(m5),
            real: fx(m3),
            rng: fx(m4),
            sport: fx(m2)
        }
    }, [data])
    return <div className={'x-sheet cenPage'}>
        <div className={'x-st-h'}>
            <div className={'x-st-ti'}>
                <i/>
                <Tabs change={tt}>{o => <div className={'tbs'}>
                    <div {...o(0)}>安全中心</div>
                    <div {...o(1)}>个人信息</div>
                </div>}</Tabs>
            </div>
            {panel(!t, <p className={'tip'}>
                为了确保您的账户安全，请您填写相关安全信息.
            </p>)}
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                {panel(t, <>
                    <div className={'info'}>
                        <div className={'r'}><label>账号：</label>{username}</div>
                        {/*<div className={'r'}><label>注册时间：</label>{regTime}</div>*/}
                        <div className={'r'}><label>昵称：</label><input
                            value={v0}
                            onChange={change(vv0, trim)}
                        /></div>
                        <div className={'r'}><label>生日：</label>
                            <XDate single readOnly={birthday} value={[birthday]} onChange={vv1}/>
                            <p>生日填写后不可再次修改</p></div>
                        <div className={'btn'} onClick={sLd ? null : () => {
                            set({
                                nickname: v0,
                                birthday: dateStr(v1)
                            })
                        }}>{sLd ? '保存中..' : '保存'}</div>
                    </div>
                    <div className={'si'}>
                        <div className={'a'}>返点</div>
                        <div>
                            <div className={'r'}>
                                <span>真人</span>
                                <span>{r.real}</span>
                                <span>彩票</span>
                                <span className={'v'}>{r.lottery[0]}
                                    <div className={'dt'}>{
                                        r.lottery[1].map(({name, commission, prize_group}, i) => <div key={i}>
                                            <label>{name}</label><span>{(is_agent?fx(commission * 100)+' / ':'')}{prize_group}</span>
                                        </div>)
                                    }</div>
                                </span>
                            </div>
                            <div className={'r'}>
                                <span>体育</span>
                                <span>{r.sport}</span>
                                <span>电子</span>
                                <span>{r.rng}</span>
                            </div>
                            <div className={'r'}>
                                <span>棋牌</span>
                                <span>{r.pvp}</span>
                                <span>捕鱼</span>
                                <span>{r.fish}</span>
                            </div>
                        </div>
                    </div>
                    <Loading act={cLd}>正在更新个人信息</Loading>
                </>)}
                {panel(!t, <>
                    <div className={'mn'}>
                        <O v={s === 4 ? 2 : s >= 2 ? 1 : 0}/>
                        <div>
                            <div className={'r'}>
                                <label>上次登陆时间：<span>{signin_at}</span></label>
                                <label>上次登IP：<span>{login_ip}</span></label>
                            </div>
                            <label>不是本人登录？<span
                                onClick={() => popSetting.show(0)}
                                className={'ch'}>修改密码</span></label>
                        </div>
                    </div>
                    <Box cls={'a'}
                         desc={'修改登录时的密码，定期修改有利于账户安全'}
                         name={'登录密码'}
                         onClick={() => popSetting.show(0)}
                         finished={1} t1={'修改'}/>
                    <Box cls={'b'}
                         desc={'修改资金密码，定期修改有利于账户安全'}
                         name={'资金密码'}
                         onClick={a => popSetting.show(a ? 2 : 1)}
                         finished={has_fund_password}
                         t0={'设定'}
                         t1={'修改'}/>
                    <Box cls={'c'}
                         desc={'绑定银行卡用于取款'}
                         name={'绑定银行卡'}
                         finished={hasBank}
                         onClick={() => history.push('/bank')}
                         t0={'绑定'}
                         t1={'管理'}/>
                    <Box cls={'d'}
                         desc={'绑定手机号，防止账户被盗'}
                         name={'验证手机'}
                         finished={is_valid_phone}
                         onClick={a => popSetting.show(a ? 4 : 3)}
                         t0={'验证'}
                         t1={'已验证'}/>
                </>)}
            </div>
        </div>
    </div>
})

export const CenterPage = props => <A {...props} cls={'xt'}/>
