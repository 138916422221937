import React, {useEffect, useState, useMemo} from "react";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import {useQuery} from "../../hooks";
import {Select} from "../../components/select/select";
import './f.scss';
import {widthDashSheet} from "../../components/sheet";
import {isAgentPage} from "../../utils";

/***
 *   账变
 *   交易记录 | 存款记录 | 提款记录c
 */

export const FoundRec = widthDashSheet((sheet, {
}) => {
    const [getWays] = useQuery('getWays');
    const [q, ld, dat] = useQuery('getTransType');
    useEffect(() => {
        getWays();
        q();
    }, []);
    const data = useMemo(()=>{
        const a = [['', '全部类型']].concat((dat || []).map(({id, name}) => {
            const o={
                YB:'OB真人',
                YBQP:'OB棋牌',
                BTI:'BT体育',
                ALB:'188体育',
                TCG:'盘口彩票',
                AV:'泛亚电竞'
            }
            if(/主钱包/.test(name)){
                name = name.replace(/\w+/,function (a){
                    return o[a]||a
                })
            }
            return [id, name]
        }));
        if(isAgentPage())return a;
        return  a.filter(([,name])=>!/分红|佣金|代理/.test(name))
    },[dat])
    const [a, aa] = useState(0);
    const [k, kk] = useState([]);
    const [[start, end], ss] = useState([])
    let params = {
        start: start ? `${start} 00:00:00` : '',
        end: end ? `${end} 23:59:59` : '',
    };
    const dd = function (o = {}) {
        ss([o.start, o.end])
    };
    const categories = useMemo(() => {
        switch (a) {
            case  0:
                return data;
            case  1:
                return [
                    ['', '所有'],
                    [0, '待处理'],
                    [1, '申请成功'],
                    [2, '申请失败'],
                    [3, '充值成功'],
                    [4, '加币异常'],
                    [5, '充值失败'],
                ];
            case  2:
                return [
                    ['', '所有'],
                    [0, '待审核'],
                    [1, '审核待定'],
                    [2, '复审通过'],
                    [3, '已拒绝'],
                    [4, '提现成功'],
                    [5, '提现失败'],
                    [6, '加币失败'],
                    [7, '部分成功'],
                    [8, '已退款'],
                    [9, '处理中'],
                    [10, '异常返回'],
                    [11, '提现失败'],
                    [12, '复审中'],
                    [13, '初审中'],
                    [14, '初审通过']
                ];
            default:
                return []
        }

    }, [a, dat]);
    const transTp = (k = 'type_id') => (
        {
            cell: (o) => {
                return ((dat || []).find(({id: t}) => t === o[k]) || {}).name
            },
            name: '账变类型'
        }
    );
    const status = ({status})=>(categories.find(c=>c[0].toString()===status.toString())||['',''])[1]
    const  api = ['getChargeRecord', 'getChargeApply', 'getWithDrawApply'][a];
    if (k[a]!=='') params.type_id = k[a];
    const col =[
        [
            transTp(),
            {key: 'created_at', name: '时间'},
            {key: 'amount', name: '变动金额'},
            {key: 'available', name: '余额'},
            {key: 'serial_number', name: '订单编号'},
        ],
        [
            {name: '充值方式', key: 'deposit_mode'},
            {name: '申请金额', key: 'amount'},
            {name: '申请时间', key: 'created_at'},
            {name: '到账金额', key: 'real_amount'},
            {name: '到账时间', key: 'created_at'},
            {
                name: '状态',   cell:status
            },
            {name: '编号', key: 'company_order_num'},
        ],
        [
            {name: '银行名称', key: 'bank'},
            {name: '卡号', key: 'account'},
            {name: '提款金额', key: 'amount'},
            {name: '提款时间', key: 'created_at'},
            {
                name: '状态',
                cell:status
            },
            {name: '提款编号', key: 'serial_number'},
        ]
    ][a];
    return sheet({
        fakeKeys: ['type_id'],
        cls: 'foundPage',
        tabs: ['交易记录', '存款记录', '提款记录'],
        api,
        cols: col,
        tabChange: (v, q) => {
            aa(v);
            q();
        },
        params: params,
        filter(q) {
            return <div className={'r'}>
                <MyDatePicker
                    quickSelect={1}
                    changeTimeFn={dd}/>
                <Select
                    loading={ld && '正在加载'}
                    value={k[a]}
                    onChange={v=>{
                        const s= k.slice()
                        s[a]=v;
                        kk(s);
                    }}
                    items={categories}/>
                <div className={'btn'} onClick={q}>查询</div>
            </div>
        }
    })
})


