import React, {useState} from 'react';
import {widthDashSheet} from "../../components/sheet";
import {Link} from "react-router-dom";
import './w.scss'
import {query} from "../../api";
import {fmtErr, tip} from "../../utils";
import {Tip} from "../common/com";

export const WelfarePage = widthDashSheet((sheet, {
}) => {
    const  [ap,app] = useState({});
    const [skip,ss] = useState([]);
    const  get = id=>{
        app({...ap,[id]:1})
        const f = (e)=>{
           if(e)tip.alert(<Tip.Err>{fmtErr(e)}</Tip.Err>);
            const  a = {...ap}
            delete a[id];
            app(a);
            return 1;
        }
        query('applyActivity',{id},()=>{
           f();
           ss(skip.concat(id))
        },f)
    }
    return sheet({
        api: 'activeList',
        fixData:(d,ft)=>{
            const da = ft(d);
            return da?da.filter(({id})=>!skip.includes(id)):[]
        },
        params:{
            paginate:1
        },
        cls: 'welfarePage',
        tabs: ['福利优惠'],
        filter: q => <div className={'r'}>
            <p>温馨提示：如果申请优惠，系统将默认您已了解优惠详情及规则。</p>
            <Link to={'/promotion'}>
                更多优惠活动
            </Link>
        </div>,
        cols: [
            {
                name: '活动名称', key:'activity_name'
            },
            {
                name: '操作', cell({id}) {
                    const ld = ap[id];
                    return <div className={'opt'}>
                        <div className={'bn'}
                        onClick={ld?null:()=>get(id)}>{ld?'领取中':'领取'}</div>
                    </div>
                }
            },
        ]
    })
});
