import React, {useState, useMemo, useEffect} from "react";
import './i.scss';
import {change} from "../pwd";
import {widthStore} from "../../store";
import {useQuery} from "../../hooks";
import {Captcha} from "../captcha";
import {fmtErr, service, tip} from "../../utils";
import {globalStore, px} from "../../store/global";
import {CCode, VerifyPhone} from "../code";
import {query} from "../../api";
import {Tip} from "../../pages/common/com";
import {Tabs} from "../tabs";

const Ipt = ({
                 ctx = {},
                 name,
                 children,
             }) => {
    let type = 'text';
    const desc = '请' + {
        cap: '输入验证码',
        cap1: '输入验证码',
        usr: '输入用户名',
        tel: '输入手机号',
        pwd: '输入密码',
        pwd1: '确认密码'
    }[name] || '';
    const [value, vv] = useState(ctx[name] || '');
    const [er, ee] = useState('');
    const [pv, pp] = useState(ctx.pv||'86');
    useEffect(() => {
        ctx.pv = pv
        if (name === 'tel') {
            const ck = () => {
                if (ctx.tel) {
                    if (!VerifyPhone(ctx.tel, pv)) {
                        ee('手机号格式不正确')
                        ctx.telE = 1
                    } else {
                        ee('')
                        ctx.telE = 0
                    }
                }
            }
            const t = setInterval(ck, 1e3)
            ck()
            return () => clearInterval(t)
        }

    }, [ctx, pv])
    const check = v => {
        let e = '';
        switch (name) {
            case 'usr':
                if (!v) e = '请输入用户名';
                else {
                    if (v.length < 6) e = '用户名为6-16位数字或字母'
                }
                globalStore.capKey = v;
                break;
            case 'tel':
                if (!v) e = '请输入手机号';
                else {
                    if (!VerifyPhone(v, pv)) {
                        e = '手机号格式不正确'
                        ctx.telE = 1
                    } else {
                        ctx.telE = 0
                    }
                }
                break;
            case 'cap':
            case 'cap1':
                if (!v) e = '请输入验证码';
                else {
                    if (v.length < 4) e = '验证码格式不正确'
                }
                break;
            case 'pwd':
                if (!v) e = '请输入密码';
                else if (!/^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9])\1\1).{6,16}$/.test(v)) {
                    e = '密码由6-16位数字和字母组成，连续三位不能相同';
                }
                break;
            case 'pwd1':
                ctx.ck = () => {
                    const e = ctx.pwd1 ? check(ctx.pwd1) : '';
                    ee(e);
                }
                if (!v) e = '请确认密码';
                else {
                    if (v !== ctx.pwd) {
                        e = '两次密码不一致'
                    }
                }
                break;
        }
        return e;
    }
    if (/pwd/.test(name)) type = 'password';
    const wrp = v => {
        switch (name) {
            case 'usr':
                v = v.replace(/[^0-9a-z]/ig, "").substr(0, 16);
                break;
            case 'cap':
            case 'cap1':
                v = v.replace(/ /g, '').substr(0, 8)
                break;
            case 'tel':
                v = v.replace(/[^1][^\d]+/g, '').substr(0, 11);
                break;
        }

        const ps =  check(v);
        if(name!=='tel'){
            ctx[name] = ps ? '' : v;
        }else ctx.tel=v
        return v;
    }
    const wrpC = () => {
        const e = check(value);
        ee(e);
        if (name === 'pwd' && ctx.ck && ctx.pwd1) {
            ctx.ck();
        }
    }
    return <div className={'w-ipt ' + name.substr(0, 3) + (er ? ' er' : '')}>
        <div className={'pf'}>
            <i/>
            {name === 'tel' ? <p><CCode value={ctx.pv} change={v=>{
              pp(ctx.pv=v)
            }}/></p> : <s/>}
        </div>
        <input type={type}
               autoComplete="new-password"
               autofill="off"
               placeholder={desc}
               value={value}
               onBlur={wrpC}
               onChange={change(vv, wrp)}
        />
        {er ? <span>{er}</span> : null}
        {children}
    </div>
}

const Code = ({ctx, isReg}) => {
    const [u, uu] = useState(0);
    const [send, l0] = useQuery('sms');
    useEffect(() => {
        const t = setInterval(() => {
            uu(Math.random());
        }, 300);
        return () => clearInterval(t);
    }, [])

    const st = useMemo(() => {
        if (l0) return 1;
        if (px.t) return 2;
        return 0
    }, [u, px, l0]);

    const btn = ['发送验证码', '正在发送', px.t + '秒后重试', '重新发送验证码'][st];
    const {tel: phone,telE} = ctx;
    const ok = !px.t && !l0 && phone &&!telE;
    return <div
        onClick={() => {
            if (ok) {
                const o = {
                    phone: ctx.pv + '-' + phone,
                    key: window.localStorage.seed
                }
                if (isReg) o.is_signup = 1
                send(o, () => {
                    px.t = 60;
                    px.phone = ctx.pv + '-' + phone;
                })
            }
        }}
        className={'btn sco' + (ok ? '' : ' dis')}>
        {btn}
    </div>
}

/***
 * sign in and sign up
 * @param {number} type 0-login username 1-login phone 2-reg username 3- reg phone
 * @constructor
 */
export const SignWin = widthStore(({globalStore}, {location = {}, type = 0}) => {
    const {token, regCode, captchaCode, allow_register, need_check_phone} = globalStore;
    const isGov = +need_check_phone;
    const [login, loginLoading] = useQuery("login");
    const [reg, regLoading] = useQuery("reg");
    const [u, uu] = useState(Math.random);
    if (!allow_register && type>1) type = 0;
    useEffect(() => {
        const t = setInterval(() => {
            uu(Math.random())
        }, 100);
        return () => clearInterval(t);
    }, [])
    const sv = +localStorage.saveLg;
    const ctx = useMemo(() => ({
        usr: sv ? localStorage.usr || '' : '',
        tel: '',
        pv:'86',
        cap: '',
        pwd: sv ? localStorage.pwd || '' : '',
        cap1: '',
        pwd1: '',
    }), []);
    useEffect(() => {
        if (type < 2) {
            globalStore.capKey = type ? localStorage.seed : ctx.usr
        } else {
            const now = new Date()
            localStorage.seed = localStorage.seed || (Math.floor(
                Math.random() * 1e11 * now.getSeconds()
                + (now.getTime() - 1628672788625)
            )).toString(36)
            query('captcha', {
                useFor: window.localStorage.seed
            })
        }
    }, [ctx, type])
    const ok = useMemo(() => {
        const {usr, tel, cap, pwd, pwd1, cap1, telE} = ctx;
        switch (type) {
            case 0:
                return !loginLoading && usr && pwd && (!captchaCode || cap)
            case 1:
                return !loginLoading && tel && !telE && cap1 && (!captchaCode || cap)
            case 2:
                return !regLoading && usr && pwd && pwd === pwd1 &&
                    ((!regCode || cap) && (!isGov || (tel && cap1 && !telE)))
        }
    }, [ctx, u, type, loginLoading, captchaCode, regLoading, regCode, isGov])
    const title = ['登录您的个人账号', '登录您的个人账号', '创建一个新的个人账户', '手机注册更安全'][type]
    const title2 = ['立即享受精彩生活', '立即享受精彩生活', '寻找值得尝试的新乐趣', '寻找值得尝试的新乐趣'][type]
    if (token) {
        tip.popLogin(0);
    }

    if (!sv) {
        localStorage.removeItem('usr');
        localStorage.removeItem('pwd');
    }
    const [save, sS] = useState(sv);
    const cpm = useMemo(() => {
        const k = window.localStorage.seed;
        const v = [
            <Ipt key={0} name={'usr'} ctx={ctx}/>,//0
            <Ipt key={1} name={'tel'} ctx={ctx}><Code ctx={ctx} isReg={type === 2}/></Ipt>,//1
            <Ipt key={2} name={'pwd'} ctx={ctx}/>,//2
            <Ipt key={3} name={'pwd1'} ctx={ctx}/>,//3
            <Ipt key={4} name={'cap1'} ctx={ctx}/>,//4
            <Ipt key={5} name={'cap'} ctx={ctx}><Captcha/></Ipt>,//5
            <Ipt key={6} name={'cap'} ctx={ctx}><Captcha useFor={k}/></Ipt>,//6
        ];
        let vv = [];
        switch (type) {
            case 0: //user sign in
                vv = [0, 2]
                if (captchaCode) vv.push(5)
                break;
            case 1: //tel sign in
                vv = [1, 4]
                if (captchaCode) vv.push(5)
                break;
            case 2: //usr sign up
                vv = [0, 2, 3];
                if (isGov) {
                    vv.push(1, 4)
                }
                if (regCode) vv.push(6)
                break;
            default:
        }
        return vv.map(a => v[a]);
    }, [ctx, type, captchaCode, isGov, regCode])

    const btnCls = ok ? 'btn' : 'btn dis '

    const submit = useMemo(() => {
        const key = window.localStorage.seed;
        return ok ? async () => {
            const {usr, tel, cap, cap1, pwd, pwd1,pv} = ctx;
            if (type < 2) {
                const su = type ? {
                    username: pv + '-' + tel,
                    password: cap1,
                    key: key,
                    isPhone:type
                } : {
                    username: usr,
                    password: pwd,
                }
                if (captchaCode) {
                    su.captcha = type ? cap1 : cap;
                }
                login(su, !type ? () => {
                    if (save) {
                        localStorage.setItem('usr', usr)
                        localStorage.setItem('pwd', pwd)
                    }
                } : undefined);
            } else {
                let x;
                x = {
                    username: usr,
                    password: pwd,
                    password_confirmation: pwd1,
                };
                if (isGov) {
                    const key = window.localStorage.seed;
                    const goon = await new Promise((resolve) => {
                        query('smsCheck', {
                            code: cap1,
                            key,
                        }, function () {
                            resolve(1)
                        }, function (e) {
                            resolve(0)
                            tip.alert({text: <Tip.Err>{fmtErr(e, '手机验证失败')}</Tip.Err>});
                        })
                    });
                    if (goon) {
                        x.key = key
                        x.phone =  pv + '-' + tel
                    } else {
                        return
                    }
                }

                if (regCode) {
                    x.captcha = cap;
                }
                reg(x);
            }
        } : () => {
        };
    }, [ok, ctx, type, captchaCode, login, save, isGov, regCode])
    return <div className={'sign-box' + ([' s-usr', ' s-tel', isGov ? ' s-reg-f' : ' s-reg'][type])}>
        <div className={'ti'}>
            <i/>
            <span>{title}</span>
            <div>{title2}</div>
        </div>
        <div className={'ctx'}>
            {type<2?<Tabs act={type}
                          change={t=> tip.popLogin(t+1)}>{o=><div className={'tb'}>
                <div {...o(0)}>账号登录</div>
                <div {...o(1)}>手机快速登录</div>
                <div className={'ac _'+type}/>
            </div>}</Tabs> :null}
            {cpm}
        </div>
        <div className={'extra'}>
            {
                type > 1 ? <>
                    <div className={btnCls} onClick={submit}>{regLoading ? '正在注册..' : '完成注册'}</div>
                    {type === 3 ? <>
                        <a onClick={() => tip.popLogin(1)}>已有账号？<span>去登陆</span></a>
                    </> : null}
                </> : <>
                    {!type ? <div className={'pwd-opt'}>
                        <div>
                            <label
                                onClick={() => {
                                    const v = save ? 0 : 1;
                                    localStorage.setItem('saveLg', v);
                                    sS(v);
                                }}><i
                                className={'checkbox' + (save ? ' ck' : '')}
                            />记住密码</label>
                        </div>
                        <div className={'fgw'} onClick={() => service.open()}>忘记密码?</div>
                    </div> : null
                    }
                    <div className={btnCls} onClick={loginLoading ? null : submit}>
                        <span>{loginLoading ? '登录...' : '登录'}</span>
                    </div>
                    {type ? <>
                        <a onClick={() => service.open()}>手机无法收到验证码？<span>联系客服</span></a>
                        <p>提示：未注册手机验证后自动注册登录</p>
                    </>:null}
                </>
            }
        </div>
        <div className={'ft-ad'}/>
        <div className={'sign-ft'}>
            <i className={'i0'}/>
            <i className={'i1'}/>
            <i className={'i2'}/>
        </div>
        <div className={'ss'}/>
        <div className={'hd'}>
            <div className={'clo'} onClick={() => {
                tip.popLogin(0);
            }}>
                <s/>
            </div>
        </div>
    </div>
})