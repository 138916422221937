export  const sscqiansanzuxuanhezhi ={
    balls:[
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
    ],
    num:[
        [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26]
    ],
    views:[
        [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26]
    ],
    titles: [
        '组选和值'
    ],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                   str += str?'|':''
                   str += num[i][j];
                }
            }
        }
        return str;
    },
    getNum:(balls)=>{
        let num = 0,bets = [1,2,2,4,5,6,8,10,11,13,14,14,15];
        for(let i=0,len=balls[0].length;i<len;i++){
            if(balls[0][i]>-1){
                num += i>12?bets[25-i]:bets[i];
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                    str += str?'|':''
                    str += views[i][j];
                }
            }
        }
        return str;
    }
}
