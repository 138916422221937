import React, {useEffect, useState, useMemo} from 'react';
import './s.scss';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {panel, Tabs} from "../../components/tabs";
import {Select} from "../../components/select/select";
import {change} from "../../components/pwd";
import {tip} from "../../utils";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {query} from "../../api";

const A = widthDashBoard((props, {
    cityStore: {province, cities},
    myBankStore: {
        banks,
        allBanks,
        usdts, alipay,
        bankMap
    },
    globalStore: {
        is_enable_alipay,
        has_fund_password, real_name
    }
}) => {
    const [getBanks] = useQuery('getBanks');
    const [getUsdt] = useQuery('getUsdt');
    const [qu, loading] = useQuery('myBankCard');
    const [bd, sbd] = useState(0);
    useEffect(() => {
        getBanks();
        getUsdt();
        qu()
    }, [])
    const [e0, ee0] = useState('');
    const [ta, tta] = useState(0);
    const [e1, ee1] = useState('');
    const [e2, ee2] = useState('');
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState('');
    const [v3, vv3] = useState('');
    const [v4, vv4] = useState('');
    const [v5, vv5] = useState(0);
    const [v6, vv6] = useState(0);
    const items = useMemo(() => {
        if (ta === 2) return banks.filter(a=>a.bank_id===43)
        if (ta) return (usdts || []).map(({address, id}) => {
            return {
                bank: 'usdt',
                id: id,
                account: address,
                account_name: 'TRC20'
            }
        })
        return banks.filter(a=>a.bank_id!==43)
    }, [banks, usdts, ta])
    const needAddr = /农村信用社/.test((bankMap[v1] || {}).name);
    const noCheck = v => {
        ee0([
            /^\d{16,19}$/g,
            /^T\w{30,63}$/g,
            /^1\d{10}$|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
        ][ta].test(v) ? '' : [
            '银行卡卡号由16位或19位数字组成',
            'TRC20类型钱包，地址T开头',
            '支付宝手机号或者邮箱'
        ][ta])
    }
    const pwdCheck = v => {
        ee1(/^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9])\1\1).{6,16}$/.test(v) ? '' : '资金密码6-16位字符，必须包含字母和数字，不允许连续三位相同，不能和登录密码相同')
        if (!has_fund_password) pwd2Check(0, v);
    }
    const pwd2Check = (v, x) => {
        ee2(((v && (!v3 || v === v3)) || (x && (!v4 || x === v4))) ? '' : '两次密码不一致')
    }
    const ck = (ch, fix, check, e, cls = '') => {
        const fn = change(v => {
            ch(fix ? fix(v) : v);
            check(v)
        })
        return {
            className: e ? 'err ' + cls : cls,
            onChange: fn,
            onPaste: fn,
            onBlur: change(v => check(v))
        }
    }
    const verify = (cb) => {
        const nx = () => {
            const o = {
                account_name: v0 || real_name,
                fund_password: v3
            }
            query('verifyBank', o).subscribe({
                next() {
                    // if (!ta) {
                    //     query('verifyBank0', {
                    //         name: v0 || real_name,
                    //         bankcard: v2
                    //     }, function () {
                    //         bindBank(cb)
                    //     }, function (b) {
                    //         tip.alert('请输入正确的银行卡信息')
                    //         sbd(0);
                    //     });
                    // } else
                        bindBank(cb)
                },
                error() {
                    sbd(0);
                }
            });
            return 1;
        }
        if (!has_fund_password) {
            query('resetFound', {
                password_old: v3,
                password_new: v3
            }, nx, () => {
                sbd(0);
            })
        } else nx()
    };
    const bindBank = (cb) => {
        const o = ta === 1 ? {
            address: v2
        } : {
            bank_id: ta === 2 ? 43 : v1,
            bank: ta === 2 ? 'alipay' : (bankMap[v1] || {}).name,
            account_name: v0 || real_name,
            account: v2,
            account_confirmation: v2
        };
        if (needAddr) {
            const nm = (aa, id) => ((aa || []).find(a => a.id === id) || {}).name || '';
            const cn = nm(cities(v5), v6);
            const pn = nm(province, v5);
            Object.assign(o, {
                city: cn,
                city_id: v6,
                province_id: v5,
                province: pn,
                branch_address: pn + cn + o.bank,
            })
        }
        const next = () => query(['bindBank', 'addUsdt', 'bindBank'][ta], o).subscribe({
            next: () => {
                tip.alert({text: ['银行卡', 'USDT钱包地址', '支付宝'][ta] + '绑定成功！'});
                sbd(0);
                cb && cb();
            },
            error: () => {
                sbd(0)
            },
        });
        if (!real_name) {
            query('setUserInfo', {real_name: v0}, () => {
                next();
                return 1
            }, () => 1)
        } else next()

    };
    useEffect(() => {
        vv2('')
        vv1('')
        ee0('')
    }, [ta])
    const bk = <div className={'are'}>
        <div className={'ico'}>
            <i className={'a'}/>
            <span>绑定{['银行卡', 'USDT钱包', '支付宝'][ta]}</span>
        </div>
        <div className={'inf'}>
            {ta === 1 ? null : <div className={'ipt'}>
                <label>姓名</label>
                {real_name ? <div>{real_name}</div> : <>
                    <input value={real_name || v0} onChange={change(vv0,
                        v => v.replace(/\s|\d/g, ''))} readOnly={!!real_name}/>
                    <div className={'d'}>请填写真实姓名，充值时的银行卡姓名需与绑定姓名一致。</div>
                </>
                }
            </div>}
            <div className={'r'}>
                {ta ? null : <div className={'ipt'}>
                    <label>银行</label>
                    <Select className={'sel'}
                            value={v1}
                            placeholder={"选择银行"}
                            onChange={vv1}
                            items={allBanks.map(({id, name}) => [id, name])}
                    />
                </div>}
                <div className={'ipt'}>
                    <label>{['卡号', '钱包地址', '支付宝'][ta]}</label>
                    <input value={v2}
                           placeholder={['', '钱包类型TRC20', '支付宝手机号或者邮箱'][ta]}
                           {...ck(vv2, v => {
                               return ta === 1 ? v
                                       .replace(/^t.*/, 'T')
                                       .replace(/^[^T].*/, '')
                                       .replace(/[^0-9a-zA-Z]/g, '')
                                   : ta === 2 ?
                                       v.replace(/[^0-9a-z_\-.@]/i, '') :
                                       v.replace(/[^0-9]/g, '')
                           }, noCheck, e0, 'c')}
                    />
                    {e0 && <span>{e0}</span>}
                </div>
            </div>
            <div className={'r'}>
                {needAddr ? <div className={'ipt pv'}>
                    <label>支行</label>
                    <Select className={'sel'}
                            value={v5}
                            placeholder={"请选择"}
                            onChange={vv5}
                            items={province.map(({id, name}) => [id, name])}
                    />
                    <Select className={'sel'}
                            value={v6}
                            placeholder={"请选择"}
                            onChange={vv6}
                            items={cities(v5).map(({id, name}) => [id, name])}
                    />
                </div> : null}
            </div>
        </div>
    </div>
    const pw = <div className={'are'}>
        <div className={'ico'}>
            <i className={'b'}/>
            <span>{has_fund_password ? '资金密码' : '设定资金密码'}</span>
        </div>
        <div className={'inf'}>
            <div className={'r'}>
                <div className={'ipt'}>
                    <label>资金密码</label>
                    <input value={v3} type={'password'}
                           {...ck(vv3, 0, pwdCheck, e1)}
                    />
                    {has_fund_password ? '' : <span><s/>为了您的安全，首次绑卡须设定资金密码。</span>}
                </div>
                {has_fund_password ? null : <div className={'ipt'}>
                    <label>确认密码</label>
                    <input value={v4}
                           {...ck(vv4, 0, pwd2Check, e2)}
                           type={'password'}/>
                </div>}
            </div>
            <p className={(e1 || e2) ? 'er' : ''}>{e1 || e2 || '资金密码须6-16位字符，包含字母和数字，不允许连续三位相同'}</p>
        </div>
    </div>
    const l = items.length;
    const dis = e0 || e1 || e2 || (ta !== 1 && !(real_name || v0)) || (!ta && !v1) || !v2 || !v3 || (!has_fund_password && (!v4));
    if (dis) {
        console.log(e0, e1, e2, (!ta && !(real_name , v0)), !v1, !v2, !v3, (!has_fund_password && (!v4)));
    }
    return <div className={'bkPage x-sheet'}>
        <div className={'x-st-h'}>
            <div className={'x-st-ti'}>
                <i/>
                <Tabs change={tta} act={ta}>{o => <div className={'tbs'}>
                    <div {...o(0)}>银行卡提款</div>
                    {+is_enable_alipay?<div {...o(2)}>支付宝提款</div>:null}
                    <div {...o(1)}>USDT提款</div>
                </div>}</Tabs>
            </div>
            <p className={'tip'}>
                {
                    [
                        '为了资金安全考虑，第一次绑定的姓名无法再次修改，再次修改请联系客服，最多绑定5张银行卡。',
                        '最多绑定5个USDT钱包地址。',
                        '为了资金安全考虑，第一次绑定的姓名无法再次修改，再次修改请联系客服，最多可绑定3个支付宝账号。'
                    ][ta]
                }
            </p>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                {panel(l < (ta === 2 ? 3 : 5), <>
                    {bk}
                    {pw}
                    <div className={'btn' + (dis ? ' dis' : '')}
                         onClick={dis ? null : () => {
                             sbd(1);
                             verify();
                         }}
                    >{bd ? '提交中...' : '立即提交'}</div>
                </>)}
                {panel(l, <div className={'ls'}>
                    {items.map(({bank, id, account, account_name}) => <div
                        key={id}
                        className={'fu o' + (ta === 2 ? ' ali' : ta ? ' ut' : '')}>
                        <i style={{
                            backgroundImage: `url(/banks/${bank}.png)`
                        }}/>
                        <h1>{account}</h1>
                        <span>{account_name}</span>
                    </div>)}
                </div>)}
            </div>
        </div>
        <Loading act={loading}>正在获取银行卡信息</Loading>
    </div>
});
export const BkManage = props => <A {...props} cls={'xt'}/>
