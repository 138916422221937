import React from 'react';
import {Home} from "../pages/home";
import {Bet} from "../pages/bet/Bet";
import {Trend} from "../pages/trend/trend";
import {Vip} from "../pages/dashboard/vip";
import {HisDetail} from "../pages/bet/recDetail";
import {TrcDetail} from "../pages/bet/trcDetail";
import {SportPage} from "../pages/sport";
import {RealPage} from "../pages/real";
import {PVPPage} from "../pages/pvp";
import {ElectPage} from "../pages/elec";
import {LottoPage} from "../pages/lotto";
import {SlotPage} from "../pages/slot";
import {PromotionPage} from "../pages/promotion";
import {VIPPage} from "../pages/vip";
import {FoundRec} from "../pages/funds";
import {AccountPage} from "../pages/account";
import {TeamBetPage} from "../pages/teamBet";
import {ProfitPage} from "../pages/profit";
import {FdPage} from "../pages/funds/fd";
import {SubPage} from "../pages/sub/mag";
import {DivPage} from "../pages/sub/div2";
import {MyIncPage} from "../pages/income/inc";
import {BkManage} from "../pages/bank";
import {CenterPage} from "../pages/center";
import {MgPage} from "../pages/msg/msg";
import {WelfarePage} from "../pages/welfare";
import {Fish} from "../pages/fish";
import {TestPage} from "../pages/pagetest";
import {AppPage} from "../pages/app";
import {Unread} from "../pages/common/com";
import {MsgPage} from "../pages/msg";
import {ActPage0} from "../pages/act/0";
import {TcgLottoPage} from "../pages/tcg";
import {Reg} from "../pages/reg";
import {Analysis} from "../pages/analysis";
// import {JoinPage} from "../pages/join";

const rts = {
    'h5/reg':['',Reg],
    'quick': ['', TestPage],
    'act/0': ['', ActPage0],
    // 'join':['',JoinPage],
    '': ['首页', {
        exact: 1,
        component: Home
    }, 0],
    'bet/:id/:type': ['', {
        exact: 1,
        component: Bet
    }, 1],
    'bet/:id/:type/:way_id': ['', Bet, 1],
    'sport': ['体育赛事', SportPage],
    'live': ['真人娱乐', RealPage],
    'app': ['', AppPage],
    'pvp': ['棋牌游戏', PVPPage],
    'vip': ['', VIPPage],
    'fish': ['', Fish],
    'lottery': ['', {
        exact: 1,
        component: LottoPage
    }],
    'lottery/bt': ['', LottoPage],
    'lottery/tcg': ['', TcgLottoPage, 1],
    'rng': ['', {
        exact: 1,
        component: SlotPage
    }],
    'rng/:target': ['', SlotPage],
    'elect': ['', ElectPage],
    'trend/:id': ['走势', Trend, 1],
    'hisLotty': ['投注记录', TeamBetPage, 1],
    'promotion': ['', {
        component: PromotionPage,
        exact: 1
    }],
    'foundHis': ['帐变记录', FoundRec, 1],
    'agent/analysis': ['数据概览', Analysis, 1],
    'agent/profit': ['团队盈亏', ProfitPage, 1],
    'agent/myInc': ['我的收入', MyIncPage, 1, 0],
    'personal': ['个人中心', CenterPage, 1],
    'agent/subManage': ['下级管理', SubPage, 1],
    'agent/Contracts': ['分红管理', DivPage, 1],
    'agent/openAccount': ['开户', AccountPage, 1],
    'agent/foundDetail': ['账变明细', FdPage, 1],
    'bank': ['提款管理', BkManage, 1],
    'welfare': ['福利优惠', WelfarePage, 1],
    'msg': [<>站内信<Unread/></>, {
        exact: 1,
        component: MgPage
    }, 1],
    'agent/teamBet': ['团队投注', TeamBetPage, 1],
    'msg/:id': ['', MgPage, 1],
    'agent/msg': ['站内信', MsgPage, 1],
    'myVip': ['VIP详情', Vip, 1],
    'gameDetail': ['游戏详情', HisDetail, 1],
    'traceDetail': ['追号详情', TrcDetail, 1],
};
const routeMap = {};
const routes = {};
routeMap.getName = link => {
    const o = routeMap[link];
    if (o) return o;
    const ls = Object.keys(routeMap).filter(k => k.indexOf(':') > -1);
    let name = '';
    ls.find(c => {
        const keys = c.match(/:\w+/g).map(c => c.substr(1));
        const r = new RegExp(c.replace(/:\w+/g, '(\\w+)'), 'gi').exec(link);
        if (keys.length && r) {
            const n = routeMap[c];
            const params = {};
            keys.forEach((k, i) => params[k] = r[i + 1]);
            if (typeof n === 'function') {
                return name = n(params)
            }
        }
        return false
    });
    return name || link;
};
const authRoutes = [];
Object.keys(rts).forEach(o => {
    const c = rts[o];
    const au = c[2];
    routeMap[o] = c[0];
    routes[o] = c[1];
    if (au) authRoutes.push([o,c[3]])
});

export {routes, routeMap, authRoutes};
