export const tvCfg = {
    domain:'https://api.sportxxxwo8.com'
}
export const base = `${tvCfg.domain}/yewu11/`;

export const api = {
    menu: ['v2/w/menu/init', {
        after: d => {
            if (d) {
                let a = d.find(a => /体育/.test(a.menuName));
                if (a) a = a.subList;
                if (a && a.length) {
                    return (a[0] || {}).subList || []
                }
            }

            return []
        }
    }],
    mcs: ['v1/w/structureMatchBaseInfoByMids', {
        m: 1,
        t:1000,
        before([b, a]) {
            return {
                euid: b,
                mids: a
            }
        },
        after(d) {
            return  d&&d.data||[];
        }
    }],
    video: ['v1/w/videoAnimationUrl', {
        m: 1,
        before(a) {
            return {"mid": a, "device": "PC", "type": "Animation"}
        },
        after(d) {
            return d && d.animationUrl || ''
        }
    }],
    player: ['v1/w/videoReferUrl', {
        t: 1e9,
        m: 1,
        before() {
            return {
                device: 'PC',
            }
        },
        after(a) {
            return a && a.referUrl
        }
    }],
    ways: ['v1/w/matchDetail/getMatchOddsInfo?'],
    matchs: ['v2/w/newhandMatches', {
        m: 1,
        t:1e3,
        after(d, t) {
            return {
                t: t,
                d: (d && d.data || []).filter((c) => {
                    const {mms, mvs} = c;
                    return (!/电竞/.test(JSON.stringify(c || ''))) && (+mms === 2 || +mvs > 0)
                })
            }
        },
        before(a) {
            return {
                "euid": a,
                "sort": 1,
                "cpn": 1,
                "cps": 50,
                "pids": ""
            }
        }
    }],
}