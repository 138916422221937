import React from 'react';
import {Banner} from "./Banner";
import {Link} from "react-router-dom";
import {AppCode, BannerAd, Foot, Foot2, HeadA, HeadB, VideoBox, X} from "../common/com";
import './home.scss';
import {PopVideo} from "../../components/popvideo";
import {SportTV} from "../../components/sportTv/tv";
import {PDFDwn} from "../../components/docDonwload";
export const Home = () => {
    return <div className={'full-page home'}>
        <HeadA/>
        <HeadB/>
        <Banner mini={true}/>
        <BannerAd/>
        <div className={'bg-a full'}>
            <X>
                <SportTV/>
            </X>
            <X cls={'c2'}>
                <i>
                    <b className={'t0'}/>
                    <b className={'t1'}/>
                    <b className={'t2'}/>
                    <b className={'t3'}/>
                    <b className={'t4'}/>
                </i>
                <div className={'r'}>
                    <h1>下载APP,随时随地,想玩就玩！</h1>
                    <h2>用心雕琢,实力成就不凡,顶尖开发团队,倾力打造！</h2>
                    <div className={'dow'}>
                        <div className={'c'}>
                            <h3>扫码下载APP</h3>
                            <AppCode/>
                            <p>
                                <s className={'ios'}/>
                                <s className={'ard'}/>
                            </p>
                            <p>苹果用户扫码后 选择 safari 浏览器打开</p>
                        </div>
                        <div className={'c'}>
                            <h3>无需下载直接访问</h3>
                            <div className={'we'}/>
                            <span>{window.location.protocol+'//'+window.location.hostname}</span>
                            <p>打开手机浏览器输入网址即可访问</p>
                        </div>
                    </div>
                </div>
            </X>
            <X cls={'c1'}>
                <Link className={'li'} to={'/live'}/>
                <Link className={'sp'} to={'/sport'}/>
                <Link className={'lo'} to={'/lottery'}/>
                <Link className={'el'} to={'/elect'}/>
                <Link className={'fi'} to={'/pvp'}/>
                <Link className={'sl'} to={'/rng'}/>
            </X>
            <div className={'hm-f'}>
                <h1>品牌赞助</h1>
                <h2>Sponsorship</h2>
                <div className={'bg'}/>
                <div className={'bg1'}/>
                <X>
                    <VideoBox cls={'a'} name={'who am i'}  src={'https://mp4.orpxf.xyz/BTinfo.mp4'}/>
                    <VideoBox cls={'b'} name={'英格兰足球总会合作伙伴'} src={'https://mp4.orpxf.xyz/BTplay.mp4'}/>
                </X>
            </div>
        </div>
        <PopVideo/>
        <Foot2/>
        <Foot/>
        {/*<PDFDwn/>*/}
    </div>;
};
