import React, {useEffect, useState} from 'react';
import {Rate} from "./slide";
import {COMMISSION_TYPES} from "../../api/enum";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import './r.scss';

export const UserRate = ({
                             large,
                             children,
                             userId,
                             onChange
                         }) => {
    const isSelf = !userId;
    const [[sport = 0, live = 0, rng = 0, pvp = 0, fish = 0], cc] = useState([]);
    const [[m2 = 0, m3 = 0, m4 = 0, m5 = 0, m6 = 0], mm] = useState([]);
    const [query, loading, data] = useQuery('userSetting');
    const [q1, l1, d1] = useQuery('userSetting');
    const [c2, setC2] = useState(0); // 体育
    const [c3, setC3] = useState(0); // 真人
    const [c4, setC4] = useState(0); // 老虎机
    const [c5, setC5] = useState(0); // 棋牌
    const [c6, setC6] = useState(0); // 捕鱼
    const [prize, setPrize] = useState(0); // 奖金组
    const reload=()=>{
        if(userId){
            q1({user_id: userId})
        }
    }
    useEffect(() => {
        query();
        if (userId) q1({user_id: userId});
    }, [userId]);
    useEffect(() => {
        if (data) {
            const {commission_rate = [], prize_group} = data;
            mm(getRate(commission_rate));
            if (!userId) setPrize(prize_group);
        }
    }, [data, userId])
    useEffect(() => {
        if (d1) {
            const {commission_rate = [], prize_group} = d1;
            cc(getRate(commission_rate))
            if (userId) setPrize(prize_group);
        }
    }, [d1, userId]);

    const {min_bet_prize_group, max_bet_prize_group, prize_group: myPrize} = data || {};
    const {min_bet_prize_group: mi0 = 0, max_bet_prize_group: ma0, prize_group: userPrize = 1900} = d1 || {};
    const min = Math.max(+min_bet_prize_group, +userPrize, +mi0);
    const max = Math.min(+max_bet_prize_group, userId ? +ma0 : +myPrize, +myPrize) || 1900;
    const getRate = commissions => {
        let m2 = 0, m3 = 0, m4 = 0, m5 = 0, m6 = 0;
        commissions.forEach(({type_id, commission_rate}) => {
            const r = commission_rate;
            switch (type_id) {
                case  COMMISSION_TYPES.sport:
                    m2 = r;
                    break;
                case  COMMISSION_TYPES.live:
                    m3 = r;
                    break;
                case  COMMISSION_TYPES.rng:
                    m4 = r;
                    break;
                case  COMMISSION_TYPES.pvp:
                    m5 = r;
                    break;
                case  COMMISSION_TYPES.fish:
                    m6 = r;
                    break;
                default:
                    return;
            }
        });
        return [m2, m3, m4, m5, m6]
    }
    useEffect(() => {
        if (userId) {
            setC2(sport);
            setC3(live);
            setC4(rng);
            setC5(pvp);
            setC6(fish);
        } else {
            setC2(m2);
            setC3(m3);
            setC4(m4);
            setC5(m5);
            setC6(m6);
        }
    }, [m2, m3, m4, m5, m6, userId, sport, live, rng, pvp, fish])
    const getLottoRate = () => {
        return ((myPrize - prize) / 20 || 0).toFixed(1);
    }
    useEffect(() => {
        const o = {
            prize_group: prize || '',
            commission_rate_2: c2,
            commission_rate_3: c3,
            commission_rate_4: c4,
            commission_rate_5: c5,
            commission_rate_6: c6
        };
        const v = getLottoRate();
        Object.defineProperty(o, 'commission_rate_1', {
            get: () => v
        })
        if(userId)o.reload=reload;
        onChange(o);
    }, [prize, c2, c3, c4, c5, c6]);
    const fx = a=>(+a).toFixed(1)
    return <div className={'rate-cfg'}>
        <Rate start={0} large={large} n0={'体育/电竞'} n1={'SPORTS'} cls={'r0'} v={c2} change={setC2} max={m2} min={sport} step={0.1}>
            <span>{fx(c2)}</span>当前返点
        </Rate>
        <Rate start={0} large={large} n0={'真人'} n1={'LIVE CASINO'} cls={'r1'} v={c3} change={setC3} max={m3} min={live} step={0.1}
              color={'#fb2554'}>
            <span>{fx(c3)}</span>当前返点
        </Rate>
        <Rate start={0} large={large} n0={'电子'} n1={'SLOT'} cls={'r2'} v={c4} change={setC4} max={m4} step={0.1} min={rng}>
            <span>{fx(c4)}</span>当前返点
        </Rate>
        <Rate start={0} large={large} n0={'棋牌'} n1={'CHESS'} cls={'r3'} v={c5} change={setC5} max={m5} step={0.1} min={pvp}
              color={'#f79b3f'}>
            <span>{fx(c5)}</span>当前返点
        </Rate>
        <Rate start={0} large={large} n0={'捕鱼'} n1={'FISHING'} cls={'r4'} v={c6} change={v => {
            setC6(v)
        }} max={m6} step={0.1} min={fish}>
            <span>{fx(c6)}</span> 当前返点
        </Rate>
        <Rate start={+min_bet_prize_group} large={large} n0={'彩票'} n1={'LOTTERY'} cls={'r5'} v={prize} change={setPrize} step={2} max={max} min={min}
              color={'#25d61f'}>
            <span> {getLottoRate()}</span><span>{prize}</span>当前返点
            / 奖金组
        </Rate>
        {children}
        <Loading act={loading || l1}>正在获取信息...</Loading>
    </div>
}
