import React, {useEffect, useMemo, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import './a.scss'
import {panel, Tabs} from "../../components/tabs";
import {change, Pwd} from "../../components/pwd";
import {useQuery} from "../../hooks";
import {Portals} from "../../components/portals";
import {Table} from "../../components/table/table";
import {Copy} from "../../components/copy";
import {Loading} from "../../components/loading";
import {Paging} from "../../components/paging/Paging";
import {UserRate} from "./rate";
import {Dividend} from "../sub/div";
import {fmtErr, randomStr, tip} from "../../utils";
import {seqQuery} from "../../api";
import {Tip} from "../common/com";
import {Link} from "react-router-dom";


const Detail = ({id, close}) => {
    const [q, ld, d] = useQuery('regLinkUser');
    const [b, bb] = useState(0);
    useEffect(() => {
        if (id) q({register_link_id: id});
        const t = setTimeout(() => bb(id), 350);
        return () => clearTimeout(t);
    }, [id]);
    const da = useMemo(() => {
        if (d && Array.isArray(d.data)) return d.data;
        return []
    }, [d])
    return <div className={'lk-dt-msk' + (id ? ' a' : '') + (b ? ' b' : '')} onClick={close}>
        <div className={'lk-pm'} onClick={e => e.stopPropagation()}>
            <div className={'lt'}><i/> 用户列表</div>
            <div className={'lks'}>
                <div className={'lss'}>
                    {da.map(({username, created_at}, i) => <div
                            key={i}
                            className={'po'}>
                            <span>{username}</span>
                            <span>{created_at}</span>
                        </div>
                    )}
                </div>
                <Loading act={ld}>正在加载</Loading>
            </div>
            <div onClick={close} className={'btn'}>确认</div>
        </div>
    </div>
};

const cr = cR => <>
    <div className={'r'}>
        <div><label>体育/电竞:</label><span>{cR.commission_rate_2}%</span></div>
        <div><label>真人:</label><span>{cR.commission_rate_3}%</span></div>
    </div>
    <div className={'r'}>
        <div><label>电子:</label><span>{cR.commission_rate_4}%</span></div>
        <div><label>棋牌:</label><span>{cR.commission_rate_5}%</span></div>
    </div>
    <div className={'r'}>
        <div><label>彩票:</label><span>{cR.commission_rate_1}% / {cR.prize_group}</span></div>
        <div><label>捕鱼:</label><span>{cR.commission_rate_6}%</span></div>
    </div>
</>

const A = widthDashBoard((props, {
    globalStore: {user_level}
}) => {
    const [query, , data] = useQuery('userSetting');
    const [rnd, sRnd] = useState(0);
    const [dk, sdk] = useState(0);

    const defaultPwd = useMemo(() => {
        // return randomStr()
        return 'bt1234'
    }, [rnd])
    useEffect(() => {
        query()
    }, []);
    const {
        daily_salary, bonus_rate,
    } = data || {};
    const [createA,] = useQuery('createAccount');
    const [createB,] = useQuery('createLink');
    const [a0, aa0] = useState('');
    const [ld, sLd] = useState('');
    const [b0, bb0] = useState('');
    const [t, tt] = useState(0);
    const [linkId, lk] = useState(0);
    const [c, cc] = useState(0);
    const [v0, vv0] = useState('');
    const [e0, ee0] = useState('');
    const [e1, ee1] = useState('');
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState('');
    const [cR, setCR] = useState({});
    const [di, setDi] = useState({});
    const [die, dDie] = useState(0);
    const [r0, setR0] = useState(0);

    const [cPage, ccp] = useState(1);
    const [reg, regLinkLoading, result = {}] = useQuery('getRegLinks');
    const [delLk] = useQuery('delLink');
    const {data: rs = [], totalPage = 1} = result || {};
    const [dded, ddd] = useState({});
    const rows = rs.filter(({id}) => !dded[id]);
    const sho = () => {
        if (!t) {
            if (!v1) return ee0('用户名不能为空');
        }
        if (die || e0) return;
        let bTx = '';
        const da = di && di.bonus_rate;
        if (da && !t) {
            da.forEach((o, i) => {
                const {deficit, rate, active_number} = o;
                bTx += `${i + 1}级：亏损 ${deficit} ， 活跃人数 ${active_number}，比例 ${rate}`
            })
            if (bTx) bTx = '分红设定\n' + bTx;
        }
        let cpText = `
        开户类型: ${t ? '链接' :  c ? '代理' : '玩家'},
        ${t ? '' : '用户名/密码: ' + v1 + ' / ' + (v2 || defaultPwd)}
        返点设置:
        体育: ${cR.commission_rate_2}%,
        真人: ${cR.commission_rate_3}%,
        电子: ${cR.commission_rate_4}%,
        棋牌: ${cR.commission_rate_5}%,
        彩票: ${cR.commission_rate_1} / ${cR.prize_group},
        捕鱼: ${cR.commission_rate_6}%
        ` + bTx;
        tip.alert({
            onClose(ok) {
                if (ok) submit()
            },
            no: true,
            //copyText, name = '复 制', done = '已复制'
            yes: t ? '确认' : <Copy copyText={cpText} name={'确认并复制'}/>,
            text: <div className={'s-inf'}>
                <p>开户类型:<span>{t ? '链接' : c ? '代理' : '玩家'}</span>{panel(!t, <>用户名:<span>{v1}</span></>)}
                </p>
                {panel(t, <p>该链接的的具体信息如下，是否立即生成链接？</p>)}
                {cr(cR)}
                {panel(bTx, <Table
                    row={[
                        {key: 'deficit', name: '亏损'},
                        {key: 'active_number', name: '活跃人数'},
                        {key: 'rate', name: '比例'},
                    ]}
                    data={da}
                />)}
            </div>
        })
    };
    useEffect(() => {
        const t = setTimeout(() => bb0(a0 ? 'b' : ''), 301);
        return () => clearTimeout(t);
    }, [a0]);

    useEffect(() => {
        if (!regLinkLoading) ddd({})
    }, [regLinkLoading, result]);

    const load = () => {
        if (t === 2) {
            const o = {};
            reg(o)
        }
    }
    useEffect(() => {
        ccp(1)
    }, [t]);
    useEffect(load, [cPage, t])
    const del = id => {
        if (!id) return;
        ddd({...dded, [id]: 0})
        delLk({id}, () => {
                ddd({...dded, [id]: 1})
            },
            () => {
                delete dded[id];
                ddd({...dded});
            }
        );
    };
    const clean = () => {
        sRnd(Math.random());
        sdk(Math.random());
        vv1('');
        vv2('');
    }
    const submit = () => {
        const o = {
            is_agent:  c,
            ...cR,
        };
        if (!t) {
            o.password = v2 || defaultPwd;
            o.username = v1.replace(/^\s+|\s+$/g, '').toLowerCase();
        }
        if (t) {
            o.setDaySalary = r0;
        }
        sLd('正在提交...');
        [createA, createB][t](o, (d) => {
            aa0('');
            sLd('');
            if (!t && di) {
                const {user_id} = d;
                if (di && o.is_agent) {
                    const l = (di.bonus_rate || []).length;
                    if (l) return seqQuery({
                        process({failed, finished, finish, result}) {
                            sLd(`正在创建分红规则(${finished}/${l})`)
                            if (failed || finish) sLd('');
                            if (failed) return tip.alert(<Tip.Err>
                                <p>开户成功，但用户{o.username}的分红规则{finished}创建失败,
                                    您可以在<Link to={'/agent/Contracts'}>分红管理</Link>中配置。</p>
                                <span>{fmtErr(result)}</span>
                            </Tip.Err>)
                            if (finish) {
                                tip.alert(<Tip.Ok>开户成功!</Tip.Ok>)
                                clean();
                            }
                        }
                    })(
                        ['addBonus', (di.bonus_rate || []).map(d => ({...d, user_id}))],
                    );
                }
            }
            if (!t) {
                tip.alert(<Tip.Ok>开户成功!</Tip.Ok>)
                clean();
            }
        }, er => {
            sLd('');
            tip.alert(<Tip.Err>{fmtErr(er)}</Tip.Err>);
        });
    };
    const ipt = (cls = '', p, v, vv, i, ru, rex, er, eer) => {
        const f = () => {
            eer(0);
            vv0(ru);
        };
        const b = () => {
            vv0('');
            if (!i && !v) return eer(p);
            if (v && !rex.test(v)) return eer(ru);
            eer(0)
        };
        return <div className={'ipt-a' + (er ? ' err' : '')}>
            {er ? <p><b>X</b>{er}</p> : ''}
            <label> <i className={cls}/></label>
            {i ?
                <>
                    <Pwd onChange={vv} value={v} placeholder={p} blur={b} focus={f}/>
                    {/*{v ? null : <i className={'ld'} onClick={() => sRnd(Math.random())}/>}*/}
                </> :
                <input autoComplete={"off"} onChange={change(vv)} value={v} type={'text'} placeholder={p} onFocus={f}
                       onBlur={b}/>}
        </div>;
    };

    const uType = <div className={'c2'}>
        <Tabs change={cc} act={c}>
            {o => <div className={'c21'}>
                <div {...o(0)}>{'玩家'}</div>
                <div {...o(1)} >{'代理'}</div>
            </div>}
        </Tabs>
    </div>

    const share = <div className={'shr'}>
        <div className={'c0' + (t === 1 ? ' cr' : '')}>
            {
                panel(t < 2,
                    <div className={'c1'}>
                        {uType}
                        {panel(t === 0, <div className={'c3'}>
                            {ipt('usr', '请输入用户名', v1, vv1, 0,
                                '用户名须6-16位字符，可使用字母或数字', /^[0-9a-zA-Z]{6,16}$/gi, e0, ee0)}
                            {ipt('pwd', '默认密码 ' + defaultPwd, v2, vv2, 1,
                                '密码须6-16位字母数字组合且连续三位不能相同', /^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9]{1})\1\1).{6,16}$/g, e1, ee1)}
                        </div>)}
                    </div>
                )
            }
            <UserRate onChange={setCR} large={t === 1}>
                {panel(!t && daily_salary,
                    <div className={'mo'}>
                        <label>彩票</label>
                        <div>
                            <label>日工资设定</label>
                            <input placeholder={'提示：您当前的日工资为' + daily_salary} value={r0}
                                   onChange={change(v => setR0(Math.min(v, daily_salary)))}/>
                        </div>
                    </div>
                )}
            </UserRate>
        </div>
        {panel((bonus_rate && bonus_rate.length) && !t && c,
            <Dividend
                key={dk}
                onChange={({data, er}) => {
                    setDi(data);
                    dDie(er);
                }}/>
        )}
        <div className={'x-ss'}/>
        <div className={'sub-c'}>
            <div className={'btn'} onClick={ld ? null : sho}><span>{ld ? '正在提交...' : ['立即开户', '生成链接'][t]}</span></div>
        </div>
        <Loading act={ld}>{ld}</Loading>
    </div>;
    const fix = (c, d) => {
        try {
            const o = c && JSON.parse(c.replace(/"(\d":)/g, '"commission_rate_$1'));
            o.prize_group = d;
            return o
        } catch (e) {
        }
        return {}
    }
    return <div className={'accPage x-sheet'}>
        <Tabs change={tt}>
            {p => <div className={'x-st-ti'}>
                <i/>
                <div className={'tbs'}>
                    <div {...p(0)}>人工开户</div>
                    <div {...p(1)}>链接开户</div>
                    <div {...p(2)}>链接管理</div>
                </div>
            </div>}
        </Tabs>
        <div className={'c x-tba'}>
            {panel(t < 2, share)}
            {panel(2 === t, <div className={'q'}>
                <div className={'link-his'}>
                    <Table
                        className={'x-table'}
                        row={[
                            {
                                name: '复制链接',
                                cell: ({url}) => <div className={'cpl'}>
                                    <a href={url} target={'_blank'}><s/><span>{url}</span></a>
                                    <Copy className={'cp'} copyText={url + '#请在浏览器中访问勿直接在微信打开'}/>
                                </div>
                            },
                            {
                                cell: ({created_count, id}) => {
                                    return <div className={'opt'}>
                                        <div onClick={() => {
                                            lk(id)
                                        }}>{created_count || 0}
                                        </div>
                                    </div>
                                }, name: '注册人数'
                            },
                            {
                                name: '类型', cell({is_agent}) {
                                    return is_agent ? '代理' : '玩家';
                                }
                            },
                            {key: 'created_at', name: '生成时间'},
                            {
                                name: '操作', cell: ({id, commission_sets, link_prize_group}) => {
                                    const x = dded[id] === 0;
                                    return <div className={'opt'}>
                                        <div onClick={() => {
                                            tip.alert(<div className={'s-inf'}>
                                                {cr(fix(commission_sets, link_prize_group))}
                                            </div>)
                                        }}>详情
                                        </div>
                                        <div onClick={() => !x && del(id)}>{x ? '删除中' : '删除'}</div>
                                    </div>
                                }
                            },
                        ]} data={rows}/>
                    <Loading act={regLinkLoading}>
                        正在加载...
                    </Loading>
                    <div className={'fl'}/>
                    <Paging data={{totalPage, cPage, handleBtnClick: ccp}}/>
                    <Portals>
                        <Detail id={linkId} close={() => lk(0)}/>
                    </Portals>
                </div>
            </div>)}
        </div>
    </div>
});
export const AccountPage = props => {
    return <A {...props} cls={'xt'}/>
};
