const o = {
    0: {name: "所有类型", type_id: ""}
};
const cfg = {};
const skip = [];
const wrap = kk => {
    skip.push(kk);
    cfg[kk] = {
        enumerable: true,
        get: () => {
            const ks = Object.keys(o)
                .filter(k => /\d+/.test(k))
                .sort((a, b) => a - b).map(k=>o[k]);
            return function (cb) {
               return ks[kk](cb)
            }
        }
    }
};
wrap('map');
wrap('find');
Object.defineProperties(o, cfg);
export const transChangeType = o;
