import React from 'react';
// API 集中配置
import {logout, stores} from '../store';
import {convertDate, encrypt, fmtErr, getRegCode, issuesTimer, processPay, tip} from '../utils'
import {nextCleanCache} from "./index";
import printJS from 'print-js';
import {OLink, Tip} from "../pages/common/com";
import {Copy} from "../components/copy";
import {popSetting} from "../pages/pop/popCenter";

const {
    globalStore, betListStore, myBankStore, thirdGameStore,
    walletStore,
    betStore, trendsStore, channelStore, prizeStore, transChangeType
} = stores;

const encPwd = str => encrypt(str, '90R1XU#DEDghEObz', '&$n$^VGfTT%VDdeX');

function fmt1(t, a = '') {
    return convertDate(t, 'YYYY-MM-DD ' + a).replace(/^\s+|\s+$/, '');
}

export const listenNoticeIds = {};
let t = -1;
const betWinNotice = (d) => {
    const data = d.data || d || [];
    const ds = data.concat().reverse();
    const s = {};
    const k = 'nc_' + globalStore.username;
    try {
        Object.assign(s, JSON.parse(window.localStorage[k]))
    } catch (e) {

    }
    const n = Date.now();
    // 已中奖的保存一周
    Object.keys(s).forEach(k => {
        const t = s[k];
        if (n - t > 1e3 * 3600 * 24 * 7) delete s[k];
    });
    let ohYeah = 0;
    ds.forEach(dd => {
        const {lottery_id, serial_number, status, title, prize, id} = dd;
        if (status === 3 && !s[serial_number] && listenNoticeIds[id]) {
            const name = (betStore.lotteries.find(({id}) => id === lottery_id) || {}).name;
            if (name) {
                ohYeah = 1;
                tip.popup({
                    title: '中奖通知',
                    key: serial_number,
                    data: <p>恭喜您的{name}-{title} 已中奖<b
                        style={{color: '#448af5', padding: '0 3px'}}>{parseFloat(prize).toFixed(2)}</b>元</p>,
                    onClose: () => {
                        s[serial_number] = Date.now();
                        window.localStorage.setItem(k, JSON.stringify(s))
                    }
                });
            }
        }
    });
    if (ohYeah) walletStore.query('main');
    return ohYeah;
};
/**
 * @typedef  config
 * @desc API的Config
 * // 发起请求时候调用
 * @property {function(Object)} before - 发送前处理数据
 * @property {function(Object)} after - 请求成功处理数据 保证格式一致
 * @property {function(Object,Object)} merge - 合并请求缓存数据
 * // 无论请求还是缓存都会调用
 * @property {function(Object)} done -  只返回data部分
 * @property {function(Object)} fail - 失败时候整理数据
 * @property {functioen(Object)} cacheKey - 发送前处理数据
 * // 其它
 * @property {number} cacheTime - 缓存时间
 * @property {(string|function)} group - 缓存分组数据 在after中使用  详见 API {@link configAfter}
 * @property {Object} storage - 指定存储位置 sessionStorage | localStorage
 */
/**
 * @name apiConfig
 * @param {(function|string)} url
 * [url, typeN, config = {
 *     cacheTime, // 缓存时间
 *     before,  // 发送前处理数据
 *     after,  // 请求成功处理数据 保证格式一致
 *     done, // after 后整理数据
 *     fail, // 失败时候整理数据 定义该项后 报错不会跳入默认处理程序(弹窗)
 *     cacheKey, //(url,params)=>key  指定缓存键值 用于将参数不同请求结果缓存到同一地方 通常搭配merge 使用
 *     storage = sessionStorage,
 *     merge(new,old), // 合并旧值 设定该项后 cacheTime 会失去效果
 * }]
 */
export const apis = {
    token: ['token', 0, {
        after({data: {token} = {}}) {
            if (!token) return {isSuccess: 0}
            globalStore.tvTk = token
        }
    }],
    aType: ['activities/type', 0, {
        cacheTime: 1e5,
        done(a = []) {
            globalStore.activeTypes = a.map(({id, name}) => [id, name])
        }
    }],
    captcha: ['users/captcha', 0, {
        cacheTime: 2e3,
        before(a = {}) {
            globalStore.capLd = 1;
            a.key = globalStore.capKey;
            if (a.useFor) {
                a.is_sign_up = 1;
                delete a['useFor'];
                a.key = window.localStorage.seed;
            }
        },
        done(a, p = {}) {
            globalStore.capLd = 0;
            if (Object.keys(p).length > 1) {
                globalStore.regCode = a
            } else {
                globalStore.captchaCode = a
            }
        },
        fail() {
            globalStore.capLd = 0;
        }
    }],
    /**
     * 第三方游戏列表接口
     * @param product_type 产品类型
     * @param platform 平台类型
     * @param client_type 客户端类型
     * @param game_type 游戏类型
     * @param page 页码
     * @param page_size 每页条数
     * */
    thirdGames: ['third-party-game/v2/list', 1, {
        cacheTime: 3e5,
        after({isSuccess, data}) {
            if (isSuccess) {
                return {
                    isSuccess,
                    data: data.data
                }
            }
        },
        before(d) {
            let {game_type, product_type} = d;
            if (product_type !== 'ALL') {
                delete d.product_type;
                d.product_id = product_type;
            }
            if (game_type) {
                game_type = d.game_type = game_type.toUpperCase();
                const f = thirdGameStore.cate[game_type];
                if (f && f.filter && f.length) {
                    const fixKey = (a) => {
                        const k = a.split('_');
                        return k[0] + k.slice(1).map(v => v[0].toUpperCase() + v.substr(1)).join('')
                    }
                    this.override.fake = f.filter(v => {
                        const ks = Object.keys(d);
                        const df = ks.find(k => d[k] + '' !== v[fixKey(k)] + '');
                        return !df;
                    });
                }
                // fake
                return {
                    is_pc: 1,
                    page: 1,
                    page_size: 9999999,
                    ...d
                }
            }
        },
        done(d, p, c) {
            const {game_type, product_type} = p;
            if (product_type === 'ALL') {
                if (d.length === 0) {
                    c('thirdGames', p)
                }
                thirdGameStore.cate[game_type] = d;
            }
        }
    }],
    /**
     * 进入游戏接口
     * @param product_type
     * @param game_mode
     * @param game_code
     * @param platform
     * */
    launchThird: ['third-party-game/launching', 1, {
        cacheTime: 5e3
    }],
    /**
     * 获取余额接口
     * @param product_type
     * */
    //third-party-game/balance
    thirdBalance: ['', 1, {
        fail(err) {
        }
    }],
    thirdProduct: ['third-party-game/product ', 0, {
        cacheTime: 3e5,
        done(d) {
            thirdGameStore.initProducts(d);
        }
    }],
    /**
     * 钱包转账
     * @param product_type
     * @param fund_type
     * @param amount
     * */
    thirdTrans: ['third-party-game/transaction', 1, {
        done() {
            return tip.alert({text: <Tip.Ok>转账成功!</Tip.Ok>});
        }
    }],
    /**
     * 钱包转账订单状态
     * @param product_type
     * @param reference_no
     * */
    thirdTransStatus: ['third-party-game/transaction-status', 1, {}],
    /**
     * 投注记录
     * @param start
     * @param end
     * @param type
     * @param settled
     * @param page
     * */
    thirdBetDetail: ['third-party-game/bet-detail', 1, {
        retry: 0,
        clear: 1
    }],
    init: ['init', 0, {
        cacheTime: 1e4,
        done(data) {
            if (data) {
                Object.assign(globalStore, data)
            }
        }
    }],
    //追号记录
    traceHistory: ['reports/trace', 0, {clear: 1}],
    // params category count(公告数量)
    category: ['cms/category', 0, {
        cacheTime: 18e5, // 30min缓存
        done(res) {
            if (res.isSuccess) {
                //todo
            }
        }
    }],
    suggestions: ['suggestions', 1, {
        done() {
            tip.alert(<Tip.Ok>感谢您的反馈！</Tip.Ok>)
        }
    }],
    // 获取文章 平台公告
    article: ['cms/article', 1, {
        // cacheTime: 6e5, // 10min缓存
        cacheTime: 1e3 * 60, //1min
    }],
    //站内信
    webMsg: ['message', 0, {
        clear: 1,
        cacheTime: 1e3 * 60,
        before(d) {
            return {...d, paginate: 1, count: 10}
        }
    }],
    //查看站内信
    msgView: ['message/view', 0, {
        cacheTime: 1e8,
        clear: 1,
        after(d) {
        }
    }],
    unread: ['message/unread-number', 0, {
        cacheTime: 5e3,
        done({count}) {
            globalStore.unread = count;
        },
        fail() {
        }
    }],

    // banner
    // banner id:28
    // qr-code id:33(url 生成qrCode)
    ad: ['ad', 1, {
        cacheTime: 1e3 * 60 * 5,
        done(res) {
            const {ads} = globalStore;
            if (JSON.stringify(res) !== JSON.stringify(ads))
                globalStore.ads = res;
        }
    }],
    ad_qrcode: ['ad', 1, {
        cacheTime: 18e5 * 2 * 24, // 24h缓存
        done(res) {
            if (res.isSuccess) {
                //todo
            }
        }
    }],
    // 奖期
    issue: ['games/issue', 1, {
        cacheTime: 2e3,
        before({lottery_id, is_need_last_winnuber}) {
            if (betStore.issue_lottery !== lottery_id && !is_need_last_winnuber) {
                betStore.issue_lottery = lottery_id;
            }
        },
        done(data, {lottery_id, is_need_last_winnuber} = {}) {
            if (!is_need_last_winnuber) {
                if (betStore.current_number === data.cur_issue) return;
                betListStore.setTraceMax(data.trace_max_times || 50);
                betStore.current_number = data.cur_issue;
                betStore.betInfo = data;
                betStore.history_gnumber = data.game_numbers || [];
                betListStore.setTrace(betStore.history_gnumber);
            }
            if (lottery_id) issuesTimer.add(lottery_id, data.cur_issue, data.cur_issue_time)
        },
        fail(err) {
            const er = fmtErr(err);
            if (er.name === 'ObjectUnsubscribedError') console.log(er);
        }
    }],
    // 玩法
    gameWay: ['games/method/', 1, {
        storage: localStorage, // 缓存到 localstorage
        cacheTime: 3e5, // 30min缓存
        after(d, p) {
            if (d && d.isSuccess && (!d.data || !d.data.length)) {
                d.isSuccess = false;
                d.message = `接口返回的玩法分类为空  lottery_id:${p.lottery_id}`;
            }
        },
        before() {
            betStore.headerData.clear();
        },
        done(data = []) {
            const {prize_group, max_bet_prize_group} = globalStore;
            const mxGroup = Math.min(max_bet_prize_group, prize_group);
            betStore.headerData.clear();
            const da = [];
            da.clear = betStore.headerData.clear;
            data.forEach(o => {
                o.children = o.children.map(g => {
                    g.children.map(f => {
                        const {is_enable_extra, prize, extra = {}, extra_prize} = f;
                        const rule = is_enable_extra ? Object.keys(extra) : [];
                        const probability = {}
                        const multiple = {}
                        rule.forEach(r => {
                            probability[r] = extra_prize[r] / mxGroup
                            multiple[r] = extra[r] / mxGroup
                        });
                        const pz = prize / mxGroup;
                        f.getPrize = (prizeGroup, info = []) => {
                            const [ball, bets] = info;
                            prizeGroup = prizeGroup || betListStore.prizeGroup;
                            if (!is_enable_extra) return [pz * (prizeGroup || mxGroup)]
                            let min = 1, max = 0;
                            let ps = [];
                            if (rule.join("") && bets) {
                                let v = ball;
                                const vv = v.replace(/[^\d]+/, '');
                                if (vv) {
                                    if (bets > 1) {
                                        if (/[^\d]/.test(v)) v = v.split(/[^\d]/);
                                        else v = v.split('');
                                    } else {
                                        v = [vv];
                                    }
                                    rule.forEach((r) => {
                                        if (!r.length) return;
                                        const a = r.split(/[^\d]+/);
                                        for (let i = 0, l = a.length; i < l; i++) {
                                            if (v.findIndex(b => +b === +a[i]) !== -1) {
                                                const pp = +probability[r];
                                                if (pp > max) max = pp;
                                                if (pp < min) min = pp;
                                            }
                                        }
                                    })
                                }
                                ps = [min];
                                if (max !== min) ps.push(max);
                            } else {
                                ps = [Math.min(...Object.values(probability))]
                            }
                            return ps.map(p => p && prizeGroup * p)
                        };
                        f.getMaxMultiple = prizeGroup => {
                            prizeGroup = prizeGroup || betListStore.prizeGroup;
                            return Math.floor(globalStore.max_prize / f.getPrize(prizeGroup)[0])
                        };
                        f.price = f.price / 1000;
                        return f;
                    });
                    return g
                });
                da.push(o);
            });
            betStore.headerData = da;
        }
    }],
    // 余额
    balance: ['users/available', 0, {
        cacheTime: 5e3,
        clear: 1,
        done(data) {
            const v = +data.available;
            if (isNaN(v)) return;
            const o = walletStore.bls.main;
            o.balance = v;
            Object.assign(globalStore, data);
        }
    }],
    reg: ['users/signup', 1, {
        before(a) {
            const c = getRegCode();
            if (c) {
                a.keyword = c;
            }
        },
        done(data) {
            tip.alert(<Tip.Ok>注册成功！</Tip.Ok>)
            if (data.token) {
                logout();
                const t = data.token;
                delete data.token;
                Object.assign(globalStore, data);
                setTimeout(() => {
                    globalStore.token = t;
                }, 100);
            }
        },
        fail(e, p) {
            const er = fmtErr(e || '注册失败');
            let c = '';
            try {
                c = e.data.captcha;
                if (typeof c === 'string') globalStore.regCode = c;
            } catch (e) {
            }
            if (!c && /验证码/.test(er)) {
                stores.query('captcha', {
                    useFor: window.localStorage.seed
                })
            } else tip.alert(<Tip.Err>{er}</Tip.Err>)
        }
    }],
    // 登录
    login: ['users/login', 1, {
        clear: 1,
        before({username, password, captcha, key}) {
            this.pwd = password;
            globalStore.capKey = key || username;
            const a = {
                captcha,
                username: username,
                password: encPwd(password)
            }
            if (key) a.key = key;
            return a
        },
        done(data, {isPhone,key,password}, clean) {
            nextCleanCache('getLottery');
            if (data.token) {
                globalStore.captchaCode = '';
                if (data.is_first_login) {
                    popSetting.show(6, undefined, isPhone?key:password)
                }
                Object.assign(globalStore, data);
            }
            return [['getLottery']]
        },
        fail(e) {
            const er = fmtErr(e || '登陆失败');
            let c = '';
            try {
                c = e.data.captcha;
                if (typeof c === 'string') globalStore.captchaCode = c;
            } catch (e) {
            }
            if (!c && er === '请输入验证码') {
                stores.query('captcha')
            } else tip.alert(<Tip.Err>{er}</Tip.Err>)
        }
    }],
    // 升级玩家到代理
    // 参数user_id  需要升级成为代理的玩家id
    toAgent: ['agents/agent', 1],
    // 走势
    // 参数 {lottery_id:1,count:10}  count- 取的数量
    trend: ['games/win-numbers', 0, {
        before({count, lottery_id}) {
            return {
                page_size: count,
                lottery_id
            }
        },
        after({isSuccess, data}) {
            if (isSuccess) {
                const d = (data || {}).data || [];
                d.sort(({issue: a}, {issue: b}) =>
                    a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
                );
                return {
                    isSuccess,
                    data: {
                        original_data: d.map(({issue, wn_number}) => {
                            let lt = wn_number.split(/[, |]/);
                            if (lt.length === 1) lt = wn_number.split('');
                            return {
                                issue,
                                lottery: lt
                            }
                        })
                    }
                }
            }
        },
        done(data,param) {
            // console.log(data)
            if (JSON.stringify(trendsStore.data) !== JSON.stringify(data))
                trendsStore.data = data;
        },
        cacheTime: 6e4,
        saveKey(url, {id}) {
            return url + id;
        },
        merge(data, old) {
            const {original_data: od} = old;
            const {original_data: da = []} = data;
            od.forEach(o => {
                if (!da.find(d => d.issue === o.issue)) {
                    da.push(o);
                }
            });
            data.original_data = da;
        }
    }],
    getLottery: ['games/lottery', 0, {
        cacheTime: 36e5,
        storage: localStorage,
        before() {
            betStore.loading = 1;
        },
        after({isSuccess, data}) {
            betStore.loading = 0;
            const {series} = betStore;
            Object.keys(data).forEach(k => {
                data[k].forEach(o => {
                    o.series = k;
                    if (k === "11-5") k = "115";
                    else if (k === "DPC") k = "3d";
                    else if (k === "PLW") k = "p35";
                    o.cate = k.toLowerCase();
                    series[o.series_id] = k
                })
            });
        },
        done(data) {
            betStore.allLottery = data;
            betStore.loading = 0;
        }
    }],
    //所有method
    getAllMethods: ['/games/way', 0, {
        cacheTime: 36e5,
        storage: localStorage,
        done(data) {
            betStore.allMethods.data = data;
        }
    }],
    getChannel: ['deposit/get-available-channel', 0, {
        clear: 1,
        cacheTime: 5e3,
        before() {
            return {mobile: 0}
        },
        after({isSuccess, data}) {
            if (isSuccess) {
                const o = {};
                const bk = {};
                Object.keys(data).forEach(k => {
                    const d = data[k];
                    const {children = []} = d;
                    children.forEach(({banks = []}) => banks.forEach(({identifier, url}) => {
                        bk[identifier] = url;
                    }));
                    const {id} = d;
                    o[id] = d;
                });
                channelStore.bankUrls = bk;
                return {isSuccess, data: o}
            }
        },
        done(data) {
            channelStore.data = data;
        },
        fail(err) {
            tip.alert({text: <Tip.Ok>{fmtErr(err || '获取充值渠道异常')}</Tip.Ok>})
        }
    }],
    bet: ['games/bet', 1, {
        before(sendData) {
            betStore._submitting = true;
            sendData.bet_source = 'web';
            sendData.balls = encrypt(JSON.stringify(sendData.balls), 'J8LFQIU7X8YTFBR9', 'E3TY470PHGTEV5YB');
            sendData.is_encoded = 1;
            sendData.traceStopValue = 1;
            sendData.traceWinStop = +betListStore.traceWinStop;
            sendData.enable_bet_result = 1;
            return sendData;
        },
        after() {
            // return {
            //     isSuccess:true
            // }
        },
        done({bet_result: {success = []}}, param) {
            const mtMap = {};
            const ff = (c, b = []) => {
                c.forEach((a) => {
                    const {name_cn, children, id} = a;
                    b = b.concat();
                    b.push(name_cn);
                    if (children) ff(children, b);
                    else mtMap[id] = b.join();
                })
            };
            const {
                gameId
            } = param;
            const name = (betStore.lotteries.find(({id}) => id === gameId) || {}).name;
            const tbData = [];
            tbData.push({n: '游戏', v: name});
            betStore._submitting = false;
            const l = success.length;
            tip.alert({
                text: <Tip.Ok>投注成功！</Tip.Ok>,
                no: l ? '确定' : undefined,
                clsN: l ? 'y' : undefined,
                yes: l ? '打印注单' : '确定',
                onClose: l ? (a) => {
                    if (a) {
                        ff(betStore.headerData);
                        success.forEach(({amount, bet_number, way, id}) => {
                            tbData.push({n: '', v: ''});
                            tbData.push({n: '玩法', v: mtMap[way]});
                            tbData.push({n: '投注', v: bet_number});
                            tbData.push({n: '金额', v: (+amount).toFixed(3)});
                            tbData.push({n: '兑奖号', v: id});
                        });
                        printJS({
                            printable: tbData,
                            documentTitle: 'BT游戏',
                            properties: [
                                {field: 'n', displayName: ''},
                                {field: 'v', displayName: ''},
                            ],
                            style: 'table td {padding:5px 20px}',
                            type: 'json'
                        });
                    }
                } : undefined
            });
            nextCleanCache('traceHistory', 'getGameRecord');
            betStore.refresh++;
            return [['balance']]
        },
        fail(err) {
            betStore._submitting = false;
            tip.alert({text: <Tip.Err>{fmtErr(err || '提交投注失败,请稍后尝试。')}</Tip.Err>})
        }
    }],
    // 充值
    // amount bank_id  payment_id  deposit_mode
    charge: ['deposit/send-request', 1, {
        done(data, p) {
            channelStore.payTime = Date.now();
            channelStore.payRes = data;
            processPay(data, p)
        }
    }],
    myBankCard: ['bankcard/mycard', 0, {
        cacheTime: 3e3,
        clear: 1,
        retry: 3,
        done(data) {
            [].sort.call(data.banks || [], ({bank_id}) => {
                if (bank_id === 43) return 1;
                return -1
            });
            Object.assign(myBankStore, data);
        },
        fail(err) {
            tip.alert({text: <Tip.Err>{fmtErr(err || '获取我的银行卡,请稍后尝试。')}</Tip.Err>})
        }
    }],
    // 我的银行卡
    getBanks: ['bankcard/getbanks', 0, {
        cacheTime: 12e3 * 3600, // 12小时缓存
        storage: localStorage,//
        done(data) {
            myBankStore.bankMap = myBankStore.allBanks = data.data || [];
        },
        fail(err) {
            tip.alert({text: <Tip.Err>{fmtErr(err || '获取银行卡列表失败,请稍后尝试。')}</Tip.Err>})
        }
    }],
    //最近游戏
    getRecentGame: ['games/recent-gaming-list', 0, {
        cacheTime: 6e4,
    }],
    // 提现
    drawMoney: ['withdraw/send-request', 1, {
        before({amount, fund_password, id, is_usdt = 0}) {
            return {
                is_usdt,
                amount: +amount,
                fund_password: encPwd(fund_password),
                id: id
            }
        },
        fail(err) {
            tip.alert({text: <Tip.Err>{fmtErr(err || '')}</Tip.Err>})
        }
    }],
    //转账
    transMoney: ['agents/transfer', 1, {
        retry: 0,
        before({username, amount, fund_password}) {
            return {
                username,
                amount,
                fund_password: fund_password,
            }
        },
        done() {
            tip.alert({text: <Tip.Ok>转账成功！</Tip.Ok>});
            return [['balance']]
        }
    }],
    //充值申请
    getChargeApply: ['reports/deposit', 0, {
        cacheTime: 5e3,
        fail(err) {

        }
    }],
    //充值记录
    getChargeRecord: ['reports/transaction', 0, {
        cacheTime: 1e4,
    }],
    //提现申请
    getWithDrawApply: ['reports/withdrawal', 0, {
        cacheTime: 5e3,
        done(data) {

        },
        fail(err) {

        }
    }],
    //游戏记录
    getGameRecord: ['reports/project', 0, {
        cacheTime: 6e3,
        done(data) {
            if (betWinNotice(data.data))
                return [['balance']];
        }
    }],
    //游戏记录 -> 总记录
    getDTS: ['reports/project-sum', 0, {
        cacheTime: 1e3 * 60,
        after({isSuccess, data, sum_data}) {
            return {
                isSuccess,
                data: {
                    data,
                    sum_data
                }
            }
        },
    }],
    /***
     *  派發列表
     */
    getCtrBonuses: ['contract-bonuses'],
    setCtrBonus: [({id}) => `contract-bonuses/sending/${id}`, 2, {
        before: () => 0
    }],
    //追号记录
    getChaseRecord: ['reports/trace', 0, {
        cacheTime: 1e3 * 60
    }],
    //团队管理
    getTeamMg: ['agents/team', 0, {
        after(d) {
            d.data = d.data || {}
            d.data.numbers = d.numbers;
        }
    }],
    //团队盈亏
    getTeamBAL: ['agents/user-profit', 0, {
        cacheTime: 1e4,
        before(data) {
            if (data.page === 0) delete data.page
        },
    }],
    //团队充提记录
    getTeamCADR: ['agents/withdraw-deposit', 0, {
        cacheTime: 1e3 * 60,
        after({isSuccess, data, self_profit, agent_sum}) {
            return {
                isSuccess,
                data: {
                    data,
                    self_profit,
                    agent_sum
                }
            }

        },
    }],

    //账变类型
    getTransType: ['reports/transaction-type', 0, {
        cacheTime: 1e3 * 600,
        done(data) {
            // Object.assign(transChangeType,data)
            transChangeType.length = 0;
            transChangeType[0] = {
                name: '所有类型',
                type_id: '',
            }
            data.map((v, k) => {
                transChangeType[k + 1] = {
                    name: v.name,
                    type_id: v.id,
                }
            })
        }
    }],
    // 用户名字开户
    createAccount: ['agents/accurate-create', 1],
    // l链接开户
    createLink: ['agents/create-register-link', 1, {
        done({url}) {
            tip.alert({
                text: <div>
                    <Tip.Ok>链接创建成功!</Tip.Ok>
                    <OLink url={url}/>
                </div>,
                yes: <Copy copyText={url + '#请在浏览器中访问勿直接在微信打开'} name={'确认并复制'}/>
            })
        }
    }],
    // 获取注册链接
    getRegLinks: ['agents/register-link', 0, {
        cacheTime: 2e3,
        clear: 1,
        done(data) {
        },
        fail() {

        }
    }],
    // 设置用户信息
    setUserInfo: ['users/user-info', 1, {
        after(r, p) {
            if (r && r.isSuccess)
                Object.assign(globalStore, p);
        },
        done(a, p) {
            tip.alert(<Tip.Ok>已更新个人信息!</Tip.Ok>);
            nextCleanCache('userSetting');
        }
    }],
    // 删除注册链接
    delLink: ['agents/delete-register-link', 1, {}],
    // 注册链接详细
    regLinkUser: ['agents/register-link-user', 0, {
        before(a) {
            if (a && !a.page_size) {
                a.page_size = 9999;
                return a;
            }
        },
        clear: 1,
        cacheTime: 1e4,
    }],
    // 用户信息查询
    userSetting: ['agents/user-setting', 0, {
        cacheTime: (d) => {
            if (d && d.user_id) return 2e3
            return 2e4
        },
        clear: 1,
        done(data, p) {
            if (data && !p) Object.assign(globalStore, data);
        }
    }],
    // 用户奖金组查询
    getPrize: ['user-prize-set', 0, {
        done(data) {
            const {lottery} = data;
            const {lottery: lt} = prizeStore;
            lottery.forEach(l => {
                const {series} = l;
                l.name = series;
                switch (series) {
                    case '时时彩':
                        l.game = 1;
                        break;
                    case '11选5':
                        l.game = 2;
                        break;
                    case '低频彩':
                        l.game = 13;
                        break;
                    case '快三':
                        l.game = 21;
                        break;
                    case 'PK10':
                        l.game = 53;
                        break;
                    case '幸运28':
                        l.game = 54;
                        break;
                    default:
                        return;
                }
                lt[l.game] = {prizeGroup: l.prize_group, name: series};
            });
            prizeStore.lottery = lt;
        },
        cacheTime: 1e3 * 3600,
    }],
    // 玩法查询
    getWays: ['games/way', 0, {
        storage: localStorage,
        cacheTime: -1
    }],
    // 添加
    // params  address
    addUsdt: ['usdts', 1, {
        done() {
            return [['getUsdt']]
        },
        fail(err) {
            tip.alert({text: <Tip.Err>{fmtErr(err || 'USDT钱包定失败,请稍后尝试。')}</Tip.Err>})
        }
    }],
    getUsdt: ['usdts', 0, {
        cacheTime: 5e3,
        done(data) {
            if (data) data.forEach(a => {
                a.address = a.address.replace(/^.*?(.{4})$/g, '**** **** **** $1')
            })
            myBankStore.usdts = data
            // myBankStore.bankMap = myBankStore.allBanks = data.data || [];
        }
    }],

    // 'announcement/article/:id',
    // 银行卡绑定
    bindBank: ['bankcard/bind-card', 1, {
        done() {
            return [['myBankCard']]
        },
        fail(err) {
            tip.alert({text: <Tip.Err>{fmtErr(err || '银行卡绑定失败,请稍后尝试。')}</Tip.Err>})
        }
    }],
    // //删除银行卡
    // delBank: ['bankcard/delete-card', 1, {
    //
    //     before(data) {
    //         data.fund_password = encPwd(data.fund_password);
    //         return data
    //     },
    //     fail(err) {
    //         tip.alert({text: <Tip.err>{fmtErr(err || '银行卡删除失败,请稍后尝试。')}</Tip.err>})
    //     }
    // }],
    // // 锁定银行卡
    // lockCard: ['bankcard/lock-card', 1, {
    //
    //     before(data) {
    //         data.fund_password = encPwd(data.fund_password);
    //         return data
    //     },
    //     done() {
    //         tip.alert({text: '银行卡锁定成功！'});
    //     },
    //     fail(err) {
    //         tip.alert({text: fmtErr(err || '银行卡锁定失败,请稍后尝试。')})
    //     }
    // }],

    //验证银行卡
    verifyBank0: ['bankcard/checking2', 1, {}],
    verifyBank: ['bankcard/validate-card', 1, {
        before(data) {
            data.fund_password = encPwd(data.fund_password);
            return data
        },
        fail(err) {
            tip.alert(<Tip.Err>{fmtErr(err)}</Tip.Err>)
        }
    }],
    //奖金组设置
    setPrizeGroup: ['agents/set-prize-group', 1, {
        done(d, {user_id}, clean) {
            tip.alert({text: <Tip.Ok>设置成功!</Tip.Ok>});
            clean('userSetting', {user_id});
        }
    }],
    // 撤单
    cancelOrder: ['reports/project-drop', 1, {
        done() {
            tip.alert(<Tip.Ok>撤单成功!</Tip.Ok>);
            nextCleanCache('getGameRecord', 'getDTS');
        }
    }],
    //终止追号
    stopTrace: ['reports/trace-stop', 1, {
        done() {
            tip.alert(<Tip.Ok>已终止追号!</Tip.Ok>);
            nextCleanCache('getGameRecord', 'getDTS');
        }
    }],
    // 追号详情 列表 trace_id
    getTraceLs: ['reports/trace-detail'],
    test: ['test', 0, {
        timeout: 0,
        cacheTime: 5e3,
        before(d = '') {
            this.override.baseURL = d.replace(/^.*(www|api)\./, '//api.');
            this.t = Date.now();
            return {};
        },
        after() {
            return {
                isSuccess: true,
                data: {t: Date.now() - this.t}
            }
        },
        fail() {

        }
    }],
    //  id detail_id 取消某期追号
    stopTraceDetail: ['reports/trace-detail-cancel', 1, {
        done() {
            tip.alert(<Tip.Ok>取消成功!</Tip.Ok>);
        }
    }],

    resetPwd: ['users/reset-password', 1, {

        before({password_old, password_new, type = 0}) {
            return {
                old_password: encPwd(password_old),
                password: encPwd(password_new),
                password_confirmation: encPwd(password_new),
                type
            }
        },
        done(a, {type}) {
            tip.alert(<Tip.Ok>{type ? '资金密码更新成功！' : '个人密码修改成功！'}</Tip.Ok>)
        },
        fail(err, {type}) {
            tip.alert(
                <Tip.Err>{fmtErr(err, type ? '资金密码更新失败，请稍后重试' : '个人密码修改失败,请稍后尝试。')}</Tip.Err>)
        }
    }],
    /**r
     * 发送消息
     * @param  title   标题
     * @param  content   内容
     * @param  receiver_id  接收消息用户id（如果有传值就是指定联系人，
     *                                   如果没有传这个参数代表所有的下级）
     */
    sendMsg: ['message/create', 1, {
        after(e) {
            return e || {isSuccess: 1}
        },
        done() {
            tip.alert(<Tip.Ok>发送成功!</Tip.Ok>)
        }
    }],
    delMsg: ['message/delete', 1, {}],
    msgType: ['message/type', 0],
    /***
     *  @param is_receiver 1- 收件  0 发件
     */
    getMsg: ['message', 0],
    //聯係人列表
    contacts: ['message/user-list'],
    // 返点
    commission: ['agents/commission', 1],
    // 删除分红配置
    delBonus: [({id, user_id}) => `user-bonus-settings/${id}/${user_id}`, 3, {
        before() {
            return 0
        }
    }],
    // 获取分红配置
    getBonus: [({user_id}) => `user-bonus-settings/${user_id}`, 0, {
        before() {
            return 0
        }
    }],
    // 删除分红配置
    updateBonus: [({id, user_id}) => `user-bonus-settings/${id}/${user_id}`, 2, {
        before(d) {
            if (d) {
                const a = {...d};
                delete a.id;
                delete a.userId;
                return a;
            }
        }
    }],
    /***
     *  新增分红配置 参数rate分红比例，deficit亏损， active_number活跃人数，user_id用户ID
     *  @param  rate 分红比例
     *  @param  deficit 亏损
     *  @param   active_number 活跃人数
     *  @param   user_id 用户ID
     * */
    addBonus: ['user-bonus-settings', 1],
    // 分红
    bonus: ['agents/bonus'],
    // 资金密码设置
    resetFound: ['users/setfundpassword', 1, {
        before({password_old, password_new}) {
            return {
                old_password: encPwd(password_old),
                password: encPwd(password_new),
                password_confirmation: encPwd(password_new),
                type: '1'
            }
        },
        after(res) {
            if (res && res.isSuccess)
                setTimeout(() => globalStore.has_fund_password = 1, 300);
        },
        done() {
            tip.alert(<Tip.Ok>资金密码修改成功！</Tip.Ok>);
        },
        fail(err) {
            tip.alert(<Tip.Err>{fmtErr(err || '资金密码修改失败,请稍后尝试。')}</Tip.Err>)
        }
    }],
    /***
     * 签约管理
     * @param user_id
     * */
    addContracts: ['user-contracts', 1],
    /***
     *  修改签约
     *  @param status 0 -未签约  1-
     */
    updateContracts: [({user_id, id}) => `user-contracts/${user_id}/${id}`, 2, {
        before({status}) {
            return {status};
        }
    }],
    /***
     *  查询签约
     *  @param username
     *  @param status
     */
    getContracts: ['user-contracts', 0, {
        cacheTime: 5e3,
        after(d) {
            d.data = d.data || {}
            d.data.numbers = d.numbers;
        },
        before(d) {
            d.page_size = 10;
        }
    }],
    /***
     *  设置日工资
     *   @param user_id
     *   @param commission_rate
     */
    setSalary: ['user-salary-settings', 1],
    // 删除日工资
    delSalary: [({user_id}) => `user-salary-settings/${user_id}`, 3],
    /**
     *  修改日工资
     *  @param commission_rate
     */
    updateSalary: [({user_id}) => `user-salary-settings/${user_id}`, 2],
    /**
     * 分红
     * @param type
     * @param date_from
     * @param date_to
     */
    /**
     * url 位置为function 时候 传参 fn(原始请求参数,处理后请求参数)=>url
     */
    bonusReport: [({is_agent}) => ['agents/bonus', 'agents/team-bonus'][+is_agent],
        0, {
            clear: 1,
            cacheTime: 1e3 * 60 * 5,
            before({type, start, end, page}) {
                const o = {
                    type, page
                };
                if (o.start) o.start = fmt1(start).replace('---', '');
                if (o.end) o.end = fmt1(end).replace('---', '');
                return o;
            },
            group: ({is_agent}) => is_agent ? 'agentBonus' : '',
            /**
             * 传参 fn(请求原始结，果原始请求参数,处理后请求参数)=>新结果
             */
            after({isSuccess, data, self_profit}, {is_agent, page}, _, groupData) {
                if (is_agent) {
                    let self = self_profit;
                    if (page > 1) {
                        const pageFirst = groupData({page: 1});
                        if (pageFirst.length) {
                            self = pageFirst[0].self[0];
                        }
                    }
                    return {
                        isSuccess,
                        data: {
                            others: data.data || [],
                            self: self == null ? [] : [self]
                        }
                    }
                }
            }
        }],
    /**
     * 日工资
     * @param type
     * @param date_from
     * @param date_to
     */
    salaryReport: [({is_agent}) => is_agent ? 'agents/team-daily-salary' : 'agents/daily-salary', 0, {
        cacheTime: 1e3 * 60 * 5,
        clear: 1,
        before({start, end, page, is_agent}) {
            const o = {
                page, is_agent,
            };
            if (start) o.start = fmt1(start) === '---' ? '' : fmt1(start);
            if (end) o.end = fmt1(end) === '---' ? '' : fmt1(end);
            return o;
        },
        after({isSuccess, data}) {
            return {
                isSuccess,
                data: data.data || []
                // data: {
                //     others: data.data || [],
                //     self: self==null?[]:[self]
                // }
            }
        }
    }],
    activities: ['activities', 0, {
        cacheTime: 1e3 * 60,
        after(a) {
            if (a.isSuccess)
                a.data.forEach(v => {
                    v.content = (v && v.content || '').replace(
                        /<a href="(https?:\/\/)?(game:\/\/.*?)".*?>(.*?)<\/a>/gi,
                        `<span class='link' onclick=window.open('$2')>$3</span>`)
                })
        }
    }],
    activeList: ['activities/list', 0, {
        cacheTime: 3e3
    }],
    applyActivity: [({id}) => `activities/promo/${id}`, 0, {
        before: () => 0,
        done() {
            tip.alert('领取成功');
        }
    }],
    withdrawCount: [
        'withdraw/withdraw-count', 1, {
            done({iWithdrawalNum = 0}) {
                globalStore.today_withdraw = iWithdrawalNum
            }
        }
    ],
    thirdLotteries: ['third-party-game/lotteries', 1, {
        cacheTime: 6e4
    }],
    roll: ['roulette/lottery', 0, {}],
    roulette: ['roulette', 0, {}],
    sms: ['sms', 0],
    smsCheck: ['/sms/check', 0, {
        done(_, p) {
            if ('key' in p) {
                return
            }
            tip.alert({text: <Tip.Ok>手机号码验证成功!</Tip.Ok>});
            globalStore.is_valid_phone = 1;
            popSetting.hide();
            return [['userSetting']]
        }
    }],
    // start(Y-m-d H:i:s) end(Y-m-d H:i:s) 运营数据
    opData: ['agents/operational-data', 0, {before: fmtD}],
    // 新会员注册
    regList: ['agents/register'],
    // 会员充值
    disposeList: ['agents/deposit'],
    // 会员首存
    firstDispose: ['agents/first-deposit'],
    // start(Y-m-d H:i:s) end(Y-m-d H:i:s) 游戏汇总
    summary: ['agents/game-summary',0,{before:fmtD}]
};

function fmtD({start, end}={}) {
    return {
        start: `${start} 00:00:00`,
        end: `${end} 23:59:59`
    }
}
