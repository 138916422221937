import React, {useState, useEffect, useMemo} from 'react';
import Slider from 'rc-slider';
import {widthStore} from "../../store";
import {useBetSubmit, useGameFoot, useGameRule, useGameWay} from "../../hooks";
import {ViewItem} from "./ViewItem";
import {limitBets, tip} from "../../utils"
import {Loading} from "../../components/loading";
import 'rc-slider/assets/index.css';

export const BetBall = widthStore(({betStore, betListStore}, {type, loading, gameId}) => {
    const components = [];
    const [act, setAct] = useState();
    const [tx, setT] = useState('');
    const {bets, playGame: game, headerData} = betStore;
    const [
        {name_en: n0 = ''} = {},
        {name_en: n1 = ''} = {},
        way2 = {}
    ] = useGameWay(loading, headerData);
    const {prizeGroup} = betListStore;
    const {name_en: n2 = ''} = way2;
    const gn = n2 && (n0 + n1 + n2);
    const [noResult, tClick, bClick, isAct] = useGameRule(type, gn);
    if (!noResult) {
        const {
            views = [], mark = '',
            isInput, desc, eg,
            chooseType
        } = game;

        function check(value) {
            const old = game.balls;
            game.balls = value;
            if (limitBets.call(game, game.getBalls(value))) {
                game.balls = old;
                return old;
            }
            return value
        }

        if (isInput) {
            const ch = ({target: {value}}) => {
                setAct(!!check(value.replace(/[^0-9 \r\n,，.。;；|]/gi, '')));
            };
            const clear = () => {
                setAct(!!(game.balls = ''));
            };
            const clean = () => {
                setAct(!!(game.balls = game.getView(game.views, game.balls)));
            };
            const reader = ({target}) => {
                const f = target.files[0];
                if (f) {
                    setT('正在解析文件...');
                    const {size} = f;
                    if (size > 1024 * 1024) {
                        setT('');
                        return tip.alert({text: '文件大小不能超过1M'});
                    }
                    const reader = new FileReader();
                    reader.onload = (function () {
                        return function ({target: {result}}) {
                            setT('');
                            if (result) {
                                setAct(!!check(result.replace(/[^0-9 \r\n,，.。;；|]/gi, '')));
                            }
                        };
                    })(f);
                    reader.readAsText(f)
                }
            };
            components.push(<div key={'dan'} className={'dans-box'}>
                {window.File && window.FileReader && window.FileList && window.Blob ?
                    <div className={'f-tool'}>
                        <div className={'btn'}>导入注单 <input onChange={reader} accept="text/*" type="file"
                                                           name="files1[]"/></div>
                        <span>{tx}</span>
                    </div>
                    : ''}
                <div className={'dans'} key={'x' + mark}>
                    <textarea onChange={ch} onBlur={ch} className={act ? 'act' : ''} value={game.balls || ''}/>
                    <div className={'dans-desc'}>
                        <p>
                            说明：<br/>
                            {desc}
                        </p>
                        <p>范例：{eg}</p>
                    </div>
                </div>
                <div className={'f-tool'}>
                    <div className={'btn o'} onClick={clean}>清理重复和错误</div>
                    <div className={'btn'} onClick={clear}>清空文本框</div>
                </div>
            </div>)
        } else {
            for (let x = 0, len = views.length; x < len; x++) {
                const ball = game.balls[x];
                const tit = game.titles[x];
                if (ball) components.push(
                    <div className='item' key={x + 'it' + mark}>
                        <div className={'title' + (!tit ? ' no-tit' : '')}>
                            {tit}
                        </div>
                        <div className='view'>
                            {
                                views[x].map((item, y) => {
                                    return (<ViewItem key={x + '-' + y}
                                                      x={x} y={y} click={bClick}
                                                      view={item} ball={ball[y]}
                                    />)
                                })
                            }
                        </div>
                        <div className="btn">{
                            (isNaN(views[x][0]) || (game.dantuo && x === 0)) ?
                                '' : chooseType.map(
                                (item, index) => {
                                    return <div
                                        className={isAct(item, x) ? 'act' : ''}
                                        key={index}
                                        onClick={() => tClick(item, x)}>
                                        {item}
                                    </div>
                                })
                        }
                        </div>
                    </div>
                )
            }
        }
    }

    const [
        setUn, moneyunit, mulriple,
        money, max, balance,
        setMu, addMu, minusMu, allAct,
        allIn, addCart, prLis = [],
        prChange, selectPrize, pz
    ] = useGameFoot(game, way2.getMaxMultiple && way2.getMaxMultiple(prizeGroup) || 1);
    const submit = useBetSubmit(gameId, 1);
    const {_submitting} = betStore;
    const available = bets && balance;
    const [vv, sVv] = useState(mulriple || 1);
    useEffect(() => {
        if (vv !== '' && vv !== mulriple) sVv(mulriple);
    }, [mulriple, vv]);

    const pr = way2.getPrize ? way2.getPrize(0, game && game.info && game.info())[0] * moneyunit * mulriple : 0;

    const v = ({target: {value}}) => {
        window.rc = window.rc || {};
        window.rc[way2.series_way_id] = value;
        localStorage.rc = JSON.stringify(window.rc);
    };
    const topWin = useMemo(() => {
        if(game){
            if(game.id==="sscyixingdingweidanfushi"){
                return  game.balls.filter(a=>a.find(c=>c===1)).length*pr-money
            }
        }
        return pr - money
    }, [pr, money,game])

    return <div className={"main"}>
        <div className={'debug-input'}>
            <input type="number" onChange={v}/>
        </div>
        <div className={"ball-area " + n1 + n2}>
            <Loading act={loading}>正在加载...</Loading>
            {loading ? null :
                noResult ? <div className={"no-data"}>没有对应的玩法配置</div> : components
            }
        </div>
        <div className={"ctrl"}>
            <div className={'a'}>
                <div className={'b'}>
                    <label>倍数：</label>
                    <div>
                        <i className={'minus'} onClick={minusMu}>-</i>
                        <input type={'text'}
                               step={1}
                               onChange={({target: {value}}) => {
                                   const v = value.replace(/[^0-9]/gi, '');
                                   if (v) setMu(parseInt(v));
                                   sVv(v)
                               }}
                               onBlur={() => {
                                   sVv(mulriple || 1)
                               }}
                               value={vv}/>
                        <i className={'plus'} onClick={addMu}>+</i>
                    </div>
                </div>
                <div className={'un'}>
                    <label>资金模式：</label>
                    <div className={'setUn'}>
                        <div onClick={() => {
                            setUn(1)
                        }} className={moneyunit === 1 ? 'act' : ''}>2元
                        </div>
                        <div onClick={() => {
                            setUn(.5)
                        }} className={moneyunit === .5 ? 'act' : ''}> 1元
                        </div>
                        <div onClick={() => {
                            setUn(.1)
                        }} className={moneyunit === .1 ? 'act' : ''}>2角
                        </div>
                        <div onClick={() => {
                            setUn(.05)
                        }} className={moneyunit === .05 ? 'act' : ''}> 1角
                        </div>
                        <div onClick={() => {
                            setUn(.01)
                        }} className={moneyunit === .01 ? 'act' : ''}>2分
                        </div>
                        <div onClick={() => {
                            setUn(.005)
                        }} className={moneyunit === .005 ? 'act' : ''}> 1分
                        </div>
                    </div>
                </div>
                <div className={'e x-slider'}>
                    <label>返点: <b>{(pz - selectPrize) / 20}%</b></label>
                    <div className={'progress-bar'}>
                        <div className={'bn'} onClick={() => prChange(selectPrize - 2)}>-</div>
                        <Slider step={2}
                                value={selectPrize}
                                min={prLis[0]}
                                onChange={prChange}
                                max={prLis[1]}/>
                        <div className={'bn'} onClick={() => prChange(selectPrize + 2)}>+</div>
                    </div>
                    <b>{selectPrize}</b>
                    奖金组
                </div>
            </div>
            <div className={'u'}>
                <div className={'d'}>
                    <p>
                        您已选<span>{bets}</span>注 共{money}元 最高奖金：
                        <span>{pr.toFixed(2)}</span>元，
                        若中奖最高可盈利
                        <span>{bets ? topWin.toFixed(2) : 0}</span>元 余额
                        <span>{balance}</span>元
                    </p>
                    <div className={'all-in' + (allAct ? " act" : '')} onClick={allIn}>
                        ALL IN
                        <div>根据可用余额自动计算您所选号码的最大可投倍数(不超过最高奖金限额）</div>
                    </div>
                </div>
                <div className={'sub' + (available && !_submitting ? ' act' : '')}>
                    <div className={'b-submit'} onClick={() => available && submit()}>
                        <span>
                        <i/>立即投注
                   </span>
                    </div>
                    <div className={'b-addCart'} onClick={() => available && addCart(1)}>
                        <span>
                        <i/>添加至购彩兰
                   </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
});
