import React, {useEffect, useMemo, useState} from 'react';
import Calendar from 'react-calendar';
import './s.scss';
import {Portals} from "../portals";
import {getPos} from "../../utils";
import {panel} from "../tabs";

export const fixD2 = k => {
    const d = new Date(k);
    return d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日';
}
export const fixD3 = k => {
    return simpleDate(fixD2(k))
}

export const dateStr = (v, suffix = '', jo = '-') => {
    const d = v ? new Date(v) : new Date()
    const c = c => (c + 100 + '').substr(1);
    return [d.getFullYear(), c(d.getMonth() + 1), c(d.getDate())].join(jo) + suffix
}

export const simpleDate = a => {
    const n = new Date().getFullYear()
    return a.replace(n + '年', '').replace(n, '').replace(/NaN/gi, '-')
}
const parseDate = (v) => {
    const n = new Date()
    const [yy, mm, dd] = [n.getFullYear(), n.getMonth(), n.getDate()]
    if (Array.isArray(v)) {
        const [a, b, c] = v;
        return new Date(yy - a, mm - b, dd - c);
    } else {
        switch (v) {
            case 7:
                return new Date(yy, mm, dd + 1 - n.getDay())
            case 1:
                return new Date(yy, mm, dd)
            case 2:
                return new Date(yy, mm, dd - 1)
            case 30:
                return new Date(yy, mm, 1)
            default:
                return n;
        }
    }
}
const fmtRange = (a, b) => {
    return [
        parseDate(a),
        parseDate(b)
    ]
}
const sDate = (a, b) => {
    const [c, d] = [new Date(a).getTime(), new Date(b).getTime()];
    return c > d ? 1 : c < d ? -1 : 0
}
export const XDate = ({
                          outCls = '',
                          readOnly = false,
                          single = false,
                          cls = '',
                          value = [new Date()],
                          onChange,
                          quick = [1, 2],
                          init,
                      }) => {
    const obj = useMemo(() => ({}), [])
    const [v, vv] = useState(value);
    const [ac, act] = useState(null);
    const [e, ee] = useState(null);
    const [y, yy] = useState({});
    const [s, ss] = useState(0);
    const [x, xx] = useState(0);
    const [se, sse] = useState(-1);

    let [start, end] = v;
    if (!start) start = end;
    if (!end) end = start;
    const onSelected = x => {
        vv([].concat(x));
        ss(0);
    }
    useEffect(() => {
        if (e) {
            const t = setTimeout(() => {
                const [x, y] = getPos(e, document.getElementById('root'));
                yy({
                    left: x + 'px',
                    top: y + e.offsetHeight + 'px'
                })
            }, 300)
            return () => clearTimeout(t);
        }
    }, [e])
    useEffect(() => {
        const t = setTimeout(() => xx(s), s ? 100 : 300);
        return () => clearTimeout(t)
    }, [s])
    const ff = [start, end];
    ff.sort(sDate);
    const [aa = '', bb = ''] = ff.map(k => {
        if (k) {
            return fixD2(k);
        } else return ''
    })
    const no = '-/-/-';

    let q = '';
    const fns = [];
    if (quick && quick.length) {
        const j = [];
        quick.forEach(v => {
            let a;
            switch (v) {
                case  1:
                    a = '今';
                    break;
                case  2:
                    a = '昨';
                    break;
                case  7:
                case  -7:
                    a = '周';
                    break;
                case  14:
                    a = '两周';
                    break;
                case  30:
                case  -30:
                    a = '月';
                    break;
                default:
                    a = v + '天'
            }
            let c = [[0, 0, v], [0, 0, 0]];
            if (v === 1) c = [1, 1];
            if (v === 2) c = [2, 2];
            if (v === 30) c = [[0, 1, 0], [0, 0, 0]]
            if (v === -7) c = [7, [0, 0, 0]];
            if (v === -30) c = [30, [0, 0, 0]]
            j.push([c, a])
        })

        q = <div className={'x-dp-q'}>
            {j.map(([[x, y], n], i) => {
                const [s, e] = fmtRange(x, y);
                const aw = fixD2(s) === aa && fixD2(e) === bb;
                const at = se === -1 ? aw : i === se && aw;
                const ck = () => {
                    sse(i)
                    vv([s, e])
                    act(s);
                }
                fns.push(ck)
                return <div key={i}
                            className={'bn' + (at ? ' act' : '')}
                            onClick={ck}
                ><span>{n}</span></div>
            })}
        </div>
    }
    useEffect(() => {
        if (fns[init]) fns[init]();
    }, [init, fns.length])

    useEffect(() => {
        clearTimeout(obj.id);
        obj.id = setTimeout(() => {
            if (single) return onChange && onChange(start)
            const x = [start, end]
            x.sort(sDate)
            onChange && onChange(x)
        }, 100)
    }, [start, end])

    const fw = (l, a) => {
        return ['日', '一', '二', '三', '四', '五', '六', '日'][a.getDay()]
    }
    return <>
        <div className={'x-dates ' + cls} onClick={() => ss(1)} ref={ee}>
            <span>{simpleDate(aa) || no}</span>
            {panel(!single, <>
                -
                <span>{simpleDate(bb) || no}</span>
            </>)}
            <i/>
        </div>
        {panel(!single, q)}
        {panel(!readOnly, <Portals>
            <div className={'x-date-out ' +(outCls+' ')+ (s ? ' a' : '') + (x ? ' b' : '')}
                 onClick={({target, currentTarget}) => {
                     if (currentTarget === target) ss(0)
                 }}>
                <div className={'x-dd' + (single ? ' single' : '')} style={y}>
                    <div>
                        <Calendar
                            formatDay={(a, b) => b.getDate()}
                            onActiveStartDateChange={single ? undefined : (value) => {
                                const {activeStartDate} = value;
                                act(activeStartDate)
                            }}
                            activeStartDate={ac}
                            formatShortWeekday={fw}
                            showDoubleView={!single}
                            onChange={onSelected}
                            returnValue={single ? undefined : 'range'}
                            value={single ? start : [start, end]}
                            selectRange={!single}
                        />
                    </div>
                    <div>
                        <div className={'ss'}/>
                    </div>
                </div>
            </div>
        </Portals>)}
    </>
}
