import {widthDashSheet} from "../../components/sheet";
import React, {useEffect, useState} from 'react'
import {change} from "../../components/pwd";
import {Select} from "../../components/select/select";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import './d.scss'
import {convertDate, trim} from "../../utils";
import {useQuery} from "../../hooks";


/**账变明细"*/

export const FdPage = widthDashSheet(sheet => {
    const [v1, vv1] = useState(''); // 账号
    const [v0, vv0] = useState(''); // 交易
    const now = Date.now();
    const old = convertDate(now - 1e3  * 3600 * 24);
    const [q,ld,da] = useQuery('getTransType');
    useEffect(()=>{
       q()
    },[]);
    const data = [['','全部类型']].concat((da||[]).map(({id,name})=>[id,name]));
    //MyDatePicker
    const [times, changeTim] = useState({
        start: old,
        end: convertDate(now),
    });
    const params = p=>{
        const {start,end} = times;
        return {
            start: start ? `${start} 00:00:00` : '',
            end: end ? `${end} 23:59:59` : '',
            type_id: v0,
            page: p,
            page_size: 15,
            is_team: 1,
            username: trim(v1)
        };
    }
    return sheet({
        fakeKeys:['type_id'],
        cls: 'foundDetailPage',
        title: '账变明细',
        api: 'getChargeRecord',
        params,
        cols: [
            {name: '账号', key: 'username'},
            {
                name: '账变类型', cell({type_id}) {
                    const o = data.find(([id]) => id === type_id);
                    return o ? o[1] : '-'
                }
            },
            {name: '时间', key: 'created_at'},
            {name: '变动金额', key: 'amount'},
            {name: '余额', key: 'available'},
            {name: '订单号', key: 'serial_number'},
        ],
        filter(q) {
            return <>
                <div className={'r'}>
                    <label>账号：</label>
                    <input
                        type={'text'}
                        value={v1}
                        onChange={change(vv1)}
                        placeholder={'请输入下级账号'}
                    />
                    <label>交易：</label>
                    <Select
                        loading={ld&&'正在加载'}
                        onChange={vv0} value={v0}
                            items={data}/>
                    <MyDatePicker changeTimeFn={changeTim}/>
                    <div className={'btn'} onClick={q}>查询</div>
                </div>
            </>
        },
    });
});
